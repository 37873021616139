import { useEffect, useState } from "react";
import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { fetchByStDt, fetchFilterByGod, fetchGodNames, fetchTemplesList, retrieveGodNames, retrieveTemples } from "../../../../redux/actions/acions";
import { getGodNames, getGodNamesLoading, getGodNamesError, getTemplesList, getTemplesListLoading, getTemplesListError, getFavouritesList, getFavouritesLoading, getFavouritesError, getFilterByGodList, getFilterByGodLoading, getFilterByGodError, getFilterByStDtLoading, getFilterByStDtList, getFilterByStDtError, getByStDtList, getByStDtLoading, getByStDtError } from "../../../../redux/selectors/selectors";
import { Card, message, Select } from 'antd';
import Error from '../error';
import "../../search.css"
import statesData from '../../states.json';
import { useTranslation } from "react-i18next";
import TemplesPage from "../../../common/TemplesPage";
import { useLocation } from "react-router-dom";



var last_rec = 0;
let god_last_rec = 0;

function TemplesList() {
    const dispatch = useDispatch()
    const [filterId, setFilterId] = useState(localStorage.getItem('filterId') );;
    const localEnv = false;
    const records_per_page = 12;
    const [did, setDid] = useState(0);
    const [dialogVisible, setDialogVisible] = useState(false);
    const [selectedState, setSelectedState] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);
    const [state, setState] = useState(null);
    const [showImage, setShowImage] = useState(true);
    const { t } = useTranslation();
    const storedLanguage = localStorage.getItem('lng') || 5;


    useEffect(() => {
        const timer = setTimeout(() => {
            setShowImage(false);
        }, 2000);

        return () => {
            clearTimeout(timer);
        };
    }, []);

    const [messageApi, contextHolder] = message.useMessage();

    const failure = (msg) => {
        messageApi.open({
            type: 'error',
            content: t('label_loginerror'),
        });
    };
    const { fList, fLoading, templesList, templesListLoading, godCtgryList, godCtgryListLoading, sList, sLoading, fstList} = useSelector(state => ({
        templesList: getTemplesList(state),
        templesListLoading: getTemplesListLoading(state),
        templesListError: getTemplesListError(state),
        godCtgryList: getGodNames(state),
        godCtgryListLoading: getGodNamesLoading(state),
        godCtgryListError: getGodNamesError(state),
        fList: getFavouritesList(state),
        fLoading: getFavouritesLoading(state),
        fError: getFavouritesError(state),
        sList: getFilterByGodList(state),
        sLoading: getFilterByGodLoading(state),
        sError: getFilterByGodError(state),
        stList: getFilterByStDtList(state),
        stLoading: getFilterByStDtLoading(state),
        stError: getFilterByStDtError(state),
        fstList: getByStDtList(state),
        fstLoading: getByStDtLoading(state),
        fstError: getByStDtError(state),
    }))

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        dispatch(localEnv ? fetchGodNames(failure) : retrieveGodNames(storedLanguage))
    }, [storedLanguage])

    function updateFilterId(id) {

        localStorage.setItem('filterId', id);
        setFilterId(id);
    }

    function filterTemplesList(gDid) {
        setDid(gDid);
        if (gDid === filterId) {
            setFilterId(null)
            dispatch(retrieveTemples(storedLanguage, last_rec));
        } else {
            setFilterId(gDid);
            updateFilterId(gDid);
        }
    }
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPageTemples, setCurrentPageTemples] = useState([]);

    useEffect(() => {
        if (filterId) {
            dispatch(fetchFilterByGod(storedLanguage, filterId, last_rec))
        }
        dispatch(retrieveTemples(storedLanguage, last_rec));
    }, [storedLanguage, filterId, currentPage]);

    useEffect(() => {
        if (!templesListLoading && templesList) {
            setCurrentPageTemples([...currentPageTemples, ...templesList]);
        }
    }, [templesListLoading]);

    const nextTemplesList = async () => {
        setIsLoading(true);
        // Increment the current page

        if (filterId === null) {
            last_rec += records_per_page;
            dispatch(retrieveTemples(storedLanguage, last_rec));
        } else {
            god_last_rec += records_per_page;
            dispatch(fetchFilterByGod(storedLanguage, did, god_last_rec));
        }
        // dispatch(fetchByStDt(storedLanguage, selectedState && selectedState.name, selectedCity, last_rec));
        setIsLoading(false);
        window.scrollTo(0, 0);
    }

    function prevTemplesList() {
        if (filterId === null) {
            last_rec -= records_per_page;
            last_rec = last_rec < 0 ? 0 : last_rec;
            dispatch(retrieveTemples(storedLanguage, last_rec));
        } else {
            god_last_rec -= records_per_page;
            god_last_rec = god_last_rec < 0 ? 0 : god_last_rec;
            dispatch(fetchFilterByGod(storedLanguage, did, god_last_rec));
        }
        // dispatch(fetchByStDt(storedLanguage, selectedState && selectedState.name, selectedCity, last_rec));
    }

    useEffect(() => {
        dispatch(localEnv ? fetchTemplesList(failure) : retrieveTemples(storedLanguage, last_rec))
    }, [storedLanguage, filterId])

    const [filled, setFilled] = useState({});

    const godList = selectedState ? fstList : sList;

    return (
        <>
            {contextHolder}
            {filterId === null ? (templesListLoading || templesList === null) ? (<Error />) :

                (
                    <TemplesPage
                        title={t("label_fam_temples")}
                        description={t("label_these_famous")}
                        tList={templesList}
                        tLoading={templesListLoading}
                        // favList={favList}
                        // toggleFavorite={toggleFavorite}
                        // toggleFilled={toggleFilled}
                        setFilterId={setFilterId}
                        godCtgryList={godCtgryList}
                        filterTemplesList={filterTemplesList}
                        godCtgryListLoading={godCtgryListLoading}
                        pageTitle={t("label_fam_temples")}
                        filterId={filterId}
                        linkPage={'/templeCtgry'}
                        nextTemplesList={nextTemplesList}
                        prevTemplesList={prevTemplesList}
                        last_rec={last_rec}
                        latit={templesList.latit}
                        longi={templesList.longi}
                        templesList={templesList}

                    />


                ) : sLoading ? (<Error />) : (
                    <TemplesPage
                        title={t("label_fam_temples")}
                        description={t("label_these_famous")}
                        tList={godList}
                        tLoading={sLoading}
                        // favList={favList}
                        // toggleFavorite={toggleFavorite}
                        // toggleFilled={toggleFilled}
                        setFilterId={setFilterId}
                        godCtgryList={godCtgryList}
                        filterTemplesList={filterTemplesList}
                        godCtgryListLoading={godCtgryListLoading}
                        pageTitle={'label_fam_temples'}
                        filterId={filterId}
                        nextTemplesList={nextTemplesList}
                        prevTemplesList={prevTemplesList}
                        last_rec={last_rec}
                        templesList={godList}
                    />
                )}


        </>
    )
}

export default TemplesList;