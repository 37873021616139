import React from 'react';

const Popup = ({ isVisible, title, content, onClose }) => (
  <div className="popup-bg" style={{ display: isVisible ? 'flex' : 'none' }}>
    <div className="popup-main animate__animated animate__fadeInUp">
      <div className="popup-main-header">
        <span className="popup-main-header-text">{title}</span>
        <div className="popup-close" onClick={onClose}>
          <i className="fa-solid fa-xmark"></i>
        </div>
        <div class="popup-back" onClick={onClose}><i class="fa-solid fa-chevron-left"></i></div>
      </div>
      <div className="popup-main-container">
        <div className="d-block htinner-popup-main-container">{content}</div>
      </div>
      <div className="popup-main-footer">
        <button type="button" className="button_move" onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  </div>
);

export default Popup;
