import React, { useEffect } from 'react';

const AdBlock = () => {
  useEffect(() => {
    if (window.adsbygoogle) {
      try {
        window.adsbygoogle.push({});
      } catch (e) {
        console.error('Adsbygoogle script error:', e);
      }
    }
  }, []);

  return (
    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
      {/* Google AdSense Responsive Ad Block */}
      <ins
        className="adsbygoogle"
        style={{
          display: 'block',
          width: '100%',
          maxWidth: '100%',
          height: 'auto',
        }}
        data-ad-client="ca-pub-3745324756668457"
        data-ad-slot="4255020950"
        data-ad-format="fluid"
        data-full-width-responsive="true"
      ></ins>
    </div>
  );
};

export default AdBlock;
