import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Select, Button, Row, Col, Checkbox, Typography, Modal, message, Tour } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import '../Profile/profile.css';
import ProfilePassword from './profilePassword';
import ProfileLocation from './ProfileLocation';
import { getProfileImageError, getProfileImageList, getProfileImageLoading, getStateError, getStateList, getStateLoading } from '../../../../redux/selectors/selectors';
import { editGender, editLang, editName, fetchProfileImage, fetchState, postAddTempleAdmin, postProfileImageRequest } from '../../../../redux/actions/acions';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import ImageUploadPage from '../Temples/imageUpload';
import Footer from '../footer/footer';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import showToast from '../../../../utils/showToast';
import CustomToastContainer from '../../../common/CustomToastContainer';
import Cropper from 'cropperjs';
import { getImageAPI, getServerImageAPI } from '../../../../http-common';
import deftemples from '../../../../assets/images/templeicon.jpg';
import AP_LANGUAGES from '../../../common/appLangs';

const { Option } = Select;
const Profile = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const userRole = localStorage.getItem('urole');
  const userid = localStorage.getItem('uid');
  const [messageApi, contextHolder] = message.useMessage();
  const [userRoles, setuserRoles] = useState('');
  const [fullname, setFullname] = useState(null);
  const [newImagePath, setNewImagePath] = useState('');
  const [tourVisible, setTourVisible] = useState(false);
  const editButtonRef = useRef(null);
  const detailsSectionRef = useRef(null);
  const passwordSectionRef = useRef(null);

  const tourSteps = [
    {
      title: 'Welcome to Your Profile Page',
      description: 'This is your Profile Page where you can manage your personal information.',
      target: null,
    },
    {
      title: t('edit'),
      description: 'Here you can edit your profile information.',
      target: () => editButtonRef.current,
    },
    {
      title: t('label_details'),
      description: 'Here you can view your profile details.',
      target: () => detailsSectionRef.current,
    },
  ];

  // Check if the user has already seen the tour
  useEffect(() => {
    const hasSeenTour = localStorage.getItem('hasSeenTour');
    if (!hasSeenTour) {
      setTourVisible(true); // Show the tour only if the user hasn't seen it before
    }
  }, []);

  // Set the flag in localStorage after the tour is shown
  useEffect(() => {
    if (tourVisible) {
      localStorage.setItem('hasSeenTour', 'true');
    }
  }, [tourVisible]);

  // Rest of your existing code...
  const success = (message) => {
    toast.success(message);
  };

  const langSuccess = () => {
    showToast('success', t('label_langupdt_success'));
  };

  const gendSuccess = () => {
    showToast('success', t('label_genderupdt_success'));
  };

  const handleSaveName = (e) => {
    setFullname(e.target.value);
  };

  const updateSaveName = () => {
    dispatch(editName({ fullname: fullname }, () => showToast('success', t('label_update_name_sucess'))));
  };

  const handleSelectChange = (value) => {
    dispatch(
      editGender({ gender: `${value}` }, () => {
        showToast('success', t('label_update_gender_sucess'));
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
    );
  };

  const handleSelect = (value) => {
    dispatch(
      editLang({ lang: `${value}` }, () => {
        showToast('success', t('label_update_lang_sucess'));
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
    );
  };

  const uid = localStorage.getItem('userRole');
  const { sDetails, sLoading, sError, iList, iLoading, iError } = useSelector((state) => ({
    sDetails: getStateList(state),
    sLoading: getStateLoading(state),
    sError: getStateError(state),
    iList: getProfileImageList(state),
    iLoading: getProfileImageLoading(state),
    iError: getProfileImageError(state),
  }));

  const getLinkPath = (userRole) => {
    switch (userRole) {
      case 'TA':
      case 'TU':
        return '/temple-dashboard';
      case 'PU':
      case 'PL':
      case 'PR':
        return '/priest-profile';
      default:
        return '/';
    }
  };

  const getToken = Boolean(localStorage.getItem('token'));
  useEffect(() => {
    if (getToken) {
      dispatch(fetchState());
    }
  }, []);

  const [fetchedImage, setFetchedImage] = useState(null);

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await dispatch(fetchProfileImage('images_2024_12_00/Img_2DT19__05_06_32_blob.jpg'));

        if (response) {
          const blob = new Blob([response], { type: 'image/jpg' });
          const url = URL.createObjectURL(blob);
          setFetchedImage(url);
        }
      } catch (error) {
        console.error('Failed to fetch profile image:', error);
      }
    };

    fetchImage();
  }, [dispatch]);

  useEffect(() => {
    form.setFieldsValue(sDetails);
  }, [sDetails]);

  useEffect(() => {
    i18next.changeLanguage(localStorage.getItem('i18nextLng'));
  }, []);

  const [token, setToken] = useState('');
  const [name, setName] = useState('');
  const [firstLetter, setFirstLetter] = useState('');
  const [user, setUser] = useState(null);

  useEffect(() => {
    const getToken = localStorage.getItem('token');
    const getName = localStorage.getItem('name');
    if (getToken && getName) {
      setToken(getToken);
      setName(getName);
    }
  }, []);

  React.useEffect(() => {
    const getName = localStorage.getItem('name');
    if (getName) {
      const firstChar = getName.charAt(0);
      setFirstLetter(firstChar);
    }
  }, []);

  useEffect(() => {
    const getName = localStorage.getItem('name');
    if (getName) {
      setName(getName);
    }

    if (userid) {
      const profileImageURL = localStorage.getItem(`profileImage_${userid}`);
      if (profileImageURL) {
        setUser(profileImageURL);
      } else {
        setUser(null);
      }
    }
  }, []);

  const firstName = name.split(' ')[0];
  const lastName = name.split(' ')[1];
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1);
  };

  const [formData, setFormData] = useState({});
  const [editable, setEditable] = useState(false);
  const [buttonText, setButtonText] = useState(t('edit'));

  const handleCancelClick = () => {
    setButtonText(t('edit'));
    setEditable(false);
    window.location.reload();
  };

  const handleEditClick = () => {
    if (!editable) {
      setButtonText(t('label_cancel'));
      setFormData({ ...formData, deityname: sDetails && sDetails.deityname });
      setFormData({ ...formData, otherdeities: sDetails && sDetails.otherdeities });
    } else {
      setButtonText(t('edit'));
    }
    setEditable(!editable);
  };

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalPremiumVisible, setIsPremiumModalVisible] = useState(false);

  const showPremiumModal = () => {
    setIsPremiumModalVisible(true);
  };

  const handlePremiumOk = () => {
    setIsPremiumModalVisible(false);
  };

  const handlePremiumCancel = () => {
    setIsPremiumModalVisible(false);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleModalSubmit = () => {
    dispatch(postAddTempleAdmin(userRoles), () => showToast('success', 'Gender Updated Successfully'));
  };

  const id = localStorage.getItem('uid');
  const [previewImage, setPreviewImage] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleGalleryClick = () => {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = 'image/*';

    fileInput.addEventListener('change', (event) => {
      const selectedFile = event.target.files[0];
      if (selectedFile) {
        const reader = new FileReader();
        reader.onload = () => {
          setSelectedImage(reader.result);
          setIsModalVisible(true);
        };
        reader.readAsDataURL(selectedFile);
      }
    });

    fileInput.click();
  };

  const imageRef = useRef();
  const [cropper, setCropper] = useState(null);
  const handleImageCloseModal = () => {
    setIsModalVisible(false);
    if (cropper) {
      cropper.destroy();
    }
  };

  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const handleCropAndUpload = () => {
    if (cropper) {
      cropper.getCroppedCanvas().toBlob((blob) => {
        const formData = new FormData();
        formData.append('file', blob);
        setLoading(true);
        setHasError(false);
        dispatch(
          postProfileImageRequest(
            formData,
            () => {
              const uploadedImagePath = URL.createObjectURL(blob);
              setNewImagePath(uploadedImagePath);
              handleImageCloseModal();
              setLoading(false);
              if (getToken) {
                dispatch(fetchState());
              }
            },
            (errorMsg) => {
              console.error('Image upload failed:', errorMsg);
              setLoading(false);
              setHasError(true);
            }
          )
        );
      });
    }
  };

  useEffect(() => {
    if (isModalVisible && imageRef.current) {
      const cropperInstance = new Cropper(imageRef.current, {
        aspectRatio: 16 / 9,
        viewMode: 1,
        autoCropArea: 1,
        responsive: true,
        scalable: false,
        zoomable: true,
        cropBoxResizable: true,
        minCropBoxHeight: 200,
        maxCropBoxHeight: 200,
        minCropBoxWidth: imageRef.current.clientWidth,
        maxCropBoxWidth: imageRef.current.clientWidth,
      });
      setCropper(cropperInstance);
    }

    return () => {
      if (cropper) {
        cropper.destroy();
      }
    };
  }, [isModalVisible]);

  return (
    <div className="mainsec">
      <Tour open={tourVisible} onClose={() => setTourVisible(false)} steps={tourSteps} />
      <div class="mob-nav fluid-container page-title justify-content-between">
        <div class="d-flex align-items-center left-sec-mob-nav">
          <Link onClick={handleGoBack}>
            <i class="fa-solid fa-angle-left"></i>
          </Link>
          <span class="bc-link">{t('label_profile')}</span>
        </div>
        <div></div>
      </div>
      <div className="f2 font-weight-bold mb-3 mt-3 container title-head-txt breadcrumb-link">
        <Link style={{ color: 'black' }} to="/">
          <span className="bc-active-link">{t('Home')}</span>
        </Link>{' '}
        -{' '}
        <span className="bc-link" onClick={handleGoBack}>
          {t('label_profile')}
        </span>
      </div>
      <div class="bg-details-highlight">
        <div class="details-headertxt-singleLine container">
          <div class="header_txt"> {sDetails && sDetails.fullname}</div>
          <div class="details-headertxt-singleLine-btns">
            <a ref={editButtonRef} className="button_move mr-3" onClick={editable ? handleCancelClick : handleEditClick}>
              {editable ? t('label_cancel') : t('edit')}
            </a>
            {userRole === 'PU' || userRole === 'PL' || userRole === 'PR' ? (
              <>
                <Link to={`/editpriestDetails/${userid}/${sDetails && sDetails.fullname}`} style={{ marginLeft: '10px' }}>
                  <button type="button" class="button_move">
                    {t('label_edit_priest_profile')}
                  </button>
                </Link>
              </>
            ) : (
              ''
            )}
            {userRole === 'AD' || userRole === 'AS' ? (
              <>
                <button type="button" onClick={() => navigate('/quicklinks')} class="button_move">
                  {t('label_app_admin_links')}
                </button>
              </>
            ) : (
              ''
            )}
            {userRole === 'TA' || userRole === 'TU' ? (
              <>
                <button type="button" onClick={() => navigate('/templeadminlinks')} class="button_move" style={{ marginLeft: '10px' }}>
                  {t('label_templ_adm_links')}
                </button>
              </>
            ) : (
              ''
            )}
            {userRole === 'SU' ? (
              <button onClick={showPremiumModal} type="button" class="button_move">
                {t('label_premium_user')}
              </button>
            ) : null}
          </div>
        </div>
      </div>
      <div className="mainsec-block-addtemple-detailspage">
        <div class="f2 font-weight-bold  container title-head-txt " style={{ overflowX: 'auto', paddingBottom: '10px', whiteSpace: 'nowrap', marginTop: '5px' }}>
          <div class="popup-bg" style={{ display: isModalPremiumVisible ? 'flex' : 'none' }}>
            <div class="popup-main animate__animated animate__fadeInUp ">
              <div class="popup-main-header">
                <span class="popup-main-header-text">{t('Benefits of Becoming a Premium User')}</span>
                <div class="popup-close" onClick={handlePremiumCancel}>
                  <i class="fa-solid fa-xmark"></i>
                </div>
                <div class="popup-back" onClick={handlePremiumCancel}>
                  <i class="fa-solid fa-chevron-left"></i>
                </div>
              </div>
              <div class="popup-main-container">
                <div class="d-block htinner-popup-main-container">
                  <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                    <img className="grp-descr-img" src={deftemples} alt="Premium User" />
                  </div>
                  <div className="dialog-content cat-discription font_main ">
                    <ul style={{ paddingLeft: '20px' }}>
                      <li>Benefit 1: Access to exclusive content.</li>
                      <li>Benefit 2: Priority customer support.</li>
                      <li>Benefit 3: Ad-free experience.</li>
                      <li>Benefit 4: Early access to new features.</li>
                      <li>Benefit 5: Discounts on products and services.</li>
                      <li>Benefit 6: Access to premium resources.</li>
                      <li>Benefit 7: Enhanced security features.</li>
                      <li>Benefit 8: Personalized recommendations.</li>
                      <li>Benefit 9: Higher storage limits.</li>
                      <li>Benefit 10: Invitations to exclusive events.</li>
                      <li>Benefit 11: Detailed analytics and reports.</li>
                      <li>Benefit 12: Customizable user interface.</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="popup-main-footer">
                <a type="primary" class="button_move" onClick={handleCancel}>
                  Proceed
                </a>
              </div>
            </div>
          </div>
          {userRole === 'SU' ? (
            <>
              <a href={`/createNewPriest`} type="primary" style={{ marginLeft: '10px' }}>
                <button type="button" class="button_move">
                  {t('label_build_my_priest')}
                </button>
              </a>
            </>
          ) : (
            ''
          )}
          <div class="popup-bg" style={{ display: isModalVisible ? 'flex' : 'none' }}>
            <div class="popup-main-filter animate__animated animate__fadeInUp ">
              <div class="popup-main-header">
                <span class="popup-main-header-text">Main header</span>
                <div class="popup-close" onClick={handleImageCloseModal}>
                  <i class="fa-solid fa-xmark"></i>
                </div>
                <div class="popup-back" onClick={handleImageCloseModal}>
                  <i class="fa-solid fa-chevron-left"></i>
                </div>
              </div>
              <div class="popup-main-container">
                <div class="d-block htinner-popup-main-container">
                  {selectedImage && <img ref={imageRef} id="imageToCrop" src={selectedImage} alt="To be cropped" />}
                </div>
              </div>
              <div class="popup-main-footer">
                <button class="button_move" onClick={handleCropAndUpload}>
                  Crop and Upload
                </button>
              </div>
            </div>
          </div>
          <div class="popup-bg" style={{ display: isModalOpen ? 'flex' : 'none' }}>
            <div class="popup-main animate__animated animate__fadeInUp ">
              <div class="popup-main-header">
                <span class="popup-main-header-text">{t('label_add_temple_admin')}</span>
                <div class="popup-close" onClick={handleCancel}>
                  <i class="fa-solid fa-xmark"></i>
                </div>
                <div class="popup-back" onClick={handleCancel}>
                  <i class="fa-solid fa-chevron-left"></i>
                </div>
              </div>
              <div class="popup-main-container">
                <div class="d-block htinner-popup-main-container">
                  <Form labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} style={{ padding: '10px 10px' }}>
                    <div style={{ padding: '10px 10px' }}>
                      <div class="tiles-designs">
                        <Form.Item label="User ID" name="userRole" rules={[{ required: true, message: 'Please enter userRole' }]}>
                          <Input value={userRole} onChange={(e) => setuserRoles(e.target.value)} />
                        </Form.Item>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
              <div class="popup-main-footer">
                <a type="primary" class="button_move" onClick={handleCancel} style={{ marginRight: '2px' }}>
                  {' '}
                  {t('label_cancel')}
                </a>
                <a type="primary" class="button_move" onClick={handleModalSubmit}>
                  {t('label_save')}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          ssssssssssssssssss
          <div class="container">
    <div class="main-body">
    
          
          
    
          <div class="row gutters-sm">
            <div class="col-md-4 mb-3">
              <div class="card">
                <div class="card-body">
                  <div class="d-flex flex-column align-items-center text-center">
                    <img src="https://bootdey.com/img/Content/avatar/avatar7.png" alt="Admin" class="rounded-circle" width="150"/>
                    <div class="mt-3">
                      <h4>John Doe</h4>
                      <p class="text-secondary mb-1">Full Stack Developer</p>
                      <p class="text-muted font-size-sm">Bay Area, San Francisco, CA</p>
                      <button class="btn btn-primary">Follow</button>
                      <button class="btn btn-outline-primary">Message</button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card mt-3">
                <ul class="list-group list-group-flush">
                  <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    <h6 class="mb-0"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-globe mr-2 icon-inline"><circle cx="12" cy="12" r="10"></circle><line x1="2" y1="12" x2="22" y2="12"></line><path d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"></path></svg>Temples</h6>
                    <span class="text-secondary">3</span>
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    <h6 class="mb-0"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-github mr-2 icon-inline"><path d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22"></path></svg>Images</h6>
                    <span class="text-secondary">3</span>
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    <h6 class="mb-0"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-twitter mr-2 icon-inline text-info"><path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path></svg>Articles</h6>
                    <span class="text-secondary">10</span>
                  </li>
              
                </ul>
              </div>
            </div>
            <div class="col-md-8">
              <div class="card mb-3">


                <div class="card-body">




                <Form form={form} name="nest-messages" labelCol={{ span: 6 }} wrapperCol={{ span: 8 }} onFinish={handleSaveName}>
            <div class="float-left w-100">
            <h6 class="d-flex align-items-center mb-3"><i class="material-icons text-info mr-2">{t('label_details')} </i></h6>
             
            </div>
            <div class="active-details" ref={detailsSectionRef}>
              <div className="tiles-designs">
                <Form.Item label={<Typography.Title level={4}>{t('label_userid')}</Typography.Title>} name="userid">
                  <Typography.Text level={5} className="typ">
                    {sDetails && sDetails.userid}
                  </Typography.Text>
                </Form.Item>
              </div>
              <div className="tiles-designs">
                <Form.Item label={<Typography.Title level={4}>{t('label_role')}</Typography.Title>} name="fullname">
                  <Typography.Text level={5} className="typ">
                    {sDetails
                      ? (() => {
                          switch (sDetails.urole.trim()) {
                            case 'TA':
                              return t('label_ta');
                            case 'PL':
                              return t('label_priest_admin');
                            case 'PR':
                              return t('label_priest_admin');
                            case 'AD':
                              return t('label_admin');
                            case 'AS':
                              return t('label_app_support');
                            case 'TU':
                              return t('label_tu');
                            case 'SU':
                              return t('label_standard_user');
                            default:
                              return t('label_notchosen');
                          }
                        })()
                      : t('label_notchosen')}
                  </Typography.Text>
                </Form.Item>
              </div>
              <div className="tiles-designs">
                <Form.Item
                  label={<Typography.Title level={4}>{t('label_fullname')}</Typography.Title>}
                  name="fullname"
                  rules={[{ required: editable, message: 'Please enter your full name' }]}
                >
                  {editable ? (
                    <Input style={{ height: '30px' }} defaultValue={sDetails && sDetails.tname} onChange={handleSaveName} onBlur={updateSaveName} />
                  ) : (
                    <Typography.Text level={5} className="typ">
                      {sDetails && sDetails.fullname}
                    </Typography.Text>
                  )}
                </Form.Item>
              </div>
              <Col span={10} style={{ display: 'flex', flexDirection: 'row-reverse', alignItems: 'flex-end' }}>
                <section>
                  <div className="user-pic">
                    {editable && (
                      <div className="upload-overlay">
                        <a onClick={handleGalleryClick} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textDecoration: 'none' }}>
                          <i className="fa-solid fa-camera" style={{ fontSize: '20px' }}></i>
                          <span>Change Profile Image</span>
                        </a>
                      </div>
                    )}
                    {sDetails && sDetails.profimg ? (
                      <img alt={sDetails.fullname || 'Default Name'} src={sDetails.profimg ? getServerImageAPI() + sDetails.profimg : deftemples} />
                    ) : previewImage ? (
                      <img src={previewImage} alt="Profile" />
                    ) : (
                      <img src={deftemples} alt="Default Profile" />
                    )}
                  </div>
                </section>
              </Col>
              <div className="tiles-designs">
                <Form.Item
                  label={<Typography.Title level={4}>{t('label_gender')}</Typography.Title>}
                  name="gender"
                  rules={[{ required: editable, message: 'Please select your gender' }]}
                >
                  {editable ? (
                    <Select onChange={handleSelectChange} style={{ width: '100%' }}>
                      <Select.Option value="">{t('label_pls_choose_option')}</Select.Option>
                      <Select.Option value="M">{t('label_male')}</Select.Option>
                      <Select.Option value="F">{t('label_female')}</Select.Option>
                      <Select.Option value="O">{t('label_other')}</Select.Option>
                    </Select>
                  ) : (
                    <Typography.Text level={5} className="typ">
                      {sDetails
                        ? (() => {
                            switch (sDetails.gender) {
                              case 'M':
                                return t('label_male');
                              case 'F':
                                return t('label_female');
                              case 'O':
                                return t('label_other');
                              default:
                                return t('label_notchosen');
                            }
                          })()
                        : t('label_notchosen')}
                    </Typography.Text>
                  )}
                </Form.Item>
              </div>
              <div className="tiles-designs">
                <Form.Item
                  label={<Typography.Title level={4}>{t('label_my_language')}</Typography.Title>}
                  name="lang"
                  rules={[{ required: editable, message: 'Please select your preferred language' }]}
                >
                  {editable ? (
                    <Select onChange={handleSelect} style={{ width: '100%' }}>
                      <Select.Option value="">-- {t('label_pls_choose_option')} --</Select.Option>
                      {AP_LANGUAGES.map((item) => (
                        <Option key={item.value} value={item.value}>
                          {item.label}
                        </Option>
                      ))}
                    </Select>
                  ) : (
                    <Typography.Text level={5} className="typ">
                      {sDetails
                        ? (() => {
                            switch (sDetails.lang.trim()) {
                              case '5':
                                return 'English';
                              case '1':
                                return 'తెలుగు';
                              case '2':
                                return 'ಕನ್ನಡ';
                              case '3':
                                return 'தமிழ்';
                              case '4':
                                return 'हिंदी';
                              case '6':
                                return 'Española';
                              case '7':
                                return 'বাংলা';
                              default:
                                return t('label_notchosen');
                            }
                          })()
                        : t('label_notchosen')}
                    </Typography.Text>
                  )}
                </Form.Item>
              </div>
            </div>
          </Form>











                
                 
                </div>
              </div>

              <div class="row gutters-sm">
                <div class="col-sm-6 mb-3">
                  <div class="card h-100">
                    <div class="card-body">
                    <ProfilePassword editable={editable} />
                    </div>
                  </div>
                </div>
             
              </div>
              <div class="row gutters-sm">
                <div class="col-sm-6 mb-3">
                  <div class="card h-100">
                    <div class="card-body">
                    <ProfileLocation editable={editable} />
                    </div>
                  </div>
                </div>
             
              </div>



            </div>
          </div>

        </div>
    </div>

          aaaaaaaaaaaaaaaa
      
         
         
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Profile;