import { createSelector } from "reselect";

const getTemples = state => state.templesList;
//const getDetails=state => state.templesList&&state.templesList.templesList;
const getNames = state => state.godNames;
//const getDetails = state => state.templeDetails && state.templeDetails.templeDetails;
const getDetails = state => state.templeDetails;
const getDetailsInOtherLang = state => state.templeDetailsInOtherLang;
const getContributions = state => state.contributions;
const getNotifications = state => state.notifications;
const getFavourites = state => state.favourites;
const getArticles = state => state.getArticles;
const getLiveTv = state => state.getLiveTv;
const getVideoLinks = state => state.getVideoLinks;
const getLogin = state => state.validUserLogin;
const getBooks = state => state.books;
const getDailyHoroscope = state => state.dailyHoroscope;
const getWeeklyHoroscope = state => state.weeklyHoroscope;
const getState = state => state.getDetails;
const getNearest = state => state.nearest;
const getNearToMe = state => state.nearToMe;
const getFilterByGod = state => state.filterByGod;
const getFilterByStDt = state => state.filterByStDt;
const getTempleGroups = state => state.templeGroups;
const getByStDt = state => state.byStDt;
const getFilterNearest = state => state.filterNearest;
const getCityTemplesList = state => state.cityTemplesList;
const getTempleTxt = state => state.templeTxt;
const getResource = state => state.resource;
const getResourceDetails = state => state.resourceDetails;
const getTempleGroup = state => state.templeGroup;
const getGroupDescription = state => state.groupDescription;
const getTempleBooks = state => state.templeBooks;
const getTempleNotifi = state => state.templeNotifi;
const getBooksFavourites = state => state.booksFavourites;
const getTempleFavourites = state => state.templeFavourites;
const getRefreshToken = state => state.refreshToken;
const getRecentlyViewed = state => state.recentlyViewed;
const getRegionalNotifi = state => state.regionalNotifi;
const getBooksByTag = state => state.booksByTag;
const getDailyHoroscopeDetails = state => state.dailyHoroscopeDetails;
const getWeeklyHoroscopeDetails = state => state.weeklyHoroscopeDetails;
const getNotificationDetails = state => state.notificationDetails;
const getResourceByTxt = state => state.resourceByTxt;
const getDonations = state => state.donationsList;
const getDonationsDetails = state => state.donationsDetails;
const getDonationsByMonth = state => state.donationsByMonth;
const getExpenses = state => state.expensesList;
const getExpensesDetails = state => state.expensesDetails;
const getTempleGroupDetails = state => state.templeGroupDetails;
const getPriestDetails = state => state.priestDetails;
const getPriestBySpclty = state => state.priestBySpclty;
const getMyRequests = state => state.myRequests;
const getPriestRequests = state => state.priestRequests;
const getMyRequestsDetails = state => state.myRequestsDetails;
const getTempleMantras = state => state.templeMantras;
const getTempleLatestDetails = state => state.templeLatestDetails;
const getDailyHoroscopeLists = state => state.dailyHoroscopeList;
const getWeeklyHoroscopeLists = state => state.weeklyHoroscopeList;
const getServicesByUserLists = state => state.servicesByUser;
const getServicesByAdminLists = state => state.servicesByAdmin;
const getServicesDetailsLists = state => state.servicesDetails;
const getTempleDashboard = state => state.templeDashboardList;
const getTempleMembers = state => state.templeMembersList;
const getPendingTemples = state => state.pendingTemplesList;
const getTempleServiceRegistration = state => state.templeServiceRegistration;
const getServiceRegDetails = state => state.serviceRegDetails;
const getUserServiceRegistration = state => state.userServiceRegistration;
const getTempleUsersBySt = state => state.templeUsersBySt;
const getHoroDetailsByDt = state => state.horoDetailsByDt;
const getAppUsers = state => state.appUsers;
const getFilterByStDtTxt = state => state.filterByStDtTxt;
const getCityStDescr = state => state.cityStDescr;
const getTemplesOutsideInd = state => state.templesOutsideInd;
const getTempleAudio = state => state.templeAudio;
const getAnonomousUser = state => state.anonomousUser;
const getMyServices = state => state.myServices;
const getTempleServiceBookings = state => state.templeServicesReg;
const getResourceFavourites = state => state.resourceFavourites;
const getResourceState = (state) => state.resources;
const getResourceArticles = state => state.resArticles;
const getResourceMantras = state => state.resMantras;
const getTempleMinDetails = state => state.templeMinDetails;
const getResourceAudios = state => state.resAudios;
const getResourceLivetv = state => state.resLivetv;
const getResourceVideos = state => state.resVideos;
const gettempleAdminNotifications = state => state.templeAdminNotifications;
const getAppAdminNotifications = state => state.appadminnotifications;
const getTempleComplaints = state => state.templeComplaints;
const getProfileImage = state => state.profileImage;
const fetchUserName = state => state.fetchUserFullName;
const getTemplePreviewImg = state => state.templeReviewImg;

export const getResBooks = createSelector(
    [getResourceState],
    (resources) => resources.books.data
);

export const getResBooksLoading = createSelector(
    [getResourceState],
    (resources) => resources.books.loading
);

export const getResBooksError = createSelector(
    [getResourceState],
    (resources) => resources.books.error
);

export const getResArticles = createSelector(
    [getResourceArticles],
    resArticles => resArticles.resArticles
);

export const getResArticlesLoading = createSelector(
    [getResourceArticles],
    resArticles => resArticles.resArticlesloading
);

export const getResArticlesError = createSelector(
    [getResourceArticles],
    resArticles => resArticles.resArticleserror
);

export const getMantras = createSelector(
    [getResourceMantras],
    resMantras => resMantras.resMantras
);

export const getMantrasLoading = createSelector(
    [getResourceMantras],
    resMantras => resMantras.resMantrasloading
);

export const getMantrasError = createSelector(
    [getResourceMantras],
    resMantras => resMantras.resMantraserror
);

export const getAudios = createSelector(
    [getResourceState],
    (resources) => resources.audios.data
);

export const getAudiosLoading = createSelector(
    [getResourceState],
    (resources) => resources.audios.loading
);

export const getAudiosError = createSelector(
    [getResourceState],
    (resources) => resources.audios.error
);


export const getTemplesList = createSelector(
    [getTemples],
    templesList => templesList.templesList
)

export const getTemplesListLoading = createSelector(
    [getTemples],
    templesList => templesList.templesListLoading
)

export const getTemplesListError = createSelector(
    [getTemples],
    templesList => templesList.templesListError
)

export const getMyServicesList = createSelector(
    [getMyServices],
    myServices => myServices.myServices
)

export const getMyServicesListLoading = createSelector(
    [getMyServices],
    myServices => myServices.myServicesLoading
)

export const getMyServicesListError = createSelector(
    [getMyServices],
    myServices => myServices.myServicesError
)

export const getTempleServiceBookingList = createSelector(
    [getTempleServiceBookings],
    templeServicesReg => templeServicesReg.templeServicesReg
)

export const getTempleServiceBookingListLoading = createSelector(
    [getTempleServiceBookings],
    templeServicesReg => templeServicesReg.templeServicesRegLoading
)

export const getTempleServiceBookingListError = createSelector(
    [getTempleServiceBookings],
    templeServicesReg => templeServicesReg.templeServicesRegError
)

// export const getTempleDetails = createSelector(
//     [getDetails, (state, id) => id],
//     (templeDetails, id) => templeDetails)

export const getGodNames = createSelector(
    [getNames],
    godNames => godNames.godNames
)

export const getGodNamesLoading = createSelector(
    [getNames],
    godNames => godNames.godNamesLoading
)

export const getGodNamesError = createSelector(
    [getNames],
    templesList => templesList.godNamesError
)

export const getTempleDetails = createSelector(
    [getDetails],
    templeDetails => templeDetails.templeDetails
)

export const getTempleDetailsError = createSelector(
    [getDetails],
    templeDetails => templeDetails.templeDetailsError
)

export const getTempleDetailsLoading = createSelector(
    [getDetails],
    templeDetails => templeDetails.templeDetailsLoading
)


export const getLatestTempleDetails = createSelector(
    [getTempleLatestDetails],
    templeLatestDetails => templeLatestDetails.templeLatestDetails
)

export const getLatestTempleDetailsError = createSelector(
    [getTempleLatestDetails],
    templeLatestDetails => templeLatestDetails.templeLatestDetailsError
)

export const getLatestTempleDetailsLoading = createSelector(
    [getTempleLatestDetails],
    templeLatestDetails => templeLatestDetails.templeLatestDetailsLoading
)

export const getContributionsList = createSelector(
    [getContributions],
    contributions => contributions.contributions
)

export const getContributionsError = createSelector(
    [getContributions],
    contributions => contributions.contributionsError
)

export const getContributionsLoading = createSelector(
    [getContributions],
    contributions => contributions.contributionsLoading
)

export const getNotificationsList = createSelector(
    [getNotifications],
    notifications => notifications.notifications
)

export const getNotificationsError = createSelector(
    [getNotifications],
    notifications => notifications.notificationsError
)

export const getNotificationstionsLoading = createSelector(
    [getNotifications],
    notifications => notifications.notificationsLoading
)

export const gettempleAdminNotificationsList = createSelector(
    [gettempleAdminNotifications],
    templeAdminNotifications => templeAdminNotifications.templeAdminNotifications
)

export const gettempleAdminNotificationsError = createSelector(
    [gettempleAdminNotifications],
    templeAdminNotifications => templeAdminNotifications.templeAdminNotificationsError
)

export const gettempleAdminNotificationstionsLoading = createSelector(
    [gettempleAdminNotifications],
    templeAdminNotifications => templeAdminNotifications.templeAdminNotificationsLoading
)

export const getAppAdminNotificationsList = createSelector(
    [getAppAdminNotifications],
    appadminnotifications => appadminnotifications.appadminnotifications
)

export const getAppAdminNotificationsError = createSelector(
    [getAppAdminNotifications],
    appadminnotifications => appadminnotifications.appadminnotificationsError
)

export const getAppAdminNotificationstionsLoading = createSelector(
    [getAppAdminNotifications],
    appadminnotifications => appadminnotifications.appadminnotificationsLoading
)

export const getFavouritesList = createSelector(
    [getFavourites],
    favourites => favourites.favourites
)

export const getFavouritesError = createSelector(
    [getFavourites],
    favourites => favourites.favouritesError
)

export const getFavouritesLoading = createSelector(
    [getFavourites],
    favourites => favourites.favouritesLoading
)

export const getBooksFavouritesList = createSelector(
    [getBooksFavourites],
    booksFavourites => booksFavourites.booksFavourites
)

export const getBooksFavouritesError = createSelector(
    [getBooksFavourites],
    booksFavourites => booksFavourites.booksFavouritesError
)

export const getBooksFavouritesLoading = createSelector(
    [getBooksFavourites],
    booksFavourites => booksFavourites.booksFavouritesLoading
)

export const getArticlesList = createSelector(
    [getArticles],
    articles => articles.articlesData
)

export const getArticlesError = createSelector(
    [getArticles],
    articles => articles.articlesError
)

export const getArticlesLoading = createSelector(
    [getArticles],
    articles => articles.articlesLoading
)

export const getLiveTvList = createSelector(
    [getLiveTv],
    liveTv => liveTv.liveTv
)

export const getLiveTvError = createSelector(
    [getLiveTv],
    liveTv => liveTv.liveTvError
)

export const getLiveTvLoading = createSelector(
    [getLiveTv],
    liveTv => liveTv.liveTvLoading
)

export const getVideoLinksList = createSelector(
    [getVideoLinks],
    videoLinks => videoLinks.videoLinks
)

export const getVideoLinksError = createSelector(
    [getVideoLinks],
    videoLinks => videoLinks.videoLinksError
)

export const getVideoLinksLoading = createSelector(
    [getVideoLinks],
    videoLinks => videoLinks.videoLinksLoading
)

export const getTempleAudioList = createSelector(
    [getTempleAudio],
    templeAudio => templeAudio.templeAudio
)

export const getTempleAudioError = createSelector(
    [getTempleAudio],
    templeAudio => templeAudio.templeAudioError
)

export const getTempleAudioLoading = createSelector(
    [getTempleAudio],
    templeAudio => templeAudio.templeAudioLoading
)

export const getLogins = createSelector(
    [getLogin],
    validLogin => validLogin.validUserLogin
)

export const getLoginError = createSelector(
    [getLogin],
    validLogin => validLogin.validUserLoginError
)

export const getLoginLoading = createSelector(
    [getLogin],
    validLogin => validLogin.validUserLoginLoading
)

export const getBooksList = createSelector(
    [getBooks],
    books => books.books
)

export const getBooksError = createSelector(
    [getBooks],
    books => books.booksError
)

export const getBooksLoading = createSelector(
    [getBooks],
    books => books.booksLoading
)

export const getDailyHoroscopeList = createSelector(
    [getDailyHoroscope],
    dailyHoroscope => dailyHoroscope.dailyHoroscope
)

export const getDailyHoroscopeError = createSelector(
    [getDailyHoroscope],
    dailyHoroscope => dailyHoroscope.dailyHoroscopeError
)

export const getDailyHoroscopeLoading = createSelector(
    [getDailyHoroscope],
    dailyHoroscope => dailyHoroscope.dailyHoroscopeLoading
)

export const getWeeklyHoroscopeList = createSelector(
    [getWeeklyHoroscope],
    weeklyHoroscope => weeklyHoroscope.weeklyHoroscope
)

export const getWeeklyHoroscopeError = createSelector(
    [getWeeklyHoroscope],
    weeklyHoroscope => weeklyHoroscope.weeklyHoroscopeError
)

export const getWeeklyHoroscopeLoading = createSelector(
    [getWeeklyHoroscope],
    weeklyHoroscope => weeklyHoroscope.weeklyHoroscopeLoading
)

export const getStateList = createSelector(
    [getState],
    getDetails => getDetails.userDetails
)

export const getStateError = createSelector(
    [getState],
    state => state.stateError
)

export const getStateLoading = createSelector(
    [getState],
    state => state.stateLoading
)

export const getNearestList = createSelector(
    [getNearest],
    state => state.nearest
)

export const getNearestError = createSelector(
    [getNearest],
    state => state.nearestError
)

export const getNearestLoading = createSelector(
    [getNearest],
    state => state.nearestLoading
)

export const getFilterByGodList = createSelector(
    [getFilterByGod],
    filterByGod => filterByGod.filterByGod
)

export const getFilterByGodError = createSelector(
    [getFilterByGod],
    filterByGod => filterByGod.filterByGodError
)

export const getFilterByGodLoading = createSelector(
    [getFilterByGod],
    filterByGod => filterByGod.filterByGodLoading
)

export const getNearToMeList = createSelector(
    [getNearToMe],
    nearToMe => nearToMe.nearToMe
)

export const getNearToMeError = createSelector(
    [getNearToMe],
    nearToMe => nearToMe.nearToMeError
)

export const getNearToMeLoading = createSelector(
    [getNearToMe],
    nearToMe => nearToMe.nearToMeLoading
)

export const getFilterByStDtList = createSelector(
    [getFilterByStDt],
    filterByStDt => filterByStDt.filterByStDt
)

export const getFilterByStDtError = createSelector(
    [getFilterByStDt],
    filterByStDt => filterByStDt.filterByGodErStDt
)

export const getFilterByStDtLoading = createSelector(
    [getFilterByStDt],
    filterByStDt => filterByStDt.filterByGodLoadStDt
)

export const getTempleGroupsList = createSelector(
    [getTempleGroups],
    templeGroups => templeGroups.templeGroups
)

export const getTempleGroupsError = createSelector(
    [getTempleGroups],
    templeGroups => templeGroups.templeGroupsError
)

export const getTempleGroupsLoading = createSelector(
    [getTempleGroups],
    templeGroups => templeGroups.templeGroupsLoading
)

export const getByStDtList = createSelector(
    [getByStDt],
    byStDt => byStDt.byStDt
)

export const getByStDtError = createSelector(
    [getByStDt],
    byStDt => byStDt.byStDtError
)

export const getByStDtLoading = createSelector(
    [getByStDt],
    byStDt => byStDt.byStDtLoading
)

export const getFilterNearestList = createSelector(
    [getFilterNearest],
    state => state.filterNearest
)

export const geFilterNearestError = createSelector(
    [getFilterNearest],
    state => state.filterNearestError
)

export const getFilterNearestLoading = createSelector(
    [getFilterNearest],
    state => state.filterNearestLoading
)

export const getTempleTxtList = createSelector(
    [getTempleTxt],
    templeTxt => templeTxt.templeTxt
)

export const getTempleTxtError = createSelector(
    [getTempleTxt],
    templeTxt => templeTxt.templeTxtError
)

export const getTempleTxtLoading = createSelector(
    [getTempleTxt],
    templeTxt => templeTxt.templeTxtLoading
)

export const getResourceList = createSelector(
    [getResource],
    resource => resource.resource
)

export const getResourceError = createSelector(
    [getResource],
    state => state.resourceError
)

export const getResourceLoading = createSelector(
    [getResource],
    state => state.resourceLoading
)


export const getResourceDetailsList = createSelector(
    [getResourceDetails],
    resourceDetails => resourceDetails.resourceDetails
)

export const getResourceDetailsError = createSelector(
    [getResourceDetails],
    state => state.resourceDetailsError
)

export const getResourceDetailsLoading = createSelector(
    [getResourceDetails],
    state => state.resourceDetailsLoading
)

export const getTempleGroupList = createSelector(
    [getTempleGroup],
    templeGroup => templeGroup.templeGroup
)

export const getTempleGroupError = createSelector(
    [getTempleGroups],
    templeGroup => templeGroup.templeGroupError
)

export const getTempleGroupLoading = createSelector(
    [getTempleGroup],
    templeGroup => templeGroup.templeGroupLoading
)

export const getGroupDescriptionList = createSelector(
    [getGroupDescription],
    groupDescription => groupDescription.groupDescription
)

export const getGroupDescriptionError = createSelector(
    [getGroupDescription],
    groupDescription => groupDescription.groupDescriptionError
)

export const getGroupDescriptionLoading = createSelector(
    [getGroupDescription],
    groupDescription => groupDescription.groupDescriptionLoading
)

export const getTempleBooksList = createSelector(
    [getTempleBooks],
    templeBooks => templeBooks.templeBooks
)

export const getTempleBooksError = createSelector(
    [getTempleBooks],
    templeBooks => templeBooks.templeBooksError
)

export const getTempleBooksLoading = createSelector(
    [getTempleBooks],
    templeBooks => templeBooks.templeBooksLoading
)

export const getTempleFavouritesList = createSelector(
    [getTempleFavourites],
    templeFavourites => templeFavourites.templeFavourites
)

export const getTempleFavouritesError = createSelector(
    [getTempleFavourites],
    templeFavourites => templeFavourites.templeFavouritesError
)

export const getTempleFavouritesLoading = createSelector(
    [getTempleFavourites],
    templeFavourites => templeFavourites.templeFavouritesLoading
)

export const getRefreshTokenList = createSelector(
    [getRefreshToken],
    refreshToken => refreshToken.refreshToken
)

export const getRefreshTokenError = createSelector(
    [getRefreshToken],
    refreshToken => refreshToken.refreshTokenError
)

export const getRefreshTokenLoading = createSelector(
    [getRefreshToken],
    refreshToken => refreshToken.refreshTokenLoading
)

export const getRecentlyViewedList = createSelector(
    [getRecentlyViewed],
    recentlyViewed => recentlyViewed.recentlyViewed
)

export const getRecentlyViewedError = createSelector(
    [getRecentlyViewed],
    recentlyViewed => recentlyViewed.recentlyViewedError
)

export const getRecentlyViewedLoading = createSelector(
    [getRecentlyViewed],
    recentlyViewed => recentlyViewed.recentlyViewedLoading
)

export const getTempleNotifiList = createSelector(
    [getTempleNotifi],
    templeNotifi => templeNotifi.templeNotifi
)

export const getTempleNotifiError = createSelector(
    [getTempleNotifi],
    templeNotifi => templeNotifi.templeNotifiError
)

export const getTempleNotifiLoading = createSelector(
    [getTempleNotifi],
    templeNotifi => templeNotifi.templeNotifiLoading
)

export const getRegionalNotifiList = createSelector(
    [getRegionalNotifi],
    regionalNotifi => regionalNotifi.regionalNotifi
)

export const getRegionalNotifiError = createSelector(
    [getRegionalNotifi],
    regionalNotifi => regionalNotifi.regionalNotifiError
)

export const getRegionalNotifiLoading = createSelector(
    [getRegionalNotifi],
    regionalNotifi => regionalNotifi.regionalNotifiLoading
)

export const getBooksByTagList = createSelector(
    [getBooksByTag],
    booksByTag => booksByTag.booksByTag
)

export const getBooksByTagError = createSelector(
    [getBooksByTag],
    booksByTag => booksByTag.booksByTagError
)

export const getBooksByTagLoading = createSelector(
    [getBooksByTag],
    booksByTag => booksByTag.booksByTagLoading
)

export const getAnonomousUserList = createSelector(
    [getAnonomousUser],
    anonomousUser => anonomousUser.anonomousUser
)

export const getAnonomousUserError = createSelector(
    [getAnonomousUser],
    anonomousUser => anonomousUser.anonomousUserError
)

export const getAnonomousUserLoading = createSelector(
    [getAnonomousUser],
    anonomousUser => anonomousUser.anonomousUserLoading
)

export const getDailyHoroscopeDetailsList = createSelector(
    [getDailyHoroscopeDetails],
    dailyHoroscopeDetails => dailyHoroscopeDetails.dailyHoroscopeDetails
)
export const getDailyHoroscopeDetailsError = createSelector(
    [getDailyHoroscopeDetails],
    dailyHoroscopeDetails => dailyHoroscopeDetails.dailyHoroscopeDetailsError
)

export const getDailyHoroscopeDetailsLoading = createSelector(
    [getDailyHoroscopeDetails],
    dailyHoroscopeDetails => dailyHoroscopeDetails.dailyHoroscopeDetailsLoading
)

export const getNotificationDetailsList = createSelector(
    [getNotificationDetails],
    notificationDetails => notificationDetails.notificationDetails
)

export const getNotificationDetailsError = createSelector(
    [getNotificationDetails],
    notificationDetails => notificationDetails.notificationDetailsError
)

export const getNotificationDetailsLoading = createSelector(
    [getNotificationDetails],
    notificationDetails => notificationDetails.notificationDetailsLoading
)

export const getWeeklyHoroscopeDetailsList = createSelector(
    [getWeeklyHoroscopeDetails],
    weeklyHoroscopeDetails => weeklyHoroscopeDetails.weeklyHoroscopeDetails
)
export const getWeeklyHoroscopeDetailsError = createSelector(
    [getWeeklyHoroscopeDetails],
    weeklyHoroscopeDetails => weeklyHoroscopeDetails.weeklyHoroscopeDetailsError
)

export const getWeeklyHoroscopeDetailsLoading = createSelector(
    [getWeeklyHoroscopeDetails],
    weeklyHoroscopeDetails => weeklyHoroscopeDetails.weeklyHoroscopeDetailsLoading
)

export const getResourceByTxtList = createSelector(
    [getResourceByTxt],
    resourceByTxt => resourceByTxt.resourceByTxt
)

export const getResourceByTxtError = createSelector(
    [getResourceByTxt],
    state => state.resourceByTxtError
)

export const getResourceByTxtLoading = createSelector(
    [getResourceByTxt],
    state => state.resourceByTxtLoading
)

export const getDonationsList = createSelector(
    [getDonations],
    donationsList => donationsList.donationsList
)

export const getDonationsListLoading = createSelector(
    [getDonations],
    donationsList => donationsList.donationsListLoading
)

export const getDonationsListError = createSelector(
    [getDonations],
    donationsList => donationsList.donationsListError
)


export const getDonationDetails = createSelector(
    [getDonationsDetails],
    donationsDetails => donationsDetails.donationsDetails
)

export const getDonationDetailsError = createSelector(
    [getDonationsDetails],
    donationsDetails => donationsDetails.donationsDetailsError
)

export const getDonationDetailsLoading = createSelector(
    [getDonationsDetails],
    donationsDetails => donationsDetails.donationsDetailsLoading
)

export const getDonationsByMonthList = createSelector(
    [getDonationsByMonth],
    donationsByMonth => donationsByMonth.donationsByMonth
)

export const getDonationsByMonthListLoading = createSelector(
    [getDonationsByMonth],
    donationsByMonth => donationsByMonth.donationsByMonthLoading
)

export const getDonationsByMonthListError = createSelector(
    [getDonationsByMonth],
    donationsByMonth => donationsByMonth.donationsByMonthError
)

export const getExpensesList = createSelector(
    [getExpenses],
    expensesList => expensesList.expensesList
)

export const getExpensesListLoading = createSelector(
    [getExpenses],
    expensesList => expensesList.expensesListLoading
)

export const getExpensesListError = createSelector(
    [getExpenses],
    expensesList => expensesList.expensesListError
)

export const getExpenseDetails = createSelector(
    [getExpensesDetails],
    expensesDetails => expensesDetails.expensesDetails
)

export const getExpenseDetailsError = createSelector(
    [getExpensesDetails],
    expensesDetails => expensesDetails.expensesDetailsError
)

export const getExpenseDetailsLoading = createSelector(
    [getExpensesDetails],
    expensesDetails => expensesDetails.expensesDetailsLoading
)

export const getTempleGroupDetailsList = createSelector(
    [getTempleGroupDetails],
    templeGroupDetails => templeGroupDetails.templeGroupDetails
)

export const getTempleGroupDetailsError = createSelector(
    [getTempleGroupDetails],
    templeGroupDetails => templeGroupDetails.templeGroupDetailsError
)

export const getTempleGroupDetailsLoading = createSelector(
    [getTempleGroupDetails],
    templeGroupDetails => templeGroupDetails.templeGroupDetailsLoading
)

export const getPriestDetailsList = createSelector(
    [getPriestDetails],
    priestDetails => priestDetails.priestDetails
)

export const getPriestDetailsError = createSelector(
    [getPriestDetails],
    priestDetails => priestDetails.priestDetailsError
)

export const getPriestDetailsLoading = createSelector(
    [getPriestDetails],
    priestDetails => priestDetails.priestDetailsLoading
)


export const getPriestBySpcltyList = createSelector(
    [getPriestBySpclty],
    priestBySpclty => priestBySpclty.priestBySpclty
)

export const getPriestBySpcltyError = createSelector(
    [getPriestBySpclty],
    priestBySpclty => priestBySpclty.priestBySpcltyError
)

export const getPriestBySpcltyLoading = createSelector(
    [getPriestBySpclty],
    priestBySpclty => priestBySpclty.priestBySpcltyLoading
)

export const getMyRequestsList = createSelector(
    [getMyRequests],
    myRequests => myRequests.myRequests
)

export const getMyRequestsError = createSelector(
    [getMyRequests],
    myRequests => myRequests.myRequestsError
)

export const getMyRequestsLoading = createSelector(
    [getMyRequests],
    myRequests => myRequests.myRequestsLoading
)

export const getPriestRequestsList = createSelector(
    [getPriestRequests],
    priestRequests => priestRequests.priestRequests
)

export const getPriestRequestsError = createSelector(
    [getPriestRequests],
    priestRequests => priestRequests.priestRequestsError
)

export const getPriestRequestsLoading = createSelector(
    [getPriestRequests],
    priestRequests => priestRequests.priestRequestsLoadingPriest
)

export const getMyRequestsDetailsList = createSelector(
    [getMyRequestsDetails],
    myRequestsDetails => myRequestsDetails.myRequestsDetails
)

export const getMyRequestsDetailsError = createSelector(
    [getMyRequestsDetails],
    myRequestsDetails => myRequestsDetails.myRequestsDetailsError
)

export const getMyRequestsDetailsLoading = createSelector(
    [getMyRequestsDetails],
    myRequestsDetails => myRequestsDetails.myRequestsDetailsLoading
)

export const getTempleMantrasList = createSelector(
    [getTempleMantras],
    templeMantras => templeMantras.templeMantras
)


export const getTempleMantrasError = createSelector(
    [getTempleMantras],
    templeMantras => templeMantras.templeMantrasError
)

export const getTempleMantrasLoading = createSelector(
    [getTempleMantras],
    templeMantras => templeMantras.templeMantrasLoading
)

export const getDailyHoroscopeLis = createSelector(
    [getDailyHoroscopeLists],
    dailyHoroscopeList => dailyHoroscopeList.dailyHoroscopeList
)

export const getDailyHoroscopeListError = createSelector(
    [getDailyHoroscopeLists],
    dailyHoroscopeList => dailyHoroscopeList.dailyHoroscopeListError
)

export const getDailyHoroscopeListLoading = createSelector(
    [getDailyHoroscopeLists],
    dailyHoroscopeList => dailyHoroscopeList.dailyHoroscopeListLoading
)

export const getWeeklyHoroscopeLis = createSelector(
    [getWeeklyHoroscopeLists],
    weeklyHoroscopeList => weeklyHoroscopeList.weeklyHoroscopeList
)

export const getWeeklyHoroscopeListError = createSelector(
    [getWeeklyHoroscopeLists],
    weeklyHoroscopeList => weeklyHoroscopeList.weeklyHoroscopeListError
)

export const getWeeklyHoroscopeListLoading = createSelector(
    [getWeeklyHoroscopeLists],
    weeklyHoroscopeList => weeklyHoroscopeList.weeklyHoroscopeListLoading
)

export const getServicesByUserList = createSelector(
    [getServicesByUserLists],
    servicesByUser => servicesByUser.servicesByUser
)

export const getServicesByUserListError = createSelector(
    [getServicesByUserLists],
    servicesByUser => servicesByUser.servicesByUserError
)

export const getServicesByUserListLoading = createSelector(
    [getServicesByUserLists],
    servicesByUser => servicesByUser.servicesByUserLoading
)

export const getServicesByAdminList = createSelector(
    [getServicesByAdminLists],
    servicesByAdmin => servicesByAdmin.servicesByAdmin
)

export const getServicesByAdminListError = createSelector(
    [getServicesByAdminLists],
    servicesByAdmin => servicesByAdmin.servicesByAdminError
)

export const getServicesByAdminListLoading = createSelector(
    [getServicesByAdminLists],
    servicesByAdmin => servicesByAdmin.servicesByAdminLoading
)

export const getServicesDetailsList = createSelector(
    [getServicesDetailsLists],
    servicesDetails => servicesDetails.servicesDetails
)

export const getServicesDetailsListError = createSelector(
    [getServicesDetailsLists],
    servicesDetails => servicesDetails.servicesDetailsError
)

export const getServicesDetailsListLoading = createSelector(
    [getServicesDetailsLists],
    servicesDetails => servicesDetails.servicesDetailsLoading
)

export const getTempleServiceRegistrationList = createSelector(
    [getTempleServiceRegistration],
    templeServiceRegistration => templeServiceRegistration.templeServiceRegistration
)

export const getTempleServiceRegistrationListError = createSelector(
    [getTempleServiceRegistration],
    templeServiceRegistration => templeServiceRegistration.templeServiceRegistrationError
)

export const getTempleServiceRegistrationListLoading = createSelector(
    [getTempleServiceRegistration],
    templeServiceRegistration => templeServiceRegistration.templeServiceRegistrationLoading
)

export const getServiceRegDetailsList = createSelector(
    [getServiceRegDetails],
    serviceRegDetails => serviceRegDetails.serviceRegDetails
)

export const getServiceRegDetailsListError = createSelector(
    [getServiceRegDetails],
    serviceRegDetails => serviceRegDetails.serviceRegDetailsError
)

export const getServiceRegDetailsListLoading = createSelector(
    [getServiceRegDetails],
    serviceRegDetails => serviceRegDetails.serviceRegDetailsLoading
)

export const getUserServiceRegistrationList = createSelector(
    [getUserServiceRegistration],
    userServiceRegistration => userServiceRegistration.userServiceRegistration
)

export const getUserServiceRegistrationListError = createSelector(
    [getUserServiceRegistration],
    userServiceRegistration => userServiceRegistration.userServiceRegistrationError
)

export const getUserServiceRegistrationListLoading = createSelector(
    [getUserServiceRegistration],
    userServiceRegistration => userServiceRegistration.userServiceRegistrationLoading
)

export const getTempleDashboardList = createSelector(
    [getTempleDashboard],
    templeDashboardList => templeDashboardList.templeDashboardList
)

export const getTempleDashboardListError = createSelector(
    [getTempleDashboard],
    templeDashboard => templeDashboard.templeDashboardError
)

export const getTempleDashboardListLoading = createSelector(
    [getTempleDashboard],
    templeDashboard => templeDashboard.templeDashboardLoading
)

export const getTempleMembersList = createSelector(
    [getTempleMembers],
    templeMembersList => templeMembersList.templeMembersList
)

export const getTempleMembersListError = createSelector(
    [getTempleMembers],
    templeMembers => templeMembers.templeMembersError
)

export const getTempleMembersListLoading = createSelector(
    [getTempleMembers],
    templeMembers => templeMembers.templeMembersLoading
)

export const getPendingTemplesList = createSelector(
    [getPendingTemples],
    pendingTemplesList => pendingTemplesList.pendingTemplesList
)

export const getPendingTemplesListError = createSelector(
    [getPendingTemples],
    pendingTemples => pendingTemples.pendingTemplesError
)

export const getPendingTemplesListLoading = createSelector(
    [getPendingTemples],
    pendingTemples => pendingTemples.pendingTemplesLoading
)

export const getTempleUsersByStList = createSelector(
    [getTempleUsersBySt],
    templeUsersBySt => templeUsersBySt.templeUsersBySt
)

export const getTempleUsersByStListError = createSelector(
    [getTempleUsersBySt],
    templeUsersBySt => templeUsersBySt.templeUsersByStError
)

export const getTempleUsersByStListLoading = createSelector(
    [getTempleUsersBySt],
    templeUsersBySt => templeUsersBySt.templeUsersByStLoading
)

export const getTempleDetailsInOtherLang = createSelector(
    [getDetailsInOtherLang],
    templeDetailsInOtherLang => templeDetailsInOtherLang.templeDetailsInOtherLang
)

export const getTempleDetailsInOtherLangError = createSelector(
    [getDetailsInOtherLang],
    templeDetailsInOtherLang => templeDetailsInOtherLang.templeDetailsInOtherLangError
)

export const getTempleDetailsInOtherLangLoading = createSelector(
    [getDetailsInOtherLang],
    templeDetailsInOtherLang => templeDetailsInOtherLang.templeDetailsInOtherLangLoading
)

export const getHoroDetailsByDtList = createSelector(
    [getHoroDetailsByDt],
    horoDetailsByDt => horoDetailsByDt.horoDetailsByDt
)
export const getHoroDetailsByDtError = createSelector(
    [getHoroDetailsByDt],
    horoDetailsByDt => horoDetailsByDt.horoDetailsByDtError
)

export const getHoroDetailsByDtLoading = createSelector(
    [getHoroDetailsByDt],
    horoDetailsByDt => horoDetailsByDt.horoDetailsByDtLoading
)

export const getAppUsersList = createSelector(
    [getAppUsers],
    appUsers => appUsers.appUsers
)

export const getAppUsersListError = createSelector(
    [getAppUsers],
    appUsers => appUsers.appUsersError
)

export const getAppUsersListLoading = createSelector(
    [getAppUsers],
    appUsers => appUsers.appUsersLoading
)

export const getFilterByStDtTxtList = createSelector(
    [getFilterByStDtTxt],
    filterByStDtTxt => filterByStDtTxt.filterByStDtTxt
)

export const getFilterByStDtTxtError = createSelector(
    [getFilterByStDtTxt],
    filterByStDtTxt => filterByStDtTxt.filterByStDtTxtError
)

export const getFilterByStDtTxtLoading = createSelector(
    [getFilterByStDtTxt],
    filterByStDtTxt => filterByStDtTxt.filterByStDtTxtLoading
)

export const getCityStDescrList = createSelector(
    [getCityStDescr],
    cityStDescr => cityStDescr.cityStDescrList
)

export const getCityStDescrListError = createSelector(
    [getCityStDescr],
    cityStDescr => cityStDescr.cityStDescrListError
)

export const getCityStDescrListLoading = createSelector(
    [getCityStDescr],
    cityStDescr => cityStDescr.cityStDescrListLoading
)

export const getTemplesOutsideIndList = createSelector(
    [getTemplesOutsideInd],
    templesOutsideInd => templesOutsideInd.templesOutsideInd
)

export const getTemplesOutsideIndListError = createSelector(
    [getTemplesOutsideInd],
    templesOutsideInd => templesOutsideInd.templesOutsideIndListError
)

export const getTemplesOutsideIndListLoading = createSelector(
    [getTemplesOutsideInd],
    templesOutsideInd => templesOutsideInd.templesOutsideIndListLoading
)

export const getResourceFavouritesList = createSelector(
    [getResourceFavourites],
    resourceFavourites => resourceFavourites.resourceFavourites
)

export const getResourceFavouritesError = createSelector(
    [getResourceFavourites],
    resourceFavourites => resourceFavourites.resourceFavouritesError
)

export const getResourceFavouritesLoading = createSelector(
    [getResourceFavourites],
    resourceFavourites => resourceFavourites.resourceFavouritesLoading
)

export const gettempleMinDetailsList = createSelector(
    [getTempleMinDetails],
    templeMinDetails => templeMinDetails.templeMinDetails
)

export const gettempleMinDetailsError = createSelector(
    [getTempleMinDetails],
    templeMinDetails => templeMinDetails.templeMinDetailsError
)

export const gettempleMinDetailsLoading = createSelector(
    [getTempleMinDetails],
    templeMinDetails => templeMinDetails.templeMinDetailsLoading
)

export const getResAudiosList = createSelector(
    [getResourceAudios],
    resAudios => resAudios.resAudios
)
export const getResAudiosError = createSelector(
    [getResourceAudios],
    resAudios => resAudios.resAudiosError
)

export const getResAudiosLoading = createSelector(
    [getResourceAudios],
    resAudios => resAudios.resAudiosLoading
)

export const getResLivetvList = createSelector(
    [getResourceLivetv],
    resLivetv => resLivetv.resLivetv
)
export const getResLivetvError = createSelector(
    [getResourceLivetv],
    resLivetv => resLivetv.resLivetvError
)

export const getResLivetvLoading = createSelector(
    [getResourceLivetv],
    resLivetv => resLivetv.resLivetvLoading
)

export const getResVideosList = createSelector(
    [getResourceVideos],
    resVideos => resVideos.resVideos
)
export const getResVideosError = createSelector(
    [getResourceVideos],
    resVideos => resVideos.resVideosError
)

export const getResVideosLoading = createSelector(
    [getResourceVideos],
    resVideos => resVideos.resVideosLoading
)

export const getTempleComplaintsList = createSelector(
    [getTempleComplaints],
    templeComplaints => templeComplaints.templeComplaints
)

export const getTempleComplaintsListError = createSelector(
    [getTempleComplaints],
    templeComplaints => templeComplaints.templeComplaintsError
)

export const getTempleComplaintsLoading = createSelector(
    [getTempleComplaints],
    templeComplaints => templeComplaints.templeComplaintsLoading
)

export const getProfileImageList = createSelector(
    [getProfileImage],
    profileImage => profileImage
)
export const getProfileImageError = createSelector(
    [getProfileImage],
    profileImage => profileImage.profileImageError
)

export const getProfileImageLoading = createSelector(
    [getProfileImage],
    profileImage => profileImage.profileImageLoading
)

export const fetchUserFullName = createSelector(
    [fetchUserName],
    fetchUserFullName => fetchUserFullName.fetchUserFullName
)

export const fetchUserFullNameError = createSelector(
    [fetchUserName],
    fetchUserFullName => fetchUserFullName.fetchUserFullNameError
)

export const fetchUserFullNameLoading = createSelector(
    [fetchUserName],
    fetchUserFullName => fetchUserFullName.fetchUserFullNameLoading
)

export const getTemplePreviewImgList = createSelector(
    [getTemplePreviewImg],
    templeReviewImg => templeReviewImg.templeReviewImg
)
export const getTemplePreviewImgError = createSelector(
    [getTemplePreviewImg],
    templeReviewImg => templeReviewImg.templeReviewImgError
)

export const getTemplePreviewImgLoading = createSelector(
    [getTemplePreviewImg],
    templeReviewImg => templeReviewImg.templeReviewImgLoading
)