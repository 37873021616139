import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Result, Select, message } from 'antd';
import { fetchMyRequests } from '../../redux/actions/acions';
import { getMyRequestsError, getMyRequestsList, getMyRequestsLoading } from '../../redux/selectors/selectors';
import CustomToastContainer from '../../components/common/CustomToastContainer';
import Footer from '../pages/Homepage/footer/footer';
import { useTranslation } from 'react-i18next';
import Error from '../pages/Homepage/error';

const MyReq = () => {
    const { t } = useTranslation();
    const storedLanguage = localStorage.getItem('lng') || 5;
    let lr = 0;
    const dispatch = useDispatch();
    const [messageApi, contextHolder] = message.useMessage();
    const navigate = useNavigate();

    const { rList, rLoading, rError } = useSelector(state => ({
        rList: getMyRequestsList(state),
        rLoading: getMyRequestsLoading(state),
        rError: getMyRequestsError(state),
    }));

    const [selectedValue, setSelectedValue] = useState('all');

    useEffect(() => {
        const status = selectedValue;
        dispatch(fetchMyRequests(status, lr));
    }, [dispatch, selectedValue]);


    const handleSelectChange = (value) => {
        setSelectedValue(value);
    };

    const handleGoBack = () => {
        navigate(-1);
    };

    return (
        <div className='mainsec'>
            <div class=" mob-nav fluid-container page-title justify-content-between">
                <div class="d-flex align-items-center left-sec-mob-nav">
                    <Link onClick={handleGoBack} ><i class="fa-solid fa-angle-left"></i></Link>
                    <span class="bc-link">{t('label_my_requests')}</span>
                </div>
                <div></div>
            </div>
            {contextHolder}

            <div className='f2 font-weight-bold mb-3 mt-3 container title-head-txt breadcrumb-link'>
                <Link style={{ color: 'black' }} to='/'>
                    <span className="bc-active-link">{t("Home")}</span>
                </Link>
                &nbsp;-&nbsp;
                <span className="bc-link" style={{ marginRight: '10px' }}>
                    {t("label_my_requests")}
                </span>
            </div>
            <div class="bg-details-highlight">
                <div class=" details-headertxt-singleLine container">
                    <div className="header_txt">
                        {t("label_my_requests")}
                    </div>
                    <Select
                        onChange={handleSelectChange}
                        name="spclty"
                        value={selectedValue}
                        className="select-element my-custom-select"
                        placeholder='Please select status'
                    >
                        <Select.Option value="all">{t('label_all')}</Select.Option>
                        <Select.Option value="Open">{t('label_see_them')}</Select.Option>
                        <Select.Option value="Closed">{t('label_close')}</Select.Option>
                    </Select>
                </div>
            </div>
            <div className="mainsec-block-addtemple-detailspage">
                <div className='container mt-3'>
                    {rLoading ? (
                        <Error />
                    ) : rError ? (
                        <Result
                            status="500"
                            title="Error"
                            subTitle="Sorry, there was an error fetching the data."
                        />
                    ) : rList && rList.length > 0 ? (
                        rList.map((notificationList) => (
                            <Link to={`/reqDetails/${notificationList.reqid}`} className="notification-link" key={notificationList.nid}>
                                <div className="tiles-designs-events">
                                    <div className="d-flex flex-space-between">
                                        <div className="f1 events-block-link-title">
                                            <i className="fa-solid fa-calendar-week mr-3"></i>
                                            <span className="f1 events-block-link-value">
                                                {`${notificationList.reqtype === 'V'
                                                    ? t('label_vratham')
                                                    : notificationList.reqtype === 'J'
                                                        ? t('label_jathakam')
                                                        : t('label_notchosen')} - ${notificationList.status}`}
                                            </span>
                                        </div>
                                        <div className="d-flex">
                                            <Link to={`/reqDetails/${notificationList.reqid}`}>
                                                <div className="detail-but ml-3">
                                                    {t('label_details')}
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                    <span className="f1 events-block-link-value">
                                    {new Date(notificationList.ludt).toISOString().split('T')[0]}
                                    </span>
                                </div>
                            </Link>
                        ))
                    ) : (
                        <Result
                            status="404"
                            title={t("label_nodata_found")}
                            subTitle={t("label_no_reqs_msg")}
                        />
                    )}
                </div>
                <Footer />
            </div>        
        </div>
    );
};

export default MyReq;
