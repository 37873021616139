import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Typography from 'antd/es/typography/Typography';
import { getBooksByTagError, getBooksByTagList, getBooksByTagLoading, getBooksError, getBooksFavouritesError, getBooksFavouritesList, getBooksFavouritesLoading, getBooksList, getBooksLoading, getFavouritesError, getFavouritesList, getFavouritesLoading, getGodNames, getGodNamesError, getGodNamesLoading, getPriestBySpcltyError, getPriestBySpcltyList, getPriestBySpcltyLoading, getResourceByTxtError, getResourceByTxtList, getResourceByTxtLoading } from '../../redux/selectors/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBooks, fetchBooksByTag, fetchBooksFavourites, fetchFavourites, fetchGodNames, fetchPriestSpclty, fetchResourceByTxt, postBooksFavourites, postFavourites, removeBooksFavourites, removeFavourites, retrieveGodNames } from '../../redux/actions/acions';
import { Button, Card, Col, FloatButton, Image, Input, Modal, Result, Row, Select, Skeleton, message } from 'antd';
import deftemples from '../../assets/images/templeicon.jpg';
import { CloseCircleOutlined, EditOutlined, RocketTwoTone, SearchOutlined } from '@ant-design/icons';
import { getImageAPI } from "../../http-common";
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import TempleCard from '../common/card';
import statesData from '../pages/states.json';
import Footer from '../pages/Homepage/footer/footer';
import showToast from '../../utils/showToast';
import CustomToastContainer from '../../components/common/CustomToastContainer';
import temple1 from "../../assets/images/pandit.png";

let last_rec = 0;
let god_last_rec = 0;
function PriestBySpclty() {
    const { t } = useTranslation();
    const records_per_page = 12;
    const [isLoading, setIsLoading] = useState(false);
    const storedLanguage = localStorage.getItem('lng') || 5;
    const [did, setDid] = useState(0);
    const [tag, setTag] = useState();
    const [selectedItem, setSelectedItem] = useState(null);
    const userid = localStorage.getItem('urole')
    const localEnv = false;
    const [filterId, setFilterId] = useState(0);
    const dispatch = useDispatch()
    const [messageApi, contextHolder] = message.useMessage();
    const { Search } = Input;
    // const failure = (msg) => {
    //   messageApi.open({
    //     type: 'error',
    //     content: msg,
    //   });
    // };
    const { uid } = useParams()

    const [selectedState, setSelectedState] = useState('State');
    const [selectedCity, setSelectedCity] = useState('City');
    const [selectedSpclty, setSelectedSpclty] = useState('all');
    const [state, setState] = useState(null);
    const { Option } = Select;

    const [filteredStates, setFilteredStates] = useState([]);
    const [filteredCities, setFilteredCities] = useState([]);

    const handleNavigation = () => {
        dispatch(fetchPriestSpclty(selectedState, selectedCity, selectedSpclty, 0))
        // Use the navigate function to navigate to the other page with selected state and city as route parameters
        //navigate(`/state/${selectedState}/city/${selectedCity}`);
        // closeModal();
    };

    const states = statesData.states.map((state) => state.name);

    const handleStateChange = (value) => {
        const newState = statesData.states.find((state) => state.name === value);
        setSelectedState(value); // Update the selected state
        setSelectedCity("All"); // Reset the selected city to "All"
        setState(newState);
    };

    const handleStateSearch = (value) => {
        const filteredStates = states.filter((stateName) =>
            stateName.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredStates(filteredStates);
    };

    // Function to handle city search
    const handleCitySearch = (value) => {
        const cities = state ? state.cities : [];
        const filteredCities = cities.filter((cityName) =>
            cityName.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredCities(filteredCities);
    };

    const [books, setbooks] = useState('books');
    const { bDetails, bLoading, bError } = useSelector(state => ({
        bDetails: getPriestBySpcltyList(state),
        bLoading: getPriestBySpcltyLoading(state),
        bError: getPriestBySpcltyError(state),
    }))

    useEffect(() => {
        i18next.changeLanguage(localStorage.getItem('i18nextLng'))
        //dispatch(fetchContributions(0, failure));
    }, [])



    const nextTemplesList = async () => {
        // setIsLoading(true);
        last_rec = last_rec + records_per_page;
        // did == filterId ? god_last_rec = god_last_rec + records_per_page : god_last_rec = 0 + records_per_page;
        dispatch(fetchBooks(storedLanguage, did, last_rec));
        // setIsLoading(false);
    }

    function prevTemplesList() {
        last_rec = last_rec - records_per_page;
        last_rec = (last_rec < 0) ? 0 : last_rec;
        // god_last_rec = god_last_rec - records_per_page;
        // god_last_rec = (god_last_rec < 0) ? 0 : god_last_rec;
        dispatch(fetchBooks(storedLanguage, did, last_rec))
    };

    const [refresh, setRefresh] = useState(false);

    const success = (title) => {
        messageApi.open({
            type: 'success',
            content: `Added ${title} to favorites`,
        });
    };
    const warning = (title) => {
        messageApi.open({
            type: 'warning',
            content: `Removed ${title} from favourites`,
        });
    };

    const failure = (msg) => {
        showToast('fail', t(`label_priestsbysplty_failure`));
        // messageApi.open({
        //     type: 'error',
        //     content: 'Please Login to see additional features',
        // });
    };

    const [getFavouriteTemples, setGetFavoriteTemples] = useState(false);
    const [filledTemples, setFilledTemples] = useState({});

    const { fList, fLoading, fError } = useSelector(state => ({
        fList: getBooksFavouritesList(state),
        fLoading: getBooksFavouritesLoading(state),
        fError: getBooksFavouritesError(state),
    }));

    useEffect(() => {
        dispatch(fetchBooksFavourites());
    }, [getFavouriteTemples, refresh]);

    const [favList, setFavList] = useState([]);

    useEffect(() => {
        if (fList) {
            setFavList(fList.fav_list);
        }
    }, [fList]);



    function addFavourites(rid) {
        dispatch(postBooksFavourites(rid, failure))
    }

    function deleteFavourites(deleteId) {
        dispatch(removeBooksFavourites(deleteId, failure));
    }

    // let favList = (fLoading === true || !Array.isArray(fList)) ? [] : fList.map(id => id.rid);

    const getToken = Boolean(localStorage.getItem('token'));

    function toggleFavorite(temple) {
        if (getToken) {
            const templeId = temple.rid;
            if (favList.includes(templeId)) {
                // Temple is in favList, remove it
                deleteFavourites(templeId, temple.title);
                warning(temple.title);
                // Update favList by removing the temple ID
                setFavList(prevFavList => prevFavList.filter(id => id !== templeId));
            } else {
                // Temple is not in favList, add it
                addFavourites(templeId, temple.title);
                success(temple.title);
                // Update favList by adding the temple ID
                setFavList(prevFavList => [...prevFavList, templeId]);
            }
        } else {
            // Handle the case when there's no token (e.g., show a message or redirect to login)
            failure("Please log in to favorite temples");
        }
    }


    const [filled, setFilled] = useState({});

    function toggleFilled(id) {
        setFilledTemples((prevFilled) => ({
            ...prevFilled,
            [id]: !prevFilled[id],
        }));
    }

    const navigate = useNavigate();
    const handleGoBack = () => {
        navigate(-1);
    };

    return (
        <>
            {contextHolder}

            <div class="mainsec">
                <div class=" mob-nav fluid-container page-title justify-content-between">
                    <div class="d-flex align-items-center left-sec-mob-nav">
                        <Link onClick={handleGoBack} ><i class="fa-solid fa-angle-left"></i></Link>
                        <span class="bc-link">{t('label_priest_by_spclty')}</span>
                    </div>
                    <div></div>
                </div>

                <div className='f2 font-weight-bold mb-3 mt-3 container title-head-txt breadcrumb-link'>

                    <div>
                        <Link style={{ color: 'black' }} to='/'>
                            <span className="bc-active-link">{t('Home')}</span>
                        </Link> -{' '}
                        <span className="bc-link">{t('label_priest_by_spclty')}</span>{' '}
                    </div>
                </div>
                <div class="bg-details-highlight" >
                    <div class=" details-headertxt container">
                        <div class="header_txt"> {t("label_priest_by_spclty")}</div>
                        <div class="">

                        </div>
                        <div class="d-flex flex-row header_star mr-5">
                            <span class="f18 mb-2 colorHeadingviolet fw-7 events-icon">
                            </span>
                        </div>
                    </div>
                </div>

                <div class="mainsec-block-addtemple-detailspage">
                    <div className='container'>
                        <div className='search_page' >
                            <div className="d-flex mt-2 mb-2 custom-scrollbar">
                                <Select
                                    showSearch  // Enable search
                                    name="state"
                                    value={selectedState}
                                    onChange={(value) => handleStateChange(value)}
                                    onSearch={(value) => handleStateSearch(value)} // Add search function
                                    className="select-element"
                                    style={{ marginRight: '5px', width: '25%' }}
                                    placeholder={t("label_pls_sel_st")}
                                >
                                    <Option value="State">{t('label_select_state')}</Option>
                                    <Option value="All">{t('label_all')}</Option>
                                    {states.map((stateName) => (
                                        <Select.Option key={stateName} value={stateName}>
                                            {stateName}
                                        </Select.Option>
                                    ))}
                                </Select>
                                <Select
                                    showSearch  // Enable search
                                    name="city"
                                    value={selectedCity}
                                    onChange={(value) => setSelectedCity(value)}
                                    onSearch={(value) => handleCitySearch(value)} // Add search function
                                    className="select-element"
                                    style={{ width: '32%' }}
                                    placeholder={t("label_pls_sel_city")}
                                >
                                    <Option value="City">{t('label_select_city')}</Option>
                                    <Option value="All">{t('label_all')}</Option>
                                    {state && state.cities.map((city) => (
                                        <Select.Option key={city} value={city}>
                                            {city}
                                        </Select.Option>
                                    ))}
                                </Select>
                                <Select
                                    defaultValue="all"
                                    showSearch
                                    onChange={(value) => setSelectedSpclty(value)}
                                    name="spclty"
                                    value={selectedSpclty}
                                    className="select-element"
                                    style={{ marginLeft: '5px', width: '25%' }}
                                    placeholder={t('label_select_speciality')}
                                >
                                    <Select.Option value="">{t('label_select_speciality')}</Select.Option>
                                    <Select.Option value="all">{t('label_all')}</Select.Option>
                                    <Select.Option value="vratham">{t('label_vratham')}</Select.Option>
                                    <Select.Option value="jatakam">{t('label_jathakam')}</Select.Option>
                                </Select>


                                {selectedState && selectedCity && (
                                    <div className="button-sec" style={{ marginTop: '3px', marginLeft: "5px" }}>
                                        <Link class="button_move" type="submit" title="Search" onClick={handleNavigation}>
                                            {t("label_go")}
                                        </Link>
                                    </div>
                                )}
                                {/* {userid === "AS" || "AD" ? (
                                    <div className="button-sec" style={{ marginTop: '5px', marginLeft: "5px" }}>
                                        <Link to='/addPriest' class="button_move" type='primary'>
                                            {t('label_add_priest')}
                                        </Link>
                                    </div>
                                ) : (null)} */}
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <Row>

                            {bDetails && bDetails.length > 0 ? (
                                bDetails.map((temple) => (
                                    <TempleCard
                                        key={temple.rid}
                                        id={temple.rid}
                                        imgpath={temple1}
                                        title={temple.fullname}
                                        city={temple.au_city}
                                        state={temple.au_st}
                                        temple={temple}
                                        favList={favList}
                                        toggleFavorite={toggleFavorite}
                                        toggleFilled={toggleFilled}
                                        link={getToken ? (`/priestDetails/${temple.au_userid}/${temple.fullname}/${temple.au_city}/${temple.au_st}`) : (null)}
                                    />
                                ))
                            ) : (
                                <Result
                                    status="404"
                                    title={t("label_nodata_found")}
                                    subTitle={t("label_no_priests_msg")}
                                />
                                // <TempleCard
                                //         id={temple.tid}
                                //         newImage={temple}
                                //         title='Pallamacharya'
                                //         city='kakinada'
                                //         state='Andhra Pradesh'
                                //         temple={temple}
                                //         favList={favList}
                                //         toggleFavorite={toggleFavorite}
                                //         toggleFilled={toggleFilled}                                       
                                //         latit={temple.latit}
                                //         longi={temple.longi}
                                //         link={`/priestDetails/${temple.userid}`}
                                //     />
                            )}
                        </Row>

                        <div className="next-row">
                            {bDetails && bDetails.length > 0 ? (
                                <Col>
                                    <Button
                                        className={`godCtgry ${filterId === 1 ? 'filterMatch' : 'filterMismatch'}`}
                                        onClick={() => prevTemplesList()}
                                        disabled={last_rec === 0}
                                    >
                                        Prev
                                    </Button>
                                </Col>
                            ) : (
                                ''
                            )}
                            {bDetails && bDetails.length > 0 ? (
                                <Col>
                                    <Button
                                        className={`godCtgry ${filterId === 1 ? 'filterMatch' : 'filterMismatch'}`}
                                        onClick={() => nextTemplesList()}
                                        disabled={bDetails.length < records_per_page}
                                    >
                                        Next
                                    </Button>
                                </Col>
                            ) : (
                                ''
                            )}
                        </div>
                        {/* <FloatButton.BackTop icon={<img style={{ height: 30, width: 30, marginLeft: -5 }} src={temple} alt="temple" />} /> */}

                    </div>
                    <Footer />
                </div>
            </div>
        </>
    )
}


export default PriestBySpclty;