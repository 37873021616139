import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Footer from '../footer/footer';
import videos from '../../../../assets/images/video.jpg'
import books_library from '../../../../assets/images/books_library.jpg'
import audios from '../../../../assets/images/audio.jpg'
import mantras from '../../../../assets/images/mantras.jpg'
import articles from '../../../../assets/images/article.jpg'
import showToast from '../../../../utils/showToast';
import CustomToastContainer from '../../../common/CustomToastContainer';
import deftemples from '../../../../assets/images/templeicon.jpg'

const Library = () => {
    const { t } = useTranslation();
    const userRole = localStorage.getItem('urole');
    const [open, setOpen] = useState(false);
    const handleLinkClick = () => {
        setOpen(false);
        showToast('error', ('Please Login to use additional features'));
    };

    return (
        <>
            <CustomToastContainer />
            <div class="mainsec">
                <div class=" mob-nav fluid-container page-title justify-content-between">
                    <div class="d-flex align-items-center left-sec-mob-nav">
                        <Link to='/' ><i class="fa-solid fa-angle-left"></i></Link>
                        <span class="bc-link">{t('label_library')}</span>
                    </div>
                    <div></div>
                </div>
                <div className='f2 font-weight-bold mb-3 mt-3 container breadcrumb-link'>

                    <Link style={{ color: 'black' }} to='/'><span class="bc-active-link">{t('Home')}</span></Link> - <span class="bc-link">{t("label_library")}</span></div>
                <div class="mainsec-block">
                    <div class="f2 font-weight-bold  container title-head-txt " style={{ overflowX: 'auto', paddingBottom: '10px', whiteSpace: "nowrap" }}>
                        {userRole === 'AD' || userRole === 'AS' ? (
                            <Link to='/addResource'>
                                <button type="button" className="declineButton">
                                    {t("label_create_resource")}
                                    <i className="fa-solid fa-circle-right animate__animated animate__heartBeat"></i>
                                </button>
                            </Link>
                        ) : null}
                        {userRole === 'AD' || userRole === 'AA' || userRole === 'TA' || userRole === 'TU' || userRole === 'SU' || userRole === 'PU' || userRole === 'WR' ? (
                            <Link to='/resourceFavorite'>
                                <button
                                    type="button"
                                    className="declineButton"
                                >
                                    {t('label_library_fav')}
                                    <i className="fa-solid fa-circle-right animate__animated animate__heartBeat"></i>
                                </button>
                            </Link>
                        ) : (
                            <button
                                type="button"
                                className="declineButton"
                                onClick={handleLinkClick}
                            >
                                {t('label_library_fav')}
                                <i className="fa-solid fa-circle-right animate__animated animate__heartBeat"></i>
                            </button>
                        )}
                    </div>
                    <div className="container">
                        <div class="container">
                            <h5 class="card-text-title">{t("Categories")}
                            </h5>
                        </div>
                        <div className="tiles-main">
                            <Link to='/books'>
                                <div class="tiles-design-temple-cat animate__animated animate__flipInX">
                                    <div class="title-img-bg">
                                        <img class="card-img-temple-cat" alt="temples" src={books_library} />
                                    </div>
                                    <div class="tile-text-temple-cat">
                                        <span>{t("label_books")}</span> <span>{("label_temples_list")}</span>
                                    </div>
                                </div>
                            </Link>
                            <Link to='/videos'>
                                <div class="tiles-design-temple-cat animate__animated animate__flipInX">
                                    <div class="title-img-bg">
                                        <img class="card-img-temple-cat" alt="temples" src={videos} />
                                    </div>
                                    <div class="tile-text-temple-cat">
                                        <span>{t("label_videos")}</span> <span>{("label_temples_list")}</span>
                                    </div>
                                </div>
                            </Link>
                            <Link to='/articles'>
                                <div class="tiles-design-temple-cat animate__animated animate__flipInX">
                                    <div class="title-img-bg">
                                        <img class="card-img-temple-cat" alt="temples" src={articles} />
                                    </div>
                                    <div class="tile-text-temple-cat">
                                        <span>{t("label_articles")}</span> <span>{("label_temples_list")}</span>
                                    </div>
                                </div>
                            </Link>
                            <Link to='/audios'>
                                <div class="tiles-design-temple-cat animate__animated animate__flipInX">
                                    <div class="title-img-bg">
                                        <img class="card-img-temple-cat" alt="temples" src={audios} />
                                    </div>
                                    <div class="tile-text-temple-cat">
                                        <span>{t("label_audios")}</span> <span>{("label_temples_list")}</span>
                                    </div>
                                </div>
                            </Link>
                            <Link to='/mantras'>
                                <div class="tiles-design-temple-cat animate__animated animate__flipInX">
                                    <div class="title-img-bg">
                                        <img class="card-img-temple-cat" alt="temples" src={mantras} />
                                    </div>
                                    <div class="tile-text-temple-cat">
                                        <span>{t("label_mantras")}</span> <span>{("label_temples_list")}</span>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>

                    <Footer />
                </div>
            </div>
        </>
    )

}

export default Library;