// ContactUs.jsx
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Form, Input, Button, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import CustomToastContainer from '../../../common/CustomToastContainer';
import Footer from './footer';
import { useDispatch } from 'react-redux';
import { postPriestReq } from '../../../../redux/actions/acions';
import showToast from '../../../../utils/showToast';



const { TextArea } = Input;

const ContactUs = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const navigate = useNavigate();

  const success = () => {
    showToast('success', t(`label_contactus_success`))
    form.resetFields()
};

const failure = (msg) => {
    showToast('error', msg)
};

  const onFinish = () => {
    form
        .validateFields()
        .then(() => {
            const values = form.getFieldsValue();
            const data = {
                "pid": values.name,
                "ctgry": values.email,
                "reqtype": values.subject,
                "query": values.message
            }
            dispatch(postPriestReq(data, success, failure))
        })
        .catch((errorInfo) => {
            console.error('Validation failed:', errorInfo);
        });
};

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div className='mainsec'>
      
      <div className='f2 font-weight-bold mb-3 mt-3 container title-head-txt'>
        <Link style={{ color: 'black' }} to='/'>
          <span className="bc-active-link">{t('Home')}</span>
        </Link> -
        {/* &nbsp; <Link style={{ color: 'black' }} onClick={handleGoBack}><span className="bc-active-link">{t('label_contact_us')}</span></Link> - */}
        &nbsp; <span className="bc-link">{t("label_contact_us")}</span>
      </div>
      <div className="bg-details-highlight">
        <div className="details-headertxt container">
          <div className="header_txt">{t("label_contact_us")}</div>
        </div>
      </div>
      <div class="mainsec-block-addtemple-detailspage">
      <div className='container'>
        <div className='horoscope-item'>
          <div className="container">
            <h5 className="card-text-title">{t('label_get_in_touch')}</h5>
          </div>
         
          <div className='container'>
          <Form
            form={form}
            name="contact_us"
            onFinish={onFinish}
            layout="vertical"
            className="active-details"
          >
            <div className="tiles-designs">
              <Form.Item
                label={<Typography.Title level={4}>{t("label_name")}</Typography.Title>}
                name="name"
                rules={[
                  {
                    required: true,
                    message: t('label_enter_name'),
                  },
                ]}
              >
                <Input placeholder={t("label_your_name")} />
              </Form.Item>
            </div>
            <div className="tiles-designs">
              <Form.Item
                label={<Typography.Title level={4}>{t("label_email")}</Typography.Title>}
                name="email"
                rules={[
                  {
                    required: true,
                    type: 'email',
                    message: t('label_enter_email'),
                  },
                ]}
              >
                <Input placeholder={t("label_your_email")} />
              </Form.Item>
            </div>
            <div className="tiles-designs">
              <Form.Item
                label={<Typography.Title level={4}>{t("Subject")}</Typography.Title>}
                name="subject"
                rules={[
                  {
                    required: true,
                    message: t('label_enter_subject'),
                  },
                ]}
              >
                <Input placeholder={t("label_your_subject")} />
              </Form.Item>
            </div>
            <div className="tiles-designs">
              <Form.Item
                label={<Typography.Title level={4}>{t("Message")}</Typography.Title>}
                name="message"
                rules={[
                  {
                    required: true,
                    message: t('label_enter_msg'),
                  },
                ]}
              >
                <TextArea rows={4} placeholder={t("label_your_msg")} />
              </Form.Item>
            </div>
            <div className="tiles-designs">
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  {t("label_submit")}
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
        </div>
      </div>
      </div>
      <Footer />
    </div>
  );
};

export default ContactUs;
