import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';

const Shakthi = () => {
    const NextArrow = (props) => {
        const { onClick } = props;
        return (
          <div className="slick-arrow next-arrow" onClick={onClick}>
          <CaretRightOutlined />
          </div>
        );
      };
      
      const PrevArrow = (props) => {
        const { onClick } = props;
        return (
          <div className="slick-arrow prev-arrow" onClick={onClick}>
          <CaretLeftOutlined />
          </div>
        );
      };

    const settings = {
        slidesToShow: 6,
        slidesToScroll: 5,
       // centerMode: true,
        infinite: true,
        nextArrow: <NextArrow />, 
        prevArrow: <PrevArrow />,
        breakpoint: 1024,
    };

    return (
        <div className="slider-container" >
            <Slider {...settings}>
                <div class="card-bg">
                    <h6 class="text-shadow text-uppercase card-design-text">Shakti Peethas
                        <span class="card-design-text2">18<span class="card-design-text3">Temples</span></span></h6>
                    <div class="card-design durga-pic"></div>
                    <div class="onhover-text"><span>Show Temples <i class="fa-solid fa-circle-play"></i></span><aside>
                        ert erw treww trew t rewt rewt rew t ret ret</aside>
                    </div>

                </div>
                <div class="card-bg">
                    <h6 class="text-shadow text-uppercase card-design-text">Jyotirlinga
                        <span class="card-design-text2">12<span class="card-design-text3">Temples</span></span></h6>
                    <div class="card-design linga-pic"></div>
                    <div class="onhover-text"><span>Show Temples <i class="fa-solid fa-circle-play"></i></span><aside>
                        ert erw treww trew t rewt rewt rew t ret ret</aside>
                    </div>
                </div>
                <div class="card-bg">
                    <h6 class="text-shadow text-uppercase card-design-text">Pancharamalu
                        <span class="card-design-text2">5<span class="card-design-text3">Temples</span></span></h6>
                    <div class="card-design shiva1"></div>
                    <div class="onhover-text"><span>Show Temples <i class="fa-solid fa-circle-play"></i></span><aside>
                        ert erw treww trew t rewt rewt rew t ret ret</aside>
                    </div>
                </div>

                <div class="card-bg">
                    <h6 class="text-shadow text-uppercase card-design-text">Pancha Bhootam Temples
                        <span class="card-design-text2">5<span class="card-design-text3">Temples</span></span></h6>
                    <div class="card-design shiva2"></div>
                    <div class="onhover-text"><span>Show Temples <i class="fa-solid fa-circle-play"></i></span><aside>
                        ert erw treww trew t rewt rewt rew t ret ret</aside>
                    </div>
                </div>

                <div class="card-bg">
                    <h6 class="text-shadow text-uppercase card-design-text">Divya Desam
                        <span class="card-design-text2">108<span class="card-design-text3">Temples</span></span></h6>
                    <div class="card-design vishnu"></div>
                    <div class="onhover-text"><span>Show Temples <i class="fa-solid fa-circle-play"></i></span><aside>
                        ert erw treww trew t rewt rewt rew t ret ret</aside>
                    </div>
                </div>

                <div class="card-bg">
                    <h6 class="text-shadow text-uppercase card-design-text">Nava Narasimha Kshetralu
                        <span class="card-design-text2">9<span class="card-design-text3">Temples</span></span></h6>
                    <div class="card-design narashimha"></div>
                    <div class="onhover-text"><span>Show Temples <i class="fa-solid fa-circle-play"></i></span><aside>
                        ert erw treww trew t rewt rewt rew t ret ret</aside>
                    </div>
                </div>

                <div class="card-bg">
                    <h6 class="text-shadow text-uppercase card-design-text">Char Dham
                        <span class="card-design-text2">4<span class="card-design-text3">Temples</span></span></h6>
                    <div class="card-design cd1"></div>
                    <div class="onhover-text"><span>Show Temples <i class="fa-solid fa-circle-play"></i></span><aside>
                        ert erw treww trew t rewt rewt rew t ret ret</aside>
                    </div>
                </div>

                <div class="card-bg">
                    <h6 class="text-shadow text-uppercase card-design-text">chota char dham
                        <span class="card-design-text2">4<span class="card-design-text3">Temples</span></span></h6>
                    <div class="card-design cd1"></div>
                    <div class="onhover-text"><span>Show Temples <i class="fa-solid fa-circle-play"></i></span><aside>
                        ert erw treww trew t rewt rewt rew t ret ret</aside>
                    </div>
                </div>

                <div class="card-bg">
                    <h6 class="text-shadow text-uppercase card-design-text">Arupadai Veedu
                        <span class="card-design-text2">6<span class="card-design-text3">Temples</span></span></h6>
                    <div class="card-design murugan"></div>
                    <div class="onhover-text"><span>Show Temples <i class="fa-solid fa-circle-play"></i></span><aside>
                        ert erw treww trew t rewt rewt rew t ret ret</aside>
                    </div>
                </div>

                <div class="card-bg">
                    <h6 class="text-shadow text-uppercase card-design-text">panch kedar
                        <span class="card-design-text2">5<span class="card-design-text3">Temples</span></span></h6>
                    <div class="card-design shiva4"></div>
                    <div class="onhover-text"><span>Show Temples <i class="fa-solid fa-circle-play"></i></span><aside>
                        ert erw treww trew t rewt rewt rew t ret ret</aside>
                    </div>
                </div>

                <div class="card-bg">
                    <h6 class="text-shadow text-uppercase card-design-text">Trilinga Kshetras
                        <span class="card-design-text2">3<span class="card-design-text3">Temples</span></span></h6>
                    <div class="card-design shiva3"></div>
                    <div class="onhover-text"><span>Show Temples <i class="fa-solid fa-circle-play"></i></span><aside>
                        ert erw treww trew t rewt rewt rew t ret ret</aside>
                    </div>
                </div>


                {/* <div class="card card-poster gradient-overlay hover-animate mb-4 mb-lg-0"><a class="tile-link" href="category.html"></a>


  </div> */}

            </Slider>
        </div>

    )
}


export default Shakthi;