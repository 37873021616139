import React, { useEffect, useState, useRef } from "react";
import { GoogleMap, InfoWindow, MarkerF, LoadScript, Autocomplete } from "@react-google-maps/api";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import logo from '../../../../assets/images/img.png';
import { Link, useNavigate } from "react-router-dom";

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function TempleMap({ tname, city, state, country, latit, longi, mapContainerStyle, enablePointerMove }) {
  const { t } = useTranslation();
  const [center, setCenter] = useState({ lat: latit, lng: longi });
  const [markers, setMarkers] = useState([]);
  const [autocomplete, setAutocomplete] = useState(null);

  const mapRef = useRef(null);

  useEffect(() => {
    if (typeof latit === 'number' && typeof longi === 'number') {
      const initialMarker = {
        id: 100,
        name: tname,
        position: { lat: latit, lng: longi }
      };
      setMarkers([initialMarker]);
      setCenter({ lat: latit, lng: longi });
    }
  }, [latit, longi, tname]);

  const apiKey = 'AIzaSyBLWiZul9MyYq8DwaGhosrNWP7QJJT9krU';

  const [activeMarker, setActiveMarker] = useState(null);

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  const handleMarkerDragEnd = (markerId, position) => {
    if (!enablePointerMove) return;
    const updatedMarkers = markers.map((marker) => {
      if (marker.id === markerId) {
        return {
          ...marker,
          position: {
            lat: position.lat(),
            lng: position.lng()
          }
        };
      }
      return marker;
    });
    setMarkers(updatedMarkers);
  };

  const handleOnLoad = (map) => {
    map.setCenter({ lat: latit, lng: longi });
    map.setZoom(15); // Adjust zoom level to focus on the marker
    mapRef.current = map;
  };

  const handlePlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      if (place.geometry) {
        const location = place.geometry.location;
        setCenter({ lat: location.lat(), lng: location.lng() });
        setMarkers([
          {
            id: 101,
            name: place.name,
            position: { lat: location.lat(), lng: location.lng() }
          }
        ]);
      }
    } else {
      console.warn('Autocomplete is not loaded yet!');
    }
  };

  const onLoadAutocomplete = (autocompleteInstance) => {
    setAutocomplete(autocompleteInstance);
  };

  useEffect(() => {
    i18next.changeLanguage(localStorage.getItem('i18nextLng'));
  }, []);

  const navigate = useNavigate();

  const handleDirectionsClick = (lat, lng) => {
    navigate(`/directions?lat=${lat}&lng=${lng}`);
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const isMobile = window.innerWidth <= 768;

  return (
    <div className="main">

      {/* <Autocomplete onLoad={onLoadAutocomplete} onPlaceChanged={handlePlaceChanged}>
        <input
          type="text"
          placeholder="Search for locations..."
          style={{
            boxSizing: `border-box`,
            border: `1px solid transparent`,
            width: `240px`,
            height: `25px`,
            padding: `0 12px`,
            borderRadius: `3px`,
            boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
            fontSize: `14px`,
            outline: `none`,
            textOverflow: `ellipses`,
            position: "absolute",
            left: "50%",
            top: "10px",
            transform: "translateX(-50%)",
            zIndex: "10",
          }}
        />
      </Autocomplete> */}
      <GoogleMap
        center={center}
        zoom={15}
        onLoad={handleOnLoad}
        onClick={() => setActiveMarker(null)}
        mapContainerStyle={mapContainerStyle || { width: '100%', height: isMobile ? '90vh' : '60vh' }}
      >
        {markers.map(({ id, name, position }) => (
          <MarkerF
            key={id}
            position={position}
            onClick={() => handleActiveMarker(id)}
            icon={{
              url: logo,
              scaledSize: new window.google.maps.Size(40, 40),
            }}
            draggable={enablePointerMove}
            onDragEnd={(e) => handleMarkerDragEnd(id, e.latLng)}
          >
            {activeMarker === id && position && name ? (
              <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                <div>
                  <h3>{name}</h3>
                  <h5>City: {capitalizeFirstLetter(city)}</h5>
                  <h5>State: {capitalizeFirstLetter(state)}</h5>
                  <h5>{country}</h5>
                  <a
                    style={{ fontWeight: 'bold' }}
                    href={`https://www.google.com/maps?q=${position.lat},${position.lng}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                   {t('label_get_directions')}
                  </a>
                </div>
              </InfoWindow>
            ) : null}
          </MarkerF>
        ))}
      </GoogleMap>
    </div>
  );
}

export default TempleMap;
