import { useDispatch, useSelector } from 'react-redux';
import { Form, Select, Typography, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { editCity, fetchState } from '../../../../redux/actions/acions';
import { getStateError, getStateList, getStateLoading } from '../../../../redux/selectors/selectors';
import statesData from '../../states.json';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import '../Profile/profile.css'
import showToast from '../../../../utils/showToast';

const ProfileLocation = ({ editable }) => {
  const states = statesData.states.map((state) => state.name);
  const [cities, setCities] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const [form] = Form.useForm();

  const capitalizeFirstLetter = (string) => {
    return string
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
};

  useEffect(() => {
    if (sDetails && sDetails.st) {
      form.setFieldsValue({
        st: sDetails.st,
        city: sDetails.city,
      });
    }
  }, [sDetails, form]);

  const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  const [messageApi, contextHolder] = message.useMessage();

  const success = () => {
    showToast('success',t('label_update_state_success'))
    form.setFieldsValue({
      st: capitalize(form.getFieldValue('st')),
      city: capitalize(form.getFieldValue('city')),
    });
  };

  const failure = (msg) => {
    showToast('failure', msg)
  };

  const dispatch = useDispatch();
  const { sDetails, sLoading, sError } = useSelector(state => ({
    sDetails: getStateList(state),
    sLoading: getStateLoading(state),
    sError: getStateError(state)
  }));

  useEffect(() => {
    dispatch(fetchState());
  }, [dispatch]);

  const handleSaveLocation = (values) => {
    dispatch(editCity({ city: values.city, st: values.st }, success, failure));
  };

  useEffect(() => {
    if (sDetails && sDetails.st) {
      const initialState = statesData.states.find(
        (state) => capitalizeFirstLetters(state.name) === capitalizeFirstLetters(sDetails.st)
      );

      setSelectedState(initialState);
      setCities(initialState ? initialState.cities.map(capitalizeFirstLetters) : []);

     
        form.setFieldsValue({
          st: capitalizeFirstLetters(sDetails.st),
          city: capitalizeFirstLetters(sDetails.city) || (initialState ? capitalizeFirstLetters(initialState.cities[0]) : undefined),
        });
      
    }
  }, [sDetails, statesData]);


  const handleStateChange = (value) => {
    const newState = statesData.states.find((state) => state.name === value);
    setSelectedState(newState);
    setCities(newState ? newState.cities.map(capitalizeFirstLetters) : []);
    form.setFieldsValue({  city: newState && newState.cities ? capitalizeFirstLetters(newState.cities[0]) : undefined });
  };


  const { t } = useTranslation();
  useEffect(() => {
    i18next.changeLanguage(localStorage.getItem('i18nextLng'));
  }, []);

  const handleCitySearch = (value) => {
    const cities = selectedState ? selectedState.cities : [];
    const filteredCities = cities.filter((cityName) =>
      cityName.toLowerCase().includes(value.toLowerCase())
    );
    setCities(filteredCities);
  };

  const capitalizeFirstLetters = (string) => {
    return string
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  return (
    <div>   
      <Form
        form={form}
        name="nest-messages"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 8 }}
        onFinish={handleSaveLocation}
      >
        {/* <div className="float-left w-100 mt-4">

          <h5 className="card-text-title">
            {t("label_location")} <span>| {t('label_st_city')}</span>
          </h5>
        </div> */}

        <div class="float-left w-100" >
          <h5 class="card-text-title2"> {t("label_location")} 
        {/* <span> | {t('label_new_edit_pwd')}</span> */}
        </h5></div>
        <div className="active-details">
          <div className="tiles-designs">
            <Form.Item
              style={{ marginTop: '5px' }}
              label={<Typography.Title level={4}>{t("label_state")}</Typography.Title>}
              name="st"
              rules={[{ required: editable, message: 'Please select your state' }]}
            >
              {editable ? (
                <Select
                  showSearch
                  onChange={handleStateChange}
                  onSearch={handleCitySearch}
                  value={selectedState ? capitalizeFirstLetters(selectedState.name) : undefined}
                >
                  {states.map((state) => (
                    <Select.Option key={state} value={state}>
                     {capitalizeFirstLetters(state)}
                    </Select.Option>
                  ))}
                </Select>
              ) : (
                <Typography.Text level={5} className='typ'>
                  {sDetails && sDetails.st ? t(sDetails.st) : t("label_notchosen")}
                </Typography.Text>
              )}
            </Form.Item>
          </div>

          <div className="tiles-designs">
            <Form.Item
              label={<Typography.Title level={4}>{t("label_city")}</Typography.Title>}
              name="city"
              rules={[{ required: editable, message: 'Please enter your city' }]}
            >
              {editable ? (
                <Select
                  showSearch
                  value={form.getFieldValue("city")}
                  onChange={value => form.setFieldsValue({ city: value })}
                  onSearch={handleCitySearch}
                  
                >
                  {cities.map((city) => (
                    <Select.Option key={city} value={city}>
                     {capitalizeFirstLetters(city)}
                    </Select.Option>
                  ))}
                </Select>
              ) : (
                <Typography.Text level={5} className='typ'>
                  {sDetails && sDetails.city ? capitalize(sDetails.city) : t("label_notchosen")}
                </Typography.Text>
              )}
            </Form.Item>
          </div>
          <div className='tiles-designs tiles-designs-no-bg'>
            {editable ? (

              <Form.Item
                className="empty-label"
                label={<Typography.Title level={4} ></Typography.Title>}
              >
                <button className="button_move" type="primary" htmlType="submit">
                  {t("label_update_location")}
                </button>
              </Form.Item>

            ) : null}
          </div>
        </div>
      </Form>
    </div>
  );
};

export default ProfileLocation;
