import React, { useEffect, useRef, useState } from 'react';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import Typography from 'antd/es/typography/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Upload, Card, Col, DatePicker, Form, Image, Input, Popconfirm, Row, Select, Skeleton, Checkbox, message } from 'antd';
import { getDailyHoroscopeError, getDailyHoroscopeList, getDailyHoroscopeLoading, getDonationDetails, getDonationDetailsError, getDonationDetailsLoading, getNotificationDetailsError, getNotificationDetailsList, getNotificationDetailsLoading, getRegionalNotifiError, getRegionalNotifiList, getRegionalNotifiLoading } from '../../redux/selectors/selectors';
import { deleteDonation, fetchDailyHoroscope, fetchDonationsDetails, fetchNotificationDetails, fetchRegionalNotifi, postLibraryUploadImageRequest, updateDonationAmount, updateDonationBill, updateDonationNotes, updateDonationPayment, updatedonationPaymentDetails, updatedonationType, updatedonorDetails } from '../../redux/actions/acions';
import deftemples from '../../assets/images/templeicon.jpg'
import { useTranslation } from 'react-i18next';
import { CloseCircleOutlined, DownOutlined, UploadOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';
import ReactCrop from 'react-image-crop';
import { getImageAPI, getCoreImagesAPI } from "../../http-common";
import Error from '../pages/Homepage/error';
import { EditOutlined } from '@ant-design/icons';
import moment from 'moment';
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.css';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Footer from '../pages/Homepage/footer/footer';
import showToast from '../../utils/showToast';
import CustomToastContainer from '../../components/common/CustomToastContainer';
import Donations from './donations';

const DonationDetails = () => {
    const { t } = useTranslation();
    const storedLanguage = localStorage.getItem('lng') || 5;
    const { donid } = useParams()
    const dispatch = useDispatch();
    const token = Boolean(localStorage.getItem('token'));
    const [messageApi, contextHolder] = message.useMessage();
    const navigate = useNavigate();
    const [amount, setAmount] = useState(null);
    const [dontype, setDontype] = useState(null);
    const [notes, setNotes] = useState(null);
    const [bill, setBill] = useState(null);
    const [payment, setPayment] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);
    const [donations, setDonations] = useState('donations');


    const success = () => {
        showToast('success', t('label_donation_delete_success'));
        form.resetFields()
    };

    const failure = (msg) => {
        showToast('failure', msg);
    };

    const deleteSuccess = () => {
        showToast('deleteSuccess', t('label_donation_delete_success'));
        // messageApi.open({
        //     type: 'success',
        //     content: 'DELETED SUCCESSFULLY',
        // });
        navigate(-1);
    };

    const { rList, rLoading, rError } = useSelector(state => ({
        rList: getDonationDetails(state),
        rLoading: getDonationDetailsLoading(state),
        rError: getDonationDetailsError(state),
    }));

    const tname = localStorage.getItem('tname')

    useEffect(() => {
        dispatch(fetchDonationsDetails(donid));
    }, [dispatch, donid]);

    const handleGoBack = () => {
        navigate(-1);
    };

    const [formData, setFormData] = useState({
        // fullname: sDetails.fullname || '',
        // gender: sDetails.gender || '',
        // lang: sDetails.lang || '',
    });

    const [selectedDate, setSelectedDate] = useState(rList ? moment(rList.dondt) : null);

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const [editable, setEditable] = useState(false);
    const [buttonText, setButtonText] = useState(t("edit"));
    const [form] = Form.useForm();

    const handleCancelClick = () => {
        // Reset the form to its initial values
        // form.resetFields();
        setButtonText('Edit');
        setEditable(false);
    };

    const handleEditClick = () => {
        if (!editable) {
            setButtonText(t("label_cancel"));
            // Populate the input field with the current value when entering edit mode
            setFormData({ ...formData, amount: rList && rList.amount });
            setFormData({ ...formData, otherdeities: rList && rList.otherdeities });
        } else {
            setButtonText('Edit');
        }
        setEditable(!editable);
    };

    const handleSelect = (value) => {
        dispatch(updatedonationType(donid, { dontype: value }, success, failure));
    };
    const amountSuccess = () => {
        showToast('success', t('label_donation_amount_success'));
        form.resetFields()
    };

    const handleAmount = (e) => {
        setAmount(e.target.value)
        //dispatch(updateDonationAmount(donid, { amount: `${e.target.value}` }));
    };

    const updateAmount = () => {
        dispatch(updateDonationAmount(donid, { amount: amount }, amountSuccess, failure));
    };

    const notesSuccess = () => {
        showToast('success', t('label_donation_notes_success'));
        form.resetFields()
    };

    const handleNotes = (e) => {
        setNotes(e.target.value)
    };

    const updateNotes = () => {
        dispatch(updateDonationNotes(donid, { notes: notes }, notesSuccess, failure));
    };

    const billSuccess = () => {
        showToast('success', t('label_donation_bill_success'));
        form.resetFields()
    };

    const handleBill = (e) => {
        setBill(e.target.value)
    };

    const updateBill = () => {
        dispatch(updateDonationBill(donid, { bill: bill }, billSuccess, failure));
    };

    const paymentSuccess = () => {
        showToast('success', t('label_donation_payment_success'));
        form.resetFields()
    };

    const handlePayment = (value) => {
        dispatch(updateDonationPayment(donid, { paymode: `${value}` }, paymentSuccess, failure));
    };


    function deleteDon(did, ts) {
        dispatch(deleteDonation(did, ts, deleteSuccess, failure));
    }

    const donorSuccess = () => {
        showToast('success', t('label_donation_donor_success'));
        form.resetFields()
    };

    const handleSave = () => {
        const values = form.getFieldsValue();
        const dataToSend = {
            donor: values.donor || rList.donor,
            addr: values.addr || rList.addr,
            cityst: values.cityst || rList.cityst,
        };
        dispatch(updatedonorDetails(donid, dataToSend, donorSuccess, failure));
    };

    const donationPaymentSuccess = () => {
        showToast('success', t('label_donation_payment_success'));
        form.resetFields()
    };

    const handleSavePayment = () => {
        const values = form.getFieldsValue();
        const dataToSend = {
            paymode: values.paymode || rList.paymode,
            bank: values.bank || rList.bank,
            trxid: values.trxid || rList.trxid,
        };
        dispatch(updatedonationPaymentDetails(donid, dataToSend, donationPaymentSuccess, failure));
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);
        return `${year}-${month}-${day}`;
    };

    const downloadAsPDF = (donationDetails) => {
        const doc = new jsPDF();
        doc.setFontSize(18);
        doc.text('Donation Details', 14, 15);

        const rows = [
            { label: t('label_temple_name'), value: donationDetails.tname },
            { label: t('label_donor_name'), value: donationDetails.donor },
            { label: t('label_date'), value: formatDate(donationDetails.dondt) },
            { label: t('label_amount'), value: donationDetails.amount },
            { label: t('label_notes'), value: donationDetails.notes },
            { label: t('label_bank'), value: donationDetails.bank },
        ];

        const formattedRows = rows.map(item => [item.label, item.value]);

        doc.autoTable({
            startY: 20,
            body: formattedRows,
            theme: 'grid',
        });

        doc.save('Donation_Details.pdf');
    };

    const columns = [
        {
            title: (
                <span style={{ fontSize: '18px', fontWeight: 'bold', color: '#007bff' }}>
                    {t("Download")}
                </span>
            ),
            dataIndex: "download",
            key: "download",
            render: (text, record) => (
                <i className="fa-solid fa-download fa-lg" style={{ cursor: 'pointer' }} onClick={() => downloadAsPDF(record)}></i>
            ),
        },
    ];


    const [selectedImage, setSelectedImage] = useState(null);
    const [crop, setCrop] = useState({ unit: '%', width: 30, aspect: 16 / 9 });

    const normFile = (e) => {
        console.warn('Upload event:', e);
        if (Array.isArray(e)) {
            return e;
        }
        return e.fileList;
    };

    const onImageLoaded = (image) => {
        console.warn(image);
    };

    const onCropChange = (crop) => {
        setCrop(crop);
    };

    const onCropComplete = (crop, pixelCrop) => {
        console.warn(crop, pixelCrop);
    };

    const onFileChange = (info) => {
        if (info.file.status === 'done') {
            // File has been uploaded successfully
            setSelectedImage(info.file.response.path); // Update state with the uploaded image path
        }
    };

    const [newImagePath, setNewImagePath] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);

    const [cropper, setCropper] = useState(null);
    const imageRef = useRef();


    const isMobileDevice = () => {
        return /Mobi|Android/i.test(navigator.userAgent);
    };

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        setIsMobile(isMobileDevice());
    }, []);

    const handleCameraClick = () => {
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = 'image/*';
        fileInput.capture = 'environment'; // Use 'user' for front camera and 'environment' for back camera

        fileInput.addEventListener('change', (event) => {
            const selectedFile = event.target.files[0];

            if (selectedFile) {
                const formData = new FormData();
                formData.append('file', selectedFile);

                dispatch(postLibraryUploadImageRequest(donations, donid, formData, () => {
                    const uploadedImagePath = URL.createObjectURL(selectedFile);
                    setNewImagePath(uploadedImagePath);
                    console.warn('Image uploaded successfully');
                }, (errorMsg) => {
                    console.error('Image upload failed:', errorMsg);
                }));
            } else {
                console.warn('No file selected');
            }

            document.body.removeChild(fileInput); // Clean up the file input
        });


        document.body.appendChild(fileInput);
        fileInput.click();
    };

    const handleImageCloseModal = () => {
        setIsModalVisible(false);
        if (cropper) {
            cropper.destroy(); // Destroy cropper instance when modal is closed
        }
    };

    const handleImageLinkClick = () => {
        showToast('error', t(`Please login to upload image.`))
    };

    const handleGalleryClick = () => {
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = 'image/*,application/pdf'; // Accept both images and PDFs
        fileInput.addEventListener('change', (event) => {
            const selectedFile = event.target.files[0];
            if (selectedFile) {
                const formData = new FormData();
                formData.append('file', selectedFile); // Append the selected file (image or PDF)
                // Check the file type for custom handling (optional)
                const fileType = selectedFile.type;
                if (fileType.startsWith('image/')) {
                    console.log('Image selected:', selectedFile.name);
                } else if (fileType === 'application/pdf') {
                    console.log('PDF selected:', selectedFile.name);
                }
                // Upload the file (either image or PDF)
                dispatch(postLibraryUploadImageRequest(donations, donid, formData, () => {
                    console.log('File uploaded successfully');
                    handleImageCloseModal(); // Close modal if applicable
                }, (errorMsg) => {
                    console.error('File upload failed:', errorMsg);
                }));
            }
        });
        fileInput.click(); // Open the file selection dialog
    };

    const downloadPDF = () => {
        const fileUrl = getCoreImagesAPI() + rList.bill; // Dynamically use the path from rList
        fetch(fileUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/pdf',
            },
        })
            .then((response) => response.arrayBuffer())  // Use arrayBuffer instead of blob
            .then((buffer) => {
                const blob = new Blob([buffer], { type: 'application/pdf' });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Expense_Details.pdf'); // File name for the download
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch((error) => {
                console.error('Error downloading the file:', error);
            });
    };

    const handleSaveClick = () => {
        const values = form.getFieldsValue();
        const donorDataToSend = {
            donor: values.donor || rList.donor,
            addr: values.addr || rList.addr,
            cityst: values.cityst || rList.cityst,
        };
        const amountDataToSend = {
            amount: values.amount || rList.amount,
        };
        const paymentDataToSend = {
            paymode: values.paymode || rList.paymode,
            bank: values.bank || rList.bank,
            trxid: values.trxid || rList.trxid,
        };

        // Dispatch actions to update donor details, donation amount, and payment details
        dispatch(updatedonorDetails(donid, donorDataToSend, donorSuccess, failure));
        dispatch(updateDonationAmount(donid, amountDataToSend, amountSuccess, failure));
        dispatch(updatedonationPaymentDetails(donid, paymentDataToSend, donationPaymentSuccess, failure));

        setEditable(false);
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        handleResize(); // Check initial screen size
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className='mainsec'>
            <div class=" mob-nav fluid-container page-title justify-content-between">
                <div class="d-flex align-items-center left-sec-mob-nav">
                    <Link onClick={handleGoBack} ><i class="fa-solid fa-angle-left"></i></Link>
                    <span class="bc-link">{t('label_donation_details')}</span>
                </div>
                <div></div>
            </div>
            {contextHolder}

            <div className='f2 font-weight-bold mb-3 mt-3 container title-head-txt breadcrumb-link breadcrumb-link' >
                <Link style={{ color: 'black' }} to='/'>
                    <span className="bc-active-link">{t('Home')}</span>
                </Link> - <span className="bc-active-link" onClick={handleGoBack}>{t('label_donations')}</span> - <span className="bc-link">{t("label_donation_details")}</span>
            </div>
            <div class="bg-details-highlight">
                <div class=" details-headertxt-singleLine container">
                    <div class="header_txt">{rList && rList.donor} donated {rList && rList.amount}Rs
                    </div>
                    <div class="details-headertxt-singleLine-btns">
                        {editable ? (
                            <>
                                <a

                                    className="button_move mr-2"
                                    onClick={handleSaveClick}

                                >
                                    {t("label_save")}
                                </a>
                                <a

                                    className="button_move  mr-2"
                                    onClick={handleCancelClick}

                                >
                                    {t("label_cancel")}
                                </a>
                            </>
                        ) : (
                            <a

                                className="button_move mr-2"
                                onClick={handleEditClick}
                            >
                                {buttonText}
                            </a>
                        )}


                        <a className="button_move mr-2" onClick={() => downloadAsPDF(rList)} style={{ marginRight: '10px' }}>
                            {t("label_print")}
                        </a>
                        <Button className="button_move" type="primary" >
                            <Popconfirm
                                title="Are you sure you want to delete?"
                                onConfirm={() => deleteDon(rList.donid, rList.ludt)}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Link>Delete</Link>
                            </Popconfirm>
                        </Button>
                    </div>
                </div>
            </div>
            <div className="mainsec-block-addtemple-detailspage">
                <div className='container'>
                    <Form
                        form={form}
                        name='basic'
                        labelCol={{ span: 5 }}
                        wrapperCol={{ span: 10 }}
                    //onFinish={handleSubmit}
                    >

                        <div className='horoscope-item' >
                            <div class="float-left w-100 " ><h5 class="card-text-title">{t('label_donation_details')} <span>| {t('label_contains_id_type_date')}</span></h5></div>
                            {/* <h2>{rList.author}</h2> */}
                            <div class="active-details">

                                <div className="tiles-designs">
                                    <Form.Item label={<Typography.Title level={4}>{t("label_donor")}</Typography.Title>} name="donor">
                                        {editable ? (
                                            <Input
                                                style={{ height: '30px' }}
                                                defaultValue={rList && rList.donor}
                                            />
                                        ) : (
                                            <Typography.Text level={5} className="typ">{rList && rList.donor}</Typography.Text>
                                        )}
                                    </Form.Item>
                                </div>
                                <div class="tiles-designs">
                                    <Form.Item
                                        label={<Typography.Title level={4}>{t("label_temple_name")}</Typography.Title>}
                                        name="Temple Name"
                                    >
                                        <Typography.Text level={5} className="typ">{tname}</Typography.Text>
                                    </Form.Item>
                                </div>
                                <div className="tiles-designs">
                                    <Form.Item label={<Typography.Title level={4}>{t("label_addr")}</Typography.Title>} name="addr">
                                        {editable ? (
                                            <Input.TextArea
                                                defaultValue={rList && rList.addr}
                                            />
                                        ) : (
                                            <Typography.Text level={5} className="typ">{rList && rList.addr}</Typography.Text>
                                        )}
                                    </Form.Item>
                                </div>
                                <div className="tiles-designs">
                                    <Form.Item label={<Typography.Title level={4}>{t("label_city_state")}</Typography.Title>} name="cityst">
                                        {editable ? (
                                            <Input
                                                style={{ height: '30px' }}
                                                defaultValue={rList && rList.cityst}
                                            />
                                        ) : (
                                            <Typography.Text level={5} className="typ">{rList && rList.cityst}</Typography.Text>
                                        )}
                                    </Form.Item>
                                </div>

                                {/* <div class="tiles-designs" >
                                {editable ? (
                                    <div >
                                        <Form.Item >
                                            <Button class="button_move" htmlType='submit' type="primary" onClick={handleSave} >
                                                {t("Update_donor_details")}
                                            </Button>
                                        </Form.Item>
                                    </div>
                                ) : (null
                                )}
                            </div> */}
                                <div className="tiles-designs">
                                    <Form.Item
                                        label={<Typography.Title level={4}>{t("label_donation_date")}</Typography.Title>}
                                        name="dondt"
                                    >

                                        <Typography.Text level={5} className='typ'> {rList && formatDate(rList.dondt)}</Typography.Text>

                                    </Form.Item>
                                </div>
                                {/* <div className="tiles-designs">
                                <Form.Item
                                    label={<Typography.Title level={4}>{t("label_donation_type")}</Typography.Title>}
                                    name="dontype"
                                >
                                    {editable ? (
                                        <Select onChange={handleSelect} defaultValue={rList && rList.dontype}>
                                            <Select.Option value="">{t('label_pls_choose_option')}</Select.Option>
                                            <Select.Option value="M">{t('label_Maintainance')}</Select.Option>
                                            <Select.Option value="H">{t('label_Hundi')}</Select.Option>
                                            <Select.Option value="A">{t('label_Annadanam')}</Select.Option>
                                            <Select.Option value="D">{t('label_daily_archana')}</Select.Option>
                                            <Select.Option value="R">{t('label_repaire')}</Select.Option>
                                            <Select.Option value="N">{t('label_new_constr')}</Select.Option>
                                        </Select>
                                    ) : (
                                        <Typography.Text level={5} className="typ">
                                            {rList ? (
                                                (() => {
                                                    switch (rList && rList.dontype) {
                                                        case 'M ':
                                                            return t('label_Maintainance');
                                                        case 'H ':
                                                            return t('label_Hundi');
                                                        case 'A ':
                                                            return t('label_Annadanam');
                                                        case 'D ':
                                                            return t('label_daily_archana');
                                                        case 'R ':
                                                            return t('label_repaire');
                                                        case 'N ':
                                                            return t('label_new_constr');
                                                        default:
                                                            return t('label_notchosen');
                                                    }
                                                })()
                                            ) : (
                                                t('label_notchosen')
                                            )}
                                        </Typography.Text>
                                    )}
                                </Form.Item>
                            </div> */}
                                <div className="tiles-designs">
                                    <Form.Item label={<Typography.Title level={4}>{t("label_don_amt")}</Typography.Title>} name="amount">
                                        {editable ? (
                                            <Input
                                                style={{ height: '30px' }}
                                                defaultValue={rList && rList.amount}
                                            />
                                        ) : (
                                            <Typography.Text level={5} className="typ">{rList && rList.amount}</Typography.Text>
                                        )}
                                    </Form.Item>
                                </div>

                                <div className="tiles-designs">
                                    <Form.Item label={<Typography.Title level={4}>{t("label_payment_mode")}</Typography.Title>} name="paymode">
                                        {editable ? (
                                            <Select>
                                                <Select.Option value="">{t('label_pls_choose_option')}</Select.Option>
                                                <Select.Option value="P">{t('Paytm')}</Select.Option>
                                                <Select.Option value="G">{t('GPay')}</Select.Option>
                                                <Select.Option value="N">{t('NetBank')}</Select.Option>
                                                <Select.Option value="C">{t('Cheque')}</Select.Option>
                                            </Select>
                                        ) : (
                                            <Typography.Text level={5} className="typ">
                                                {rList ? (
                                                    (() => {
                                                        switch (rList.paymode) {
                                                            case 'P':
                                                                return t('Paytm');
                                                            case 'G':
                                                                return t('GPay');
                                                            case 'N':
                                                                return t('NetBank');
                                                            case 'C':
                                                                return t('Cheque');
                                                            default:
                                                                return t('label_notchosen');
                                                        }
                                                    })()
                                                ) : (
                                                    t('label_notchosen')
                                                )}
                                            </Typography.Text>
                                        )}
                                    </Form.Item>
                                </div>

                                <div className="tiles-designs">
                                    <Form.Item label={<Typography.Title level={4}>{t("label_bank")}</Typography.Title>} name="bank">
                                        {editable ? (
                                            <Select>
                                                <Select.Option value="">{t('label_pls_choose_option')}</Select.Option>
                                                <Select.Option value="1">{t('ICICI')}</Select.Option>
                                                <Select.Option value="2">{t('HDFC')}</Select.Option>
                                                <Select.Option value="3">{t('SBI')}</Select.Option>
                                            </Select>
                                        ) : (
                                            <Typography.Text level={5} className="typ">
                                                {rList ? (
                                                    (() => {
                                                        switch (rList.bank) {
                                                            case '1':
                                                                return t('ICICI');
                                                            case '2':
                                                                return t('HDFC');
                                                            case '3':
                                                                return t('SBI');
                                                            default:
                                                                return t('label_notchosen');
                                                        }
                                                    })()
                                                ) : (
                                                    t('label_notchosen')
                                                )}
                                            </Typography.Text>
                                        )}
                                    </Form.Item>
                                </div>

                                <div className="tiles-designs">
                                    <Form.Item label={<Typography.Title level={4}>{t("label_trans_id")}</Typography.Title>} name="trxid">
                                        {editable ? (
                                            <Input style={{ height: '30px' }} defaultValue={rList && rList.trxid} />
                                        ) : (
                                            <Typography.Text level={5} className="typ">{rList && rList.trxid}</Typography.Text>
                                        )}
                                    </Form.Item>
                                </div>
                                <div class="tiles-designs" >
                                    <Form.Item
                                        label={<Typography.Title level={4} >{t("label_notes")}</Typography.Title>}
                                        name="notes"
                                    >

                                        {editable ? (
                                            <Input.TextArea
                                                defaultValue={rList && rList.notes}
                                                onChange={handleNotes}
                                            />
                                        ) : (

                                            <Typography.Text level={5} className='typ'>{rList && rList.notes}</Typography.Text>
                                        )}
                                    </Form.Item>
                                </div>
                                <div className="active-details">
                                    <div className="tiles-designs">
                                        {token ? (
                                            <div text="Add Image" >
                                                {previewImage ? (
                                                    <img src={previewImage} alt="Captured Image" />
                                                ) : (
                                                    <>
                                                        {isMobile && (
                                                            <button className="button_move" onClick={handleCameraClick} style={{ marginBottom: '8px' }}>
                                                                {t('label_camera')}
                                                            </button>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                        ) : (
                                            <div text="Add Image" >
                                                {previewImage ? (
                                                    <img src={previewImage} alt="Captured Image" />
                                                ) : (
                                                    <>
                                                        {isMobile && (
                                                            <button className="button_move" onClick={handleImageLinkClick} style={{ marginBottom: '8px' }}>
                                                                {t('label_camera')}
                                                            </button>
                                                        )}
                                                    </>
                                                )}
                                                <button className="button_move" onClick={handleImageLinkClick} style={{ marginBottom: '8px', marginLeft: "5px" }}>{t('label_gallery')}</button>


                                            </div>)}
                                        {/* <div className="detail-container detail-container-image">
                                    <img
                                        className='det-image'
                                        src={rList && rList.img_path ? getImageAPI() + rList.img_path : 'default-image-path.jpg'}
                                        alt="image"
                                    />
                                </div> */}
                                        <div class="popup-bg" style={{ display: isModalVisible ? 'flex' : 'none' }}>
                                            <div class="popup-main animate__animated animate__fadeInUp ">
                                                <div class="popup-main-header">
                                                    <span class="popup-main-header-text">Main header</span>
                                                    <div class="popup-close" onClick={handleImageCloseModal}><i class="fa-solid fa-xmark"></i></div>
                                                    <div class="popup-back" onClick={handleImageCloseModal}><i class="fa-solid fa-chevron-left"></i></div>
                                                </div>
                                                <div class="popup-main-container">
                                                    <div class="d-block htinner-popup-main-container">
                                                        {/* <span className="close" onClick={handleImageCloseModal}>&times;</span> */}
                                                        {selectedImage && <img ref={imageRef} id="imageToCrop" src={selectedImage} alt="To be cropped" />}
                                                    </div>
                                                </div>
                                                <div class="popup-main-footer">
                                                    <button class="button_move">Crop and Upload</button>
                                                </div>
                                            </div>
                                        </div>
                                        {rList && rList.img_path ? (
                                            <div className="tiles-grid">
                                                <Image.PreviewGroup>

                                                    <div >
                                                        <div className="detail-container detail-container-image">
                                                            <Image
                                                                className="card-img"
                                                                src={rList && rList.img_path ? getImageAPI() + rList.img_path : 'default-image-path.jpg'}
                                                                alt="images"
                                                            />
                                                            {editable ? (
                                                                <Popconfirm
                                                                    title="Are you sure you want to delete?"
                                                                    okText="Yes"
                                                                    cancelText="No"
                                                                >
                                                                    <CloseCircleOutlined className="close-icon" />
                                                                </Popconfirm>
                                                            ) : null}
                                                            {editable ? (
                                                                <Popconfirm
                                                                    title="Are you sure you want to update?"
                                                                    okText="Yes"
                                                                    cancelText="No"
                                                                >
                                                                    <Checkbox className="close-icon1" />
                                                                </Popconfirm>
                                                            ) : null}
                                                        </div>
                                                    </div>


                                                    {/* <div className="detail-container detail-container-image">
                                                {newImagePath && <Image className="card-img" src={newImagePath} alt="Selected Image" />}
                                                {newImagePath && editable ? (
                                                    // <CloseCircleOutlined className="close-icon" onClick={onFinish} />
                                                ) : null}
                                            </div> */}
                                                </Image.PreviewGroup>
                                            </div>
                                        ) : (
                                            <div className="detail-container detail-container-image">
                                                <img
                                                    className="card-img"
                                                    alt="temples"
                                                    src={deftemples}
                                                    style={{
                                                        width: '20%',
                                                        height: '20%',
                                                        marginBottom: '2px',
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {/* <div class="tiles-designs" >
                                {editable ? (
                                    // <div >
                                    //     <Form.Item >
                                    //         <Button class="button_move" type="primary" htmlType='submit' onClick={updateNotes} >{t("Update Notes")} </Button>
                                    //     </Form.Item>
                                    // </div>
                                ) : (null
                                )}
                            </div> */}
                                {editable && (
                                    <div className="tiles-designs">
                                        <Form.Item
                                            name="img"
                                            label={<Typography.Title level={4}>{t('label_add_img')}</Typography.Title>}
                                            valuePropName="fileList"
                                            getValueFromEvent={normFile}
                                            extra="use png/jpg/jpeg format only"
                                        >
                                            <div>
                                                <ImgCrop rotationSlider>
                                                    <Upload
                                                        name="logo"
                                                        action="/upload.do"
                                                        listType="picture"
                                                        onChange={onFileChange}
                                                    >
                                                        <button
                                                            type="button"
                                                            className="btn btn-outline-dark"
                                                            style={{ marginBottom: '8px' }} // Adds spacing between buttons
                                                        >
                                                            <i className="fa-solid fa-upload"></i>&nbsp;{t('label_click')}
                                                        </button>
                                                    </Upload>
                                                </ImgCrop>

                                                {/* Conditionally render the Camera Button only on mobile view */}
                                                {isMobile && (
                                                    <Upload
                                                        name="logo"
                                                        action="/upload.do"
                                                        listType="picture"
                                                        onChange={onFileChange}
                                                        showUploadList={false}
                                                        accept="image/*;capture=camera"
                                                    >
                                                        <button type="button" className="btn btn-outline-dark">
                                                            <i className="fa-solid fa-camera"></i>&nbsp;{t('label_camera')}
                                                        </button>
                                                    </Upload>
                                                )}
                                            </div>
                                        </Form.Item>

                                        <ReactCrop
                                            src="https://via.placeholder.com/150"
                                            crop={crop}
                                            onImageLoaded={onImageLoaded}
                                            onChange={onCropChange}
                                            onComplete={onCropComplete}
                                        />
                                    </div>
                                )}
                                <div >
                                    {/* <Form.Item >
                                    <a class="button_move" type="primary"  ><Popconfirm
                                        title="Are you sure you want to delete?"
                                        onConfirm={() => deleteDon(rList.donid, rList.ludt)}
                                        okText="Yes"
                                        cancelText="No"
                                    >
                                        <Link style={{ color: 'black' }}>Delete</Link>
                                    </Popconfirm> </a>
                                </Form.Item> */}
                                </div>
                            </div>
                        </div>


                    </Form>
                </div>


                <Footer />
            </div>
        </div>
    );
};

export default DonationDetails;
