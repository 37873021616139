import React, { useState } from 'react';
import { Modal, Card, Button } from 'antd';
import { getImageAPI } from '../../http-common';
import deftemples from '../../assets/images/templeicon.jpg';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const TemplePreviewModal = ({ tList, isVisible, onClose }) => {
    const { t } = useTranslation();
    const [currentIndex, setCurrentIndex] = useState(0);

    const nextTemple = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % tList.length);
    };

    const prevTemple = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? tList.length - 1 : prevIndex - 1
        );
    };

    const currentTemple = tList && tList[currentIndex];

    const capitalizeFirstLetter = (string) => {
        if (!string) return ''; // Return an empty string if string is null or undefined
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    // Ensure currentTemple is defined before rendering
    if (!currentTemple) {
        return null; // Return null or a loading state if currentTemple is not defined
    }

    return (
        <>
            <div className="popup-bg" style={{ display: isVisible ? 'flex' : 'none' }}>
                <div className="popup-main-filter animate__animated animate__fadeInUp">
                    <div className="popup-main-header">
                        <span className="popup-main-header-text">{currentTemple.tname}</span>
                        <div className="popup-close" onClick={onClose}>
                            <i className="fa-solid fa-xmark"></i>
                        </div>
                        <div className="popup-back" onClick={onClose}>
                            <i className="fa-solid fa-chevron-left"></i>
                        </div>
                    </div>
                    <div className="popup-main-container">
                        <div className="d-block htinner-popup-main-container">
                            <Link
                                className="ignore-styles"
                                to={`/templeDetails/${currentTemple.tid}?prevPage=${window.location.pathname}`}
                            >
                                <img
                                    className="grp-descr-img"
                                    alt={currentTemple.tname || 'Default Temple'}
                                    src={currentTemple.imgpath ? getImageAPI() + currentTemple.imgpath : deftemples}
                                />
                            </Link>
                            <div className="dialog-content-text" style={{ whiteSpace: 'pre-wrap' }}>
                                <p>
                                    <strong>{t('label_addr')} :</strong>{' '}
                                    {currentTemple.addr1 || 'N/A'}
                                    {currentTemple.addr2 ? `, ${currentTemple.addr2}` : ''}
                                </p>
                                <p>
                                    <strong>{t('label_state_city')} :</strong>{' '}
                                    {currentTemple.lang_city}, {t(currentTemple.st)}
                                </p>
                                <p>
                                    <strong>{t('label_deity_name')} :</strong>{' '}
                                    {currentTemple.deityname || 'N/A'}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="popup-main-footer">
                        <a
                            onClick={prevTemple}
                            className="button_move"
                            disabled={tList && tList.length <= 1}
                            style={{ float: 'left', marginRight: '2px' }}
                        >
                            {t('label_previous')}
                        </a>
                        <a
                            onClick={nextTemple}
                            className="button_move"
                            disabled={tList && tList.length <= 1}
                            style={{ float: 'right' }}
                        >
                            {t('label_next')}
                        </a>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TemplePreviewModal;