import React, { useEffect, useState } from 'react';
import { Breadcrumb, Button, Col, Image, Modal, Result, Row, Select, Typography, message } from 'antd';
import TempleCard from './card';
import { Link, useNavigate } from 'react-router-dom';
import aum from '../../assets/icons/om.jpg';
import { useDispatch, useSelector } from 'react-redux';
import { getFavouritesError, getFavouritesList, getFavouritesLoading, getTempleFavouritesError, getTempleFavouritesList, getTempleFavouritesLoading } from '../../redux/selectors/selectors';
import { deleteTemple, fetchByStDt, fetchContributions, fetchFavourites, fetchPendingTemples, fetchTempleFavourites, postFavourites, removeFavourites, retrieveTemples } from '../../redux/actions/acions';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { CloseCircleOutlined, CloseOutlined } from '@ant-design/icons';
import Error from '../pages/Homepage/error';
import Footer from '../pages/Homepage/footer/footer';
import NearBy from '../pages/Homepage/Temples/nearby';
import showToast from '../../utils/showToast';
import CustomToastContainer from '../../components/common/CustomToastContainer';
import TemplesListMap from '../pages/Homepage/Temples/templesListMap';
import Images from './images';
import deityImages from './godImages';
import TemplePreviewModal from './templePreviewModal';

const TemplesPage = ({ title, description, gList, tLoading, templesList, tList, setFilterId, selectedCity, selectedState, godCtgryList, filterTemplesList, image, filterId, selectedGod, temple, nextTemplesList, prevTemplesList, last_rec, handleDelete, show }) => {
    const { t } = useTranslation();
    const records_per_page = 12;

    // Define 'toggleFilled' function
    const toggleFilled = (id) => {
        setFilled(prevFilled => ({
            ...prevFilled,
            [id]: !prevFilled[id]
        }));
    };

    // Define 'storeTempleDetails' function
    const storeTempleDetails = (temple) => {
        const recentlyViewedTemples = JSON.parse(localStorage.getItem('recentlyViewedTemples')) || [];
        const updatedTemples = [temple, ...recentlyViewedTemples.slice(0, 10)]; // Keep only the latest 10 recently viewed temples
        localStorage.setItem('recentlyViewedTemples', JSON.stringify(updatedTemples));
    };

    // Define 'handleClick' function
    const handleClick = () => {
        storeTempleDetails(temple);
    };

    useEffect(() => {
        i18next.changeLanguage(localStorage.getItem('i18nextLng'));
    }, []);

    function formatBoldText(txt) {
        if (txt) {
            return <div dangerouslySetInnerHTML={
                { __html: formatPlainText(txt) }
            } />
        } else {
            return ''
        }
    }

    function formatPlainText(txt) {
        if (txt) {
            let finalTxt = ''
            let allLines = txt.split("<BR>");
            for (let i = 0; i < allLines.length; i++) {
                if (allLines[i].includes('<B>')) {
                    finalTxt = finalTxt + (allLines[i] + '</B>') + '\n';
                } else {
                    finalTxt = finalTxt + allLines[i] + '\n';
                }
            }
            return finalTxt;
        } else {
            return ''
        }
    }

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isGodModalVisible, setIsGodModalVisible] = useState(false);
    const [isPreviewModalVisible, setPreviewModalVisible] = useState(false);
    const [favList, setFavList] = useState([]);
    const [filled, setFilled] = useState({});
    const [hideGodSection, setHideGodSection] = useState(false);
    const [hideNext, setHideNext] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [iconsVisible, setIconsVisible] = useState(false);
    const [modalPreviewVisible, setModalPreviewVisible] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { fList, fLoading, fError } = useSelector(state => ({
        fList: getTempleFavouritesList(state),
        fLoading: getTempleFavouritesLoading(state),
        fError: getTempleFavouritesError(state),
    }));

    const getToken = Boolean(localStorage.getItem('token'));

    // Handle popstate (browser back button)
    useEffect(() => {
        const handlePopState = (event) => {
            if (isPreviewModalVisible) {
                closePreviewModal(); // Close the modal
                navigate(-1); // Navigate back
            }
        };

        if (isPreviewModalVisible) {
            window.addEventListener('popstate', handlePopState);
        }

        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, [isPreviewModalVisible, navigate]);

    // Handle Escape key press
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape' && isPreviewModalVisible) {
                closePreviewModal();
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [isPreviewModalVisible]);

    const openPreviewModal = () => {
        setPreviewModalVisible(true);
        // Add a new entry to the browser's history stack
        window.history.pushState({ modalOpen: true }, '');
    };

    const closePreviewModal = () => {
        setPreviewModalVisible(false);
        // Remove the history entry added for the modal
        window.history.back();
    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    const closeShowMoreModal = () => {
        setIsModalVisible(false);
    };

    const handleFilterGodClick = () => {
        setIsGodModalVisible(true);
    };

    const handleFilterGodCancel = () => {
        setIsGodModalVisible(false);
    };

    const handleGoBack = () => {
        setFilterId(null);
        localStorage.removeItem('filterId');
        navigate(-1);
    };

    const toggleIcons = () => setIconsVisible(!iconsVisible);

    const openModal = () => {
        setModalVisible(true);
    };

    const closeModal = () => {
        setModalVisible(false);
    };

    const toggleFavorite = (temple) => {
        if (getToken) {
            const templeId = temple.tid;
            if (favList.includes(templeId)) {
                deleteFavourites(templeId, temple.tname);
            } else {
                addFavourites(templeId, temple.tname);
            }
        } else {
            showToast('error', t('label_fav_error'));
        }
    };

    const addFavourites = (id, tname) => {
        dispatch(postFavourites(id));
        setFavList(prevFavList => [...prevFavList, id]);
        localStorage.setItem('favourites', JSON.stringify([...favList, id]));
        showToast('success', `${t('label_added')} ${t(tname)} ${t('label_to_favourites')}`);
    };

    const deleteFavourites = (id, tname) => {
        dispatch(removeFavourites(id));
        setFavList(prevFavList => prevFavList.filter(favId => favId !== id));
        localStorage.setItem('favourites', JSON.stringify(favList.filter(favId => favId !== id)));
        showToast('info', `${t('label_removed')} ${t(tname)} ${t('label_from_favourites')}`);
    };

    return (
        <div className="mainsec">
            <div className="mob-nav fluid-container page-title justify-content-between">
                <div className="d-flex align-items-center left-sec-mob-nav">
                    <Link onClick={handleGoBack} to='/'><i className="fa-solid fa-angle-left"></i></Link>
                    <span className="bc-link">{title}</span>
                </div>
                <div className="right-sec-mob-nav d-flex align-items-center">
                    {(!window.location.pathname.startsWith('/templeGroup') &&
                        !window.location.pathname.startsWith('/searchResults') &&
                        !window.location.pathname.startsWith('/searchFavourite')) &&
                        !window.location.pathname.startsWith('/recentlyViewed') && (
                            <button className="declineButton god-filter-btn" onClick={handleFilterGodClick}>
                                {t("label_sel_god")}
                                <i className="fa-solid fa-om animate__animated animate__heartBeat" style={{ display: 'block' }}></i>
                                {filterId && (
                                    <div className="detail-container">
                                        <span className="god-button">{deityImages[filterId]}</span>
                                        <CloseCircleOutlined
                                            className="close-icon"
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                setFilterId(null);
                                            }}
                                        />
                                    </div>
                                )}
                                {filterId === null && (
                                    <div className="detail-container d-flex align-items-center">
                                        <span className="detail-but-god ml-2">{t("label_all")}</span>
                                    </div>
                                )}
                            </button>
                        )}
                </div>
            </div>

            <div className="f2 font-weight-bold mb-3 mt-3 container title-head-txt breadcrumb-link">
                <div className="d-flex">
                    <span>
                        <Link style={{ color: 'black' }} to='/'>
                            <span className="bc-active-link">{t('Home')}</span>
                        </Link> -{' '}
                        <Link style={{ color: 'black' }} to="/templeCtgry" onClick={handleGoBack}>
                            <span className="bc-active-link">{t('label_temples_list')}</span>
                        </Link> - {' '}
                    </span> &nbsp;<span className="bc-link mr-2"> {title}</span>
                </div>
            </div>

            <div className="mainsec-block">
                <div className="container cat-txt">
                    <div className="cat-detail">
                        <span className="f3 d-flex justify-content-between">
                            <div>
                                <b>{title}</b> &nbsp; &nbsp;<i className="fa-solid fa-volume-high"></i>
                            </div>
                            {description && description.length >= 50 && (
                                <div className="detail-but" onClick={showModal}>
                                    {t('label_show_more')}
                                </div>
                            )}
                        </span>
                        <p
                            className="f1"
                            dangerouslySetInnerHTML={{
                                __html: description
                                    ? description.replace(/<BR>/g, '<br>').replace(/<B>(.*?)<\/B>/g, '<strong>$1</strong>')
                                    : ''
                            }}
                        />
                    </div>
                </div>

                <div className="container search">
                    {tLoading ? (
                        <Error />
                    ) : (
                        <div className="tiles-main">
                            {tList && tList.length > 0 ? (
                                tList.map(temple => (
                                    <TempleCard
                                        key={temple.tid}
                                        id={temple.tid}
                                        imgpath={temple.imgpath}
                                        title={temple.tname}
                                        addr1={temple.addr1}
                                        city={temple.lang_city}
                                        state={temple.st}
                                        temple={temple}
                                        favList={favList}
                                        toggleFavorite={toggleFavorite}
                                        toggleFilled={toggleFilled}
                                        storeTempleDetails={storeTempleDetails}
                                        latit={temple.latit}
                                        longi={temple.longi}
                                        link={`/templeDetails/${temple.tid}`}
                                        handleDelete={handleDelete}
                                        handleClick={handleClick}
                                        tList={tList}
                                    />
                                ))
                            ) : (
                                <Result
                                    status="404"
                                    title={t("label_nodata_found")}
                                    subTitle={t("label_nodata_temple_msg")}
                                />
                            )}
                        </div>
                    )}
                </div>

                {!hideNext && (
                    <div className="next-row">
                        {tList && tList.length > 0 ? (
                            <Col>
                                <a
                                    className="button_move"
                                    onClick={last_rec === 0 ? null : prevTemplesList}
                                    disabled={last_rec === 0}
                                >
                                    {t("label_previous")}
                                </a>
                            </Col>
                        ) : ''}
                        {tList && tList.length > 0 ? (
                            <Col>
                                <a
                                    className="button_move"
                                    onClick={tList.length < records_per_page ? null : nextTemplesList}
                                    disabled={tList && tList.length < records_per_page}
                                >
                                    {t("label_next")}
                                </a>
                            </Col>
                        ) : ''}
                        {/* <Col>
                            <a
                                href="#"
                                className="button_move"
                                onClick={openPreviewModal}
                                style={{ pointerEvents: !tList || tList.length === 0 ? 'none' : 'auto', opacity: !tList || tList.length === 0 ? 0.5 : 1 }}
                            >
                                Preview
                            </a>
                        </Col> */}
                    </div>
                )}

                <TemplePreviewModal
                    tList={tList}
                    isVisible={isPreviewModalVisible}
                    onClose={closePreviewModal}
                />

                <Footer />
            </div>
        </div>
    );
};

export default TemplesPage;