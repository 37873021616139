import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { Button, Checkbox, Col, Form, Input, Popover, Row, Select, Spin, Typography, message, } from 'antd';
import { LockOutlined, MailOutlined, PhoneOutlined, UserOutlined } from '@ant-design/icons';
import { editLang, fetchNotifications, fetchRefreshToken, fetchState, fetchTempleFavourites, fetchValidUserLogin, postPasswordRequest, postRegisterUserRequest, postUserActivity, removeNotification } from '../../../../redux/actions/acions';
import { useDispatch, useSelector } from 'react-redux';
import { getStateError, getStateList, getStateLoading } from '../../../../redux/selectors/selectors';
import showToast from '../../../../utils/showToast';
import { ToastContainer } from 'react-toastify';
import Error from '../error';
import AP_LANGUAGES from "../../../common/appLangs"
import { getServerImageAPI } from '../../../../http-common';
import deftemples from '../../../../assets/images/templeicon.jpg'
import FooterMobile from '../footer/footerMobile';

function Login1({ }) {
    const [token, setToken] = useState('');
    const [name, setName] = useState('')
    const { Option } = Select;
    const showDrawer = () => {
        setOpen(true);
    };

    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const storedLanguage = localStorage.getItem('lng');
    const [refresh, setRefresh] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();
    const [loginError, setLoginError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isPopoverVisible, setIsPopoverVisible] = useState(false);
    const [popoverVisible, setPopoverVisible] = useState(false);
    //const firstLetter = getName.charAt(0);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^[0-9]{1,10}$/;

    const validatePhoneNumber = (_, value) => {
        if (value && !phoneRegex.test(value)) {
            return Promise.reject(new Error('Please enter a valid Phone Number! Only up to 10 digits are allowed.'));
        }
        return Promise.resolve();
        if (value && !phoneRegex.test(value)) {
            return Promise.reject(new Error('Please enter a valid Phone Number! Only up to 10 digits are allowed.'));
        }
        return Promise.resolve();
    };



    const [selectedRole, setSelectedRole] = useState(null);
    const success = async () => {
        showToast('success', t('label_login_sucess'));
        setIsLoginModalOpen(false);
        setIsLoading(true);

        await new Promise(resolve => setTimeout(resolve, 2000));

        navigate(selectedRole === 'AD' ? '/quicklinks' : selectedRole === 'TA' ? '/templeDashboard' : '/');
        setIsLoading(false);
        setShowLoginForm(false);
        window.location.reload()
        setTimeout(() => {
            setShowWelcome(true);
        }, 30);
        localStorage.setItem('showWelcome', true);
    };

    // On component mount, check if showWelcome is stored in localStorage
    useEffect(() => {
        const storedShowWelcome = localStorage.getItem('showWelcome') === 'true';

        if (storedShowWelcome) {
            setShowWelcome(true);  // Show the welcome message
            localStorage.removeItem('showWelcome'); // Clean up after showing the welcome message

            // Automatically hide the welcome message after 3 seconds
            setTimeout(() => {
                setShowWelcome(false);
            }, 3000);
        }
    }, []);


    const failure = (msg) => {
        setLoginError(msg); // Set the error message using the `setLoginError` state function
        showToast('error', msg);
    };


    const key = 'updatable';
    const openMessage = () => {

        messageApi.open({
            key,
            type: 'loading',
            content: 'Loading...',
        });
        setTimeout(() => {
            showToast('success', t('label_register_sucess'));
        }, 1000);
    };

    const { sDetails, sLoading, sError } = useSelector(state => ({
        sDetails: getStateList(state),
        sLoading: getStateLoading(state),
        sError: getStateError(state)
    }));
    useEffect(() => {
        if (getToken) {
            dispatch(fetchState());
        }
    }, [])

    useEffect(() => {
        i18next.changeLanguage('1')
        localStorage.setItem('lng', 1)
    }, [])


    const forgetSuccess = () => {
        showToast('success', 'Check your mail to change password!');
        // messageApi.open({
        //   type: 'success',
        //   content: 'Check your mail to change password',
        // });
    };
    const forgetFailure = (msg) => {
        showToast('failure', msg);
        // messageApi.open({
        //   type: 'failure',
        //   content: msg,
        // });
    };

    const onRegister = (values) => {
        dispatch(postRegisterUserRequest(values, openMessage, failure))
    };


    const [isForgetModalOpen, setIsForgetModalOpen] = useState(false);

    const forgetOnFinish = (values) => {
        const data = {
            "email": values.email
        }
        dispatch(postPasswordRequest(data, forgetSuccess, forgetFailure));

    };

    const handlePredefinedLogin = (email, password) => {
        // Set predefined values
        form.setFieldsValue({
            username: email,
            password: password
        });
        // Automatically submit the form
        form.submit();
    };

    const [form] = Form.useForm();

    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
    const dispatch = useDispatch();


    const loginOnFinish = async (values) => {
        dispatch(fetchValidUserLogin(values.username, values.password, success, failure), () => success());
    }

    const handleRoleClick = (roleEmail, rolePassword, role) => {
        setSelectedRole(role);
        form.setFieldsValue({
            username: roleEmail,
            password: rolePassword,
        });
        form.submit();
    };


    const getToken = Boolean(localStorage.getItem('token'));

    // const [visible, setVisible] = useState(true);
    useEffect(() => {
        const timer = setTimeout(() => {
            setVisible(false);
        }, 3000);

        return () => {
            clearTimeout(timer);
        };
    }, []);

    const [showWelcome, setShowWelcome] = useState(false);

    useEffect(() => {
        // Close the welcome message after 30 seconds
        const closeWelcomeTimeout = setTimeout(() => {
            setShowWelcome(false);
        }, 3000);

        // Cleanup the timeout to avoid memory leaks
        return () => clearTimeout(closeWelcomeTimeout);
    }, [showWelcome]);

    const [visible, setVisible] = useState(true); // For popover visibility
    const [showLoginForm, setShowLoginForm] = useState(false); // For login form visibility
    const [activeForm, setActiveForm] = useState('login'); // To switch between login, register, and forget

    if (activeForm === 'register') {
        const location = useLocation();
        const pageName = location.pathname;
        const data = {
            page: pageName,
            action: 'Click',
            message: `Visited ${pageName}`,
        };

        dispatch(postUserActivity(data));
    }

    const handleVisibleChange = (visible) => {
        setVisible(visible);
    };

    const handleFormSwitch = (formType) => {
        setActiveForm(formType);
    };

    const handleFormCancel = () => {
        setShowLoginForm(false);
        setActiveForm('login');
    };

    return (
        <>
            {contextHolder}
            <ToastContainer />

            {showLoginForm && (

                <div className={`signin ${showLoginForm ? 'show' : 'hide'}`}>
                    {isLoading && (<Error />)}
                    <Row>
                        <Col>
                            <div className="popup-bg" style={{ display: 'flex' }}>
                                <div className="popup-main-login animate__animated animate__fadeInUp">
                                    <div className="popup-main-container-login">
                                        {activeForm === 'login' && (
                                            <div className="d-block popup-login-main">

                                                <Form
                                                    name="basic"
                                                    labelCol={{ span: 24 }}
                                                    wrapperCol={{ span: 24 }}
                                                    initialValues={{ remember: true }}
                                                    onFinish={loginOnFinish}
                                                    autoComplete="off"
                                                    className="form-box"
                                                    form={form}
                                                >
                                                    <div className="logo_login"></div>
                                                    <h1 className="login-txt">{t('Login')}</h1>
                                                    <Form.Item
                                                        name="username"
                                                        rules={[
                                                            { required: true, message: t('label_enter_email') },
                                                            { pattern: emailRegex, message: 'Please enter a valid Email!' }
                                                        ]}
                                                    >
                                                        <Input prefix={<MailOutlined />} placeholder={t('label_email')} />
                                                    </Form.Item>
                                                    <Form.Item
                                                        name="password"
                                                        rules={[{ required: true, message: t('label_enter_pwd')}]}
                                                    >
                                                        <Input.Password prefix={<LockOutlined />} placeholder={t('label_pwd')} />
                                                    </Form.Item>
                                                    <Form.Item name="remember" valuePropName="checked">
                                                        <Checkbox style={{ color: "white" }}>{t("label_remember")}</Checkbox>
                                                    </Form.Item>
                                                    <Form.Item>
                                                        <Button type="primary" htmlType="submit" className="login-form-button hoverbg">
                                                            {t('Login')}
                                                        </Button>

                                                        {loginError && <p style={{ color: 'red' }}> {t("error_msg_26")}</p>}
                                                    </Form.Item>
                                                    <Row>
                                                        <a className="login-form-forgot" onClick={() => handleFormSwitch('forget')}>
                                                            {t("label_forgot_pwd")}
                                                        </a>
                                                    </Row>
                                                    <p className="reg-btn-login">
                                                        {t("error_msg_73")} <a style={{ color: '#ffbd01' }} onClick={() => handleFormSwitch('register')}>{t("error_msg_71")}</a>
                                                    </p>
                                                    <div className="role-buttons" >
                                                        <a className='button_move' style={{ marginRight: "5px" }} onClick={() => handleRoleClick('anitha@vavili.com', 'test@123', 'AD')}>AD</a>
                                                        <a className='button_move' style={{ marginRight: "5px" }} onClick={() => handleRoleClick('sairam@vavili.com', 'test@123', 'TA')}>TA</a>
                                                        <a className='button_move' style={{ marginRight: "5px" }} onClick={() => handleRoleClick('shannu@vavili.com', 'test@123', 'PL')}>PL</a>
                                                        <a className='button_move' onClick={() => handleRoleClick('vanarasi18@gmail.com', 'test@123')}>SU</a>
                                                    </div>
                                                </Form>
                                            </div>
                                        )}

                                        {activeForm === 'register' && (
                                            <div className="d-block popup-login-main">
                                                <Form
                                                    name="basic"
                                                    labelCol={{ span: 24 }}
                                                    wrapperCol={{ span: 24 }}
                                                    initialValues={{ remember: true }}
                                                    onFinish={onRegister}
                                                    autoComplete="off"
                                                    className="form-box"
                                                >
                                                    <div className="logo_login"></div>
                                                    <h1 className="login-txt">{t("error_msg_71")}</h1>
                                                    <Form.Item name="fullname" rules={[{ required: true, message: t("label_enter_name") }]}>
                                                        <Input prefix={<UserOutlined />} placeholder={t('label_fullname')} />
                                                    </Form.Item>
                                                    <Form.Item
                                                        name="email"
                                                        rules={[
                                                            { required: true, message: t('label_enter_email') },
                                                            { pattern: emailRegex, message: 'Please enter a valid Email!' }
                                                        ]}
                                                    >
                                                        <Input prefix={<MailOutlined />} placeholder={t('label_email')} />
                                                    </Form.Item>
                                                    <Form.Item
                                                        name="phone"
                                                        placeholder="phone"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: t('label_enter_phone'),
                                                            },
                                                            {
                                                                validator: validatePhoneNumber,
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            prefix={<PhoneOutlined />}
                                                            placeholder={t('label_enter_ph_no')}
                                                            onInput={(e) => {
                                                                e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                                            }}
                                                        />
                                                    </Form.Item>
                                                    <Form.Item name="scode" rules={[{ required: true, message: t('label_enter_pwd')}]}>
                                                        <Input.Password prefix={<LockOutlined />} placeholder={t('label_pwd')} />
                                                    </Form.Item>
                                                    <Form.Item>
                                                        <Button type="primary" htmlType="submit" className="login-form-button hoverbg" style={{ marginTop: "5px" }}>
                                                            {t('label_sign_up')}
                                                        </Button>
                                                        {isLoading && <Spin />}
                                                        {loginError && <p style={{ color: 'red' }}> {t("error_msg_72")}</p>}
                                                        <p className="reg-btn-login">
                                                            {t("error_msg_a")} <a style={{ color: '#ffbd01' }} onClick={() => handleFormSwitch('login')}>{t('Login')}</a>
                                                        </p>
                                                    </Form.Item>
                                                </Form>
                                            </div>
                                        )}

                                        {activeForm === 'forget' && (
                                            <div className="d-block popup-login-main">
                                                <Form
                                                    name="basic"
                                                    labelCol={{ span: 24 }}
                                                    wrapperCol={{ span: 24 }}
                                                    initialValues={{ remember: true }}
                                                    onFinish={forgetOnFinish}
                                                    autoComplete="off"
                                                    className="form-box"
                                                >
                                                    <div className="logo_login"></div>
                                                    <h1 className="login-txt">{t('Forget Password')}</h1>
                                                    <Form.Item
                                                        name="email"
                                                        rules={[{ pattern: emailRegex, message: 'Please enter a valid Email!' }]}
                                                    >
                                                        <Input prefix={<MailOutlined />} placeholder={t('label_email')} />
                                                    </Form.Item>
                                                    <Form.Item name="phone" rules={[{ message: t('label_enter_phone') }]}>
                                                        <Input
                                                            prefix={<PhoneOutlined />}
                                                            placeholder={t('label_enter_ph_no')}
                                                            onInput={(e) => {
                                                                e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                                            }}
                                                        />
                                                    </Form.Item>
                                                    <Form.Item>
                                                        <Button type="primary" htmlType="submit" className="login-form-button hoverbg" style={{ marginTop: "5px" }}>
                                                            {t("label_reset_pwd")}
                                                        </Button>
                                                        <p className="reg-btn-login">
                                                            {t("label_remem_pwd")} |  <a style={{ color: '#ffbd01' }} onClick={() => handleFormSwitch('login')}>{t('Login')}</a>
                                                        </p>
                                                        {isLoading && <Spin />}
                                                    </Form.Item>
                                                </Form>
                                            </div>
                                        )}
                                    </div>

                                    <div className="popup-main-footer-login">
                                        <a className='button_move' type="default" onClick={handleFormCancel}>{t('label_cancel')}</a>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            )}
            {showWelcome && (
                <div class="welcomebg">
                    <div class="welcome-image"></div>
                </div>
            )}

        </>
    )
}

export default Login1;