import React, { useEffect, useState } from 'react';
import {
    Button, Form, Input, Typography, Dropdown, Space, message,
    Upload, Modal, Image, Col, Row, Select, DatePicker
} from 'antd';
import dayjs from 'dayjs';
import "../../addTemple.css";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { editResource, fetchDailyHoroscopeDetails, postHoroscope } from '../../../../redux/actions/acions';

import { useTranslation } from 'react-i18next';
import statesData from '../../states.json';
import { getDailyHoroscopeDetailsList} from '../../../../redux/selectors/selectors';
import i18next from 'i18next';
import showToast from '../../../../utils/showToast';
import CustomToastContainer from '../../../common/CustomToastContainer';
import Footer from '../footer/footer';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

const normFile = (e) => {

    if (Array.isArray(e)) {
        return e;
    }
    return e.fileList;
};

const AddDailyPanchangam = () => {
    const { TextArea } = Input;
    const { t } = useTranslation();
    const [crop, setCrop] = useState({ unit: '%', width: 30, aspect: 16 / 9 });
    const { hid } = useParams()
    const [selectedDeity, setSelectedDeity] = useState(null);

    const dispatch = useDispatch()
    const [form] = Form.useForm();

    const [selectedState, setSelectedState] = useState("label_state");
    const [selectedCity, setSelectedCity] = useState('City');
    const [selectedSpclty, setSelectedSpclty] = useState('');
    const [state, setState] = useState(null);
    const { Option } = Select;

    const [filteredStates, setFilteredStates] = useState([]);
    const [filteredCities, setFilteredCities] = useState([]);

    const states = statesData.states.map((state) => state.name);

    const handleStateChange = (value) => {
        const newState = statesData.states.find((state) => state.name === value);
        setSelectedState(value); // Update the selected state
        setSelectedCity("All"); // Reset the selected city to "All"
        setState(newState);
    };

    const handleStateSearch = (value) => {
        const filteredStates = states.filter((stateName) =>
            stateName.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredStates(filteredStates);
    };

    // Function to handle city search
    const handleCitySearch = (value) => {
        const cities = state ? state.cities : [];
        const filteredCities = cities.filter((cityName) =>
            cityName.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredCities(filteredCities);
    };


    const onFinish = () => {
        form
            .validateFields()
            .then(() => {
                const values = form.getFieldsValue();
                const data = {
                    "lang": values.lang,
                    "region": values.region,
                    "st": values.st,
                    "publish_date": dayjs(values.publish_date).format('YYYY-MM-DD'),
                    "title": values.title,
                    "year_info": values.year_info,
                    "author": values.author,
                    "nakshatra": values.nakshatra,
                    "ctgry": values.ctgry,
                    "tithi": values.tithi,
                    "ruthuvu": values.ruthuvu,
                    "maasam": values.maasam,
                    "vaaram": values.vaaram,
                    "yoga": values.yoga,
                    "karanam": values.karanam,
                    "varjyam": values.varjyam,
                    "durmuhurth": values.durmuhurth,
                    "amruthkal": values.amruthkal,
                    "shubhakal": values.shubhakal,
                    "suryarasi": values.suryarasi,
                    "chandrarasi": values.chandrarasi,
                    "sunrise": values.sunrise,
                    "sunset": values.sunset,
                    "txt": values.txt,
                    "yg_time": values.yg_time,
                    "rh_time": values.rh_time,
                }

                // Check if the navigation parameter is 0
                if (hid === '0') {
                    // If 0, use postResource API
                    dispatch(postHoroscope(data, success, failure));
                } else {
                    // If not 0, use editResource API with the appropriate resource ID
                    dispatch(editResource(hid, data, success, failure));
                }
            })
            .catch((errorInfo) => {
                console.error('Validation failed:', errorInfo);
            });

    };

    const storedLanguage = localStorage.getItem('lng') || 5;
    const [selectedLanguage, setSelectedLanguage] = useState(storedLanguage);

    const handleChange = (value) => {
        localStorage.setItem('lng', value);
        setSelectedLanguage(value);
        // Make API request here with the selected language
    };

    useEffect(() => {
        i18next.changeLanguage(localStorage.getItem('i18nextLng'))
        //dispatch(fetchContributions(0, failure));
    }, [])

    const [showAllImages, setShowAllImages] = useState(false);

    const handleImageClick = () => {
        setShowAllImages(true);
    };

    const handleDeityImageClick = (did) => {
        setShowAllImages(false);
        //dispatch(postResource({ did: did, }));
    };


    const handleCloseModal = () => {
        setShowAllImages(false);
    };

    const [messageApi, contextHolder] = message.useMessage();
    const success = () => {
        showToast('success', t('label_add_horo_success'))

        form.resetFields()
    };

    const failure = (msg) => {
        showToast('error', msg)

    };
    const { tDetails } = useSelector(state => ({
        tDetails: getDailyHoroscopeDetailsList(state),
    }))


    useEffect(() => {
        dispatch(fetchDailyHoroscopeDetails(hid, storedLanguage));
    }, [storedLanguage, dispatch]);


    useEffect(() => {
        form.setFieldsValue(tDetails);
    }, [tDetails])

    const navigate = useNavigate();
    const handleGoBack = () => {
        navigate(-1);
    };

    const handleCancelClick = (e) => {
        e.preventDefault();
        form.resetFields();
        navigate(-1);
    };



    return (
        <>
            {contextHolder}
            <CustomToastContainer />
            <div class='mainsec'>
                <div class=" mob-nav fluid-container page-title justify-content-between">
                    <div class="d-flex align-items-center left-sec-mob-nav">
                        <Link onClick={handleGoBack} ><i class="fa-solid fa-angle-left"></i></Link>
                        <span class="bc-link">{t('label_create_daily_panchangam')}</span>
                    </div>
                    <div></div>
                </div>
                <div className='f2 font-weight-bold mb-3 mt-3 container title-head-txt breadcrumb-link' >
                    <Link style={{ color: 'black' }} to='/'>
                        <span className="bc-active-link">{t('Home')}</span>
                    </Link> - <span className="bc-active-link" onClick={handleGoBack}>{t('label_panchangam')}</span> - <span className="bc-link">{hid === '0' ? (
                        <span>{t('label_create_daily_panchangam')}</span>
                    ) : (
                        <span>{t('label_create_daily_panchangam')}</span>
                    )}</span>
                </div>
                <div class="bg-details-highlight">
                <div class=" details-headertxt-singleLine container">
                        <div class="header_txt">{hid === '0' ? (
                            <> {t('label_create_daily_panchangam')}</>
                        ) : (
                            <>{t('label_create_daily_panchangam')}</>
                        )}</div>
                        <div class="details-headertxt-singleLine-btns">
                            <a   className="button_move" onClick={handleCancelClick} style={{ marginRight: '5px' }}>
                                {t("label_cancel")}
                            </a>
                            <a   className="button_move" type="primary" htmlType="submit" onClick={onFinish}>
                                {t("label_save")}
                            </a>
                            <Link className="button_move" to='/addHoroTxt' style={{ marginLeft: "5px" }}>{t("label_horo_by_text")}</Link>
                        </div>                       
                    </div>
                </div>
                <div class="mainsec-block-addtemple-detailspage">
                    <div className='container'>
                        {/* <Link to={'/resource/hid'}><Typography.Text>Back</Typography.Text></Link> */}
                        <Form form={form} name="nest-messages" onFinish={onFinish}

                        // labelCol={{
                        //     span: 7,
                        // }}
                        // wrapperCol={{
                        //     span: 9,
                        // }} 
                        >
                            <div class="float-left w-100" ><h5 class="card-text-title">{t("label_enter_panchangam_details")}
                                {/* <span>|  {t('label_name_author_source')}</span> */}
                            </h5></div>
                            <div class="active-details">
                                {/* <Row gutter={10}>
                                <Col span={16}> */}
                                <div class="tiles-designs" >
                                    <Form.Item
                                        name='title'
                                        label={<Typography.Title level={4}>{t("label_name")}</Typography.Title>}
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <Input style={{ height: '30px' }} />
                                    </Form.Item>
                                </div>
                                <div class="tiles-designs" >
                                    <Form.Item
                                        name='author'
                                        label={<Typography.Title level={4}>{t('label_author_speaker')}</Typography.Title>}
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <Input style={{ height: '30px' }} />
                                    </Form.Item>
                                </div>
                                <div class="tiles-designs" >
                                    <Form.Item
                                        name='lang'
                                        label={<Typography.Title level={4}>{t("label_lang")}</Typography.Title>}
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <Select placeholder={t("label_select_language")}>
                                            <Option value="1">{t("Telugu")}</Option>
                                            <Option value="4">{t("Hindi")}</Option>
                                            <Option value="5">{t("English")}</Option>
                                            <Option value="2">{t("Kannada")}</Option>
                                            <Option value="3">{t("Tamil")}</Option>
                                            <Option value="7">{t("Bengali")}</Option>
                                            <Option value="6">{t("Spanish")}</Option>
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div class="tiles-designs" >
                                    <Form.Item
                                        name='st'
                                        label={<Typography.Title level={4}>{t("label_state")}</Typography.Title>}
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <Select
                                            showSearch  // Enable search
                                            name="state"
                                            value={selectedState}
                                            onChange={(value) => handleStateChange(value)}
                                            onSearch={(value) => handleStateSearch(value)} // Add search function
                                            className="select-element"
                                            style={{ marginRight: '5px', width: '50%' }}
                                            placeholder={t("label_pls_sel_st")}
                                        >
                                            <Option value="State">Select State</Option>
                                            <Option value="All">{t('label_all')}</Option>
                                            {states.map((stateName) => (
                                                <Select.Option key={stateName} value={stateName}>
                                                    {stateName}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div class="tiles-designs" >
                                    <Form.Item
                                        name='year_info'
                                        label={<Typography.Title level={4}>{t('label_year_info')}</Typography.Title>}
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <Input style={{ height: '30px' }} />
                                    </Form.Item>
                                </div>
                                <div class="tiles-designs" >
                                    <Form.Item
                                        name='region'
                                        label={<Typography.Title level={4}>{t('label_region')}</Typography.Title>}
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <Select placeholder={t('label_pls_sel_region')}>
                                            <Option value="1">1</Option>
                                            <Option value="2">2</Option>
                                            <Option value="3">3</Option>
                                            <Option value="4">4</Option>
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div class="tiles-designs" >
                                    <Form.Item
                                        name="publish_date"
                                        label={<Typography.Title level={4}>{t('label_pub_date')}</Typography.Title>}
                                        initialValue={dayjs()}
                                        rules={[{ required: true }]}
                                    >
                                        <DatePicker
                                            style={{ height: '30px' }}
                                            defaultValue={dayjs()}
                                        />
                                    </Form.Item>
                                </div>
                                <div class="tiles-designs">
                                    <Form.Item
                                        name='nakshatra'
                                        label={<Typography.Title level={4}>{t('label_nak')}</Typography.Title>}
                                        rules={[{ required: true }]}
                                    >
                                        <Input style={{ height: '30px' }} />
                                    </Form.Item>
                                </div>
                                <div class="tiles-designs">
                                    <Form.Item
                                        name='yg_time'
                                        label={<Typography.Title level={4}>{t('label_yam')}</Typography.Title>}
                                        rules={[{ required: true }]}
                                    >
                                        <Input style={{ height: '30px' }} />
                                    </Form.Item>
                                </div>
                                <div class="tiles-designs">
                                    <Form.Item
                                        name='rh_time'
                                        label={<Typography.Title level={4}>{t('label_rahu_time')}</Typography.Title>}
                                        rules={[{ required: true }]}
                                    >
                                        <Input style={{ height: '30px' }} />
                                    </Form.Item>
                                </div>
                                <div class="tiles-designs">
                                    <Form.Item
                                        name='tithi'
                                        label={<Typography.Title level={4}>{t('label_tith')}</Typography.Title>}
                                        rules={[{ required: true }]}
                                    >
                                        <Input style={{ height: '30px' }} />
                                    </Form.Item>
                                </div>
                                <div class="tiles-designs">
                                    <Form.Item
                                        name='ruthuvu'
                                        label={<Typography.Title level={4}>{t('label_ruthuvu')}</Typography.Title>}
                                        rules={[{ required: true }]}
                                    >
                                        <Input style={{ height: '30px' }} />
                                    </Form.Item>
                                </div>
                                <div class="tiles-designs">
                                    <Form.Item
                                        name='maasam'
                                        label={<Typography.Title level={4}>{t('label_maasam')}</Typography.Title>}
                                        rules={[{ required: true }]}
                                    >
                                        <Input style={{ height: '30px' }} />
                                    </Form.Item>
                                </div>
                                <div class="tiles-designs">
                                    <Form.Item
                                        name='vaaram'
                                        label={<Typography.Title level={4}>{t('label_vaaram')}</Typography.Title>}
                                        rules={[{ required: true }]}
                                    >
                                        <Input style={{ height: '30px' }} />
                                    </Form.Item>
                                </div>
                                <div class="tiles-designs">
                                    <Form.Item
                                        name='yoga'
                                        label={<Typography.Title level={4}>{t('label_yoga')}</Typography.Title>}
                                        rules={[{ required: true }]}
                                    >
                                        <Input style={{ height: '30px' }} />
                                    </Form.Item>
                                </div>
                                <div class="tiles-designs">
                                    <Form.Item
                                        name='karanam'
                                        label={<Typography.Title level={4}>{t('label_karanam')}</Typography.Title>}
                                        rules={[{ required: true }]}
                                    >
                                        <Input style={{ height: '30px' }} />
                                    </Form.Item>
                                </div>
                                <div class="tiles-designs">
                                    <Form.Item
                                        name='varjyam'
                                        label={<Typography.Title level={4}>{t('label_varjyam')}</Typography.Title>}
                                        rules={[{ required: true }]}
                                    >
                                        <Input style={{ height: '30px' }} />
                                    </Form.Item>
                                </div>
                                <div class="tiles-designs">
                                    <Form.Item
                                        name='durmuhurth'
                                        label={<Typography.Title level={4}>{t('label_durmuhurtham')}</Typography.Title>}
                                        rules={[{ required: true }]}
                                    >
                                        <Input style={{ height: '30px' }} />
                                    </Form.Item>
                                </div>
                                <div class="tiles-designs">
                                    <Form.Item
                                        name='amruthkal'
                                        label={<Typography.Title level={4}>{t('label_amruthkal')}</Typography.Title>}
                                        rules={[{ required: true }]}
                                    >
                                        <Input style={{ height: '30px' }} />
                                    </Form.Item>
                                </div>
                                <div class="tiles-designs">
                                    <Form.Item
                                        name='shubhakal'
                                        label={<Typography.Title level={4}>{t('label_shubkal')}</Typography.Title>}
                                        rules={[{ required: true }]}
                                    >
                                        <Input style={{ height: '30px' }} />
                                    </Form.Item>
                                </div>
                                <div class="tiles-designs">
                                    <Form.Item
                                        name='suryarasi'
                                        label={<Typography.Title level={4}>{t('label_suryarasi')}</Typography.Title>}
                                        rules={[{ required: true }]}
                                    >
                                        <Input style={{ height: '30px' }} />
                                    </Form.Item>
                                </div>
                                <div class="tiles-designs">
                                    <Form.Item
                                        name='chandrarasi'
                                        label={<Typography.Title level={4}>{t('label_chandrarasi')}</Typography.Title>}
                                        rules={[{ required: true }]}
                                    >
                                        <Input style={{ height: '30px' }} />
                                    </Form.Item>
                                </div>
                                <div class="tiles-designs">
                                    <Form.Item
                                        name='sunrise'
                                        label={<Typography.Title level={4}>{t('label_sunrise')}</Typography.Title>}
                                        rules={[{ required: true }]}
                                    >
                                        <Input style={{ height: '30px' }} />
                                    </Form.Item>
                                </div>
                                <div class="tiles-designs">
                                    <Form.Item
                                        name='sunset'
                                        label={<Typography.Title level={4}>{t('label_sunset')}</Typography.Title>}
                                        rules={[{ required: true }]}
                                    >
                                        <Input style={{ height: '30px' }} />
                                    </Form.Item>
                                </div>


                                <div class="tiles-designs" >
                                    <Form.Item
                                        name='txt'
                                        label={<Typography.Title level={4}>{t("label_details")}</Typography.Title>}
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <TextArea rows={4} style={{ marginTop: '6px' }} />
                                    </Form.Item>
                                </div>
                            </div>

                            {/* <Button class="button_move" data-mdb-ripple-color="dark" type="primary" htmlType="submit" >
                                {t("label_save")}
                            </Button> */}
                        </Form>
                    </div>
                    <Footer />
                </div>


            </div>

        </>
    );
};
export default AddDailyPanchangam;