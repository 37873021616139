import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Form, Input, Button, Typography, message } from 'antd';
import { postPasswordReset } from '../../../../redux/actions/acions';
import { useTranslation } from 'react-i18next';
import showToast from '../../../../utils/showToast';
import CustomToastContainer from '../../../common/CustomToastContainer';
import Footer from '../footer/footer';

function PasswordResetForm() {
    const [password, setPassword] = useState('');
    const location = useLocation(); // Use useLocation to get the query params
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();

    // Extract query parameters
    const queryParams = new URLSearchParams(location.search);
    const email = queryParams.get('email');
    const token = queryParams.get('token');

    const success = () => {
        showToast('success', t('label_pword_success'))
    };

    const failure = (msg) => {
        showToast('fail', t('label_pword_failure'))
    };

    const handleSavePassword = () => {
        form.validateFields()
            .then((values) => {
                const data = { "token": token, "pcode": values.pcode, "cpcode": values.cpcode };
                dispatch(postPasswordReset(email, data, success, failure));
            })
            .catch((errorInfo) => {
                console.error('Validation Failed:', errorInfo);
            });
    };

    const handleCancelClick = () => {
        form.resetFields();
    };

    return (
        <div className='mainsec'>
            {contextHolder}
            
            <div className='f2 font-weight-bold mb-3 mt-3 container title-head-txt'>
                <Link style={{ color: 'black' }} to='/'>
                    <span className="bc-active-link">{t('Home')}</span>
                </Link> -
                {/* &nbsp; <span className="bc-link">{t("label_update_password")}</span> */}
            </div>
            <div className="bg-details-highlight">
                <div className="details-headertxt container">
                    <div className="header_txt"> {t('label_update_password')}</div>
                    <div className="">
                        <a type="button" className="button_move" onClick={handleCancelClick} style={{ marginRight: '5px' }}>
                            {t("label_cancel")}
                        </a>
                        <a type="primary" className="button_move" onClick={handleSavePassword}>
                            {t("label_save")}
                        </a>
                    </div>
                </div>
            </div>
            <div className="mainsec-block-addtemple-detailspage">
                <div className='container'>
                    <Form
                        form={form}
                        name="nest-messages"
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 8 }}
                        onFinish={handleSavePassword}
                    >
                        <div className="container"><h5 className="card-text-title">{t("label_update_password")} 
                        {/* <span>| {t('label_new_edit_pwd')}</span> */}
                        </h5></div>
                        <div className="active-details">
                            <div className="tiles-designs">
                                <Form.Item
                                    name="pcode"
                                    label={<Typography.Title level={4}>{t("label_pwd")}</Typography.Title>}
                                    rules={[
                                        { required: true, message: 'Please input your password!' }
                                    ]}
                                    hasFeedback
                                >
                                    <Input.Password style={{ paddingRight: '10px' }} placeholder='Enter password' />
                                </Form.Item>
                            </div>
                            <div className="tiles-designs">
                                <Form.Item
                                    style={{ marginTop: '5px' }}
                                    label={<Typography.Title level={4}>{t("label_confirm_pwd")}</Typography.Title>}
                                    name="cpcode"
                                    dependencies={['pcode']}
                                    hasFeedback
                                    rules={[
                                        { required: true, message: 'Please confirm your password!' },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value || getFieldValue('pcode') === value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(new Error('The passwords you entered do not match!'));
                                            },
                                        }),
                                    ]}
                                >
                                    <Input.Password style={{ paddingRight: '10px' }} placeholder='Re-enter the password' />
                                </Form.Item>
                            </div>
                        </div>
                    </Form>
                </div>
                <Footer />
            </div>
        </div>
    )
}

export default PasswordResetForm;
