import React, { useEffect, useState } from 'react';
import { Typography } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './Footer.css';
import logo from '../../../../assets/images/img.png'
import footer from '../../../../assets/images/footer.jpg'

const Footer = () => {
    const { t } = useTranslation();
    const [token, setToken] = useState('');
    const [name, setName] = useState('')
    const [open, setOpen] = useState(false);
    const showDrawer = () => {
        setOpen(true);
      };

    useEffect(() => {
        const getToken = localStorage.getItem('token');
        const getName = localStorage.getItem('name');
        if (getToken && getName) {
          setToken(getToken);
          setName(getName)
        }
      }, [showDrawer])

    return (
        <div className='site-footer' style={{ marginTop: '7px'  }}>
            <div className="container-fluid" style={{ color: '#ecf0f1' }}>
                <div className="row">
                    <div className="col-md-3">
                        <div className="text-center">
                            <img src={logo} className="fotterlogo img" alt="logo" />
                        </div>
                    </div>
                    <div className="col-md-2 col-sm-12 ff-links">
                        <h4 className="foothd">{t("label_imp_links")}</h4>
                        <Link to="/">{t("Home")}</Link><br />
                        <Link to="/about-iskcon/">{t("label_aboutus")}</Link><br />
                        <Link to="/events/">{t("label_upcom_events")}</Link><br />
                        <Link to="/counselling/">{t("Activities")}</Link><br />
                        <Link to="/festivals/">{t("Festivals")}</Link><br />
                        <Link to="/image-gallery/">{t("label_photo_gal")}</Link>
                    </div>
                    <div className="col-md-2 col-sm-12 ff-links">
                        <h4 className="foothd">{t("label_usefull")}</h4>
                        <Link to="/privacy">{t("label_privacy_policy")}</Link><br />
                        <Link to="/refund-policy/">{t("label_refd_policy")}</Link><br />
                        <Link to="/terms">{t("label_terms_conditions")}</Link><br />
                        <Link to="/site-map/">{t("label_site_map")}</Link><br />
                        <Link to="/cookie-policy/">{t("label_ck_policy")}</Link><br />
                        <Link to="/contactUs">{t("label_contact_us")}</Link><br />
                    </div>
                    <div className="col-md-2 col-sm-12 address-claass ff-links">
                        <h4 className="foothd">{t("label_quick_links")}</h4>
                        <p>
                            <Link to="/templesList">{t('label_fam_temples')}</Link><br />
                            <Link to={`/templeGroup/101`}>{t("label_div_desam")}</Link><br />
                            <Link to={`/templeGroup/117`}>{t("label_iskon_temples")}</Link><br />
                            <Link to={`/state/Telangana/city/All`}>{t("label_temples_in_state")}</Link><br />                            <Link to={`/state/Telangana/city/nellore`}>{t("label_temples_in_nellore")}</Link><br />
                            <Link to={`/state/Telangana/city/karnataka`}>{t("label_temples_in_karnataka")}</Link><br />
                            <Link to={`/searchResults/Shiva Temples in Telangana`}>{t("label_shiva_temples")}</Link><br />
                            <Link to={`/searchResults/ISKON Temple`}>{t("label_iskon_temples")}</Link><br />
                        </p>
                    </div>
                    <div className="col-md-3 col-sm-12 contactalign ff-links">
                        <div className="contactdiv">
                            <h4 className="foothd">{t('label_contact')}</h4>
                            <b style={{ color: '#fab223', fontWeight: 'bold' }}> {t("label_email")}</b><br />
                            <a href="mailto:admin@vavili.com" style={{ color: 'white' }}> admin@vavili.com</a><br />
                            <b style={{ color: '#fab223', fontWeight: 'bold' }}>{t("label_follow_us")}</b><br />
                            <ul className="social-icons">
                                <li><a className="facebook" target="true"  >
                                    <i className="fa fa-facebook-square" aria-hidden="true"></i>
                                </a></li>
                                <li><a className="twitter" target="true" href="">
                                    <i className="fa fa-twitter"></i>
                                </a></li>
                                <li><a target="true" className="instagram" href="">
                                    <i className="fa fa-instagram "></i>
                                </a></li>
                                <li><a target="true" className="linkedin" href="https://www.youtube.com/@TemplesWIKI">
                                    <i className="fa fa-youtube"></i>
                                </a></li>
                            </ul>
                            <div className="fbtn">
                                <Link target="true" className="footbtn" to="/addDonations"> {t("Donate")}</Link>
                            </div>
                        </div>

                    </div>
                </div>
                <div className='copyright'>
                    <Typography.Text>{t("label_about_company")}</Typography.Text>
                </div>
            </div>
        </div>
    );
}

export default Footer;
