import React, { useEffect, useState } from 'react'
import { Card } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Footer from '../footer/footer';
import showToast from '../../../../utils/showToast';
import CustomToastContainer from '../../../common/CustomToastContainer';
import deftemples from '../../../../assets/images/templeicon.jpg'
import horoscope_icon from "../../../../assets/images/horoscope-img.png";

const HoroCategeries = () => {
    const { t } = useTranslation();
    const userRole = localStorage.getItem('urole');
    const [open, setOpen] = useState(false);
    const handleLinkClick = () => {
        setOpen(false);
        showToast('error', ('Please Login to use additional features'));
    };

    return (
        <>

            <div class="mainsec horo-bg">
                <div class=" mob-nav fluid-container page-title justify-content-between">
                    <div class="d-flex align-items-center left-sec-mob-nav">
                        <Link to='/' ><i class="fa-solid fa-angle-left"></i></Link>
                        <span class="bc-link">{t('label_panchangam')}</span>
                    </div>
                    <div></div>
                </div>
                <div className='f2 font-weight-bold mb-3 mt-3 container breadcrumb-link'>

                    <Link style={{ color: 'black' }} to='/'><span class="bc-active-link">{t('Home')}</span></Link> - <span class="bc-link">{t("label_panchangam")}</span></div>
                <div class="mainsec-block">
                    <div class="f2 font-weight-bold  container title-head-txt " style={{ overflowX: 'auto', paddingBottom: '10px', whiteSpace: "nowrap" }}>
                        {(userRole === 'AD' || userRole === 'AA') && (
                            <>
                                <Link to='/editDailyHoroscope/0'>
                                    <button type="button" class="declineButton">{t("label_create_daily_horo")}<i class="fa-solid fa-circle-right animate__animated animate__heartBeat"></i></button></Link>
                                <Link to='/editWeeklyHoroscope/0'>
                                    <button type="button" class="declineButton">{t("label_create_weekly_horo")}<i class="fa-solid fa-circle-right animate__animated animate__heartBeat"></i></button></Link>
                                <Link to='/adddailypanchangam'>
                                    <button type="button" class="declineButton">{t("label_create_daily_panchangam")}<i class="fa-solid fa-circle-right animate__animated animate__heartBeat"></i></button></Link>
                            </>
                        )}

                        {/* <Link to='/nearby'>
                        <button type="button" class="declineButton">{t('label_temples_by_map')}<i class="fa-solid fa-circle-right animate__animated animate__heartBeat"></i></button></Link>
                    {userRole ? (
                        <Link to='/searchFavourite'>
                            <button type="button" class="declineButton">{t("page_title_fav")}<i class="fa-solid fa-circle-right animate__animated animate__heartBeat"></i></button></Link>
                    ) : (
                        <Link className="my-link2" onClick={handleLinkClick}>
                            <button type="button" class="declineButton ">{t("page_title_fav")}<i class="fa-solid fa-circle-right animate__animated animate__heartBeat"></i></button></Link>
                    )} */}
                    </div>
                    <div className="container">
                        <div class="container">
                            <h5 class="card-text-title">{t("Categories")}
                                {/* <span>| {t("label_library_divided_groups")}</span> */}
                            </h5>
                        </div>
                        <div className="tiles-main">
                            <Link to='/todaypanchangam'>
                                <div class="tiles-design-temple-cat animate__animated animate__flipInX">
                                    <div class="title-img-bg">
                                        <img class="card-img-temple-cat" alt="temples" src={horoscope_icon} />
                                    </div>
                                    <div class="tile-text-temple-cat">
                                        <span>{t("label_today_panchamgam")}</span> <span>{("label_temples_list")}</span>
                                    </div>
                                </div>
                            </Link>
                            {/* <Link to='/horoscope'>
                                <div class="tiles-design-temple-cat animate__animated animate__flipInX">
                                    <div class="title-img-bg">
                                        <img class="card-img-temple-cat" alt="temples" src={'https://tse4.mm.bing.net/th?id=OIF.RfN7SL3U9XIvOafeuj6MaA&pid=Api&P=0&h=180'} />
                                    </div>
                                    <div class="tile-text-temple-cat">
                                        <span>{t("label_daily_panchamgam")}</span> <span>{("label_temples_list")}</span>
                                    </div>
                                </div>
                            </Link> */}
                            <Link to='/dailyRaasiDetails'>
                                <div class="tiles-design-temple-cat animate__animated animate__flipInX">
                                    <div class="title-img-bg">
                                        <img class="card-img-temple-cat" alt="temples" src={horoscope_icon} />
                                    </div>
                                    <div class="tile-text-temple-cat">
                                        <span>{t("label_daily_horoscope")}</span> <span>{("label_temples_list")}</span>
                                    </div>
                                </div>
                            </Link>
                            <Link to={`/dailyRaasiDetails/${'weekly'}`}>
                                <div class="tiles-design-temple-cat animate__animated animate__flipInX">
                                    <div class="title-img-bg">
                                        <img class="card-img-temple-cat" alt="temples" src={horoscope_icon} />
                                    </div>
                                    <div class="tile-text-temple-cat">
                                        <span>{t("label_weekly_horo")}</span> <span>{("label_weekly_horo")}</span>
                                    </div>
                                </div>
                            </Link>
                            <Link to={`/dailyRaasiDetails/${'monthly'}`}>
                                <div class="tiles-design-temple-cat animate__animated animate__flipInX">
                                    <div class="title-img-bg">
                                        <img class="card-img-temple-cat" alt="temples" src={horoscope_icon} />
                                    </div>
                                    <div class="tile-text-temple-cat">
                                        <span>{t("label_monthly")}</span> <span>{("label_monthly")}</span>
                                    </div>
                                </div>
                            </Link>
                            <Link to={`/dailyRaasiDetails/${'yearly'}`}>
                                <div class="tiles-design-temple-cat animate__animated animate__flipInX">
                                    <div class="title-img-bg">
                                        <img class="card-img-temple-cat" alt="temples" src={horoscope_icon} />
                                    </div>
                                    <div class="tile-text-temple-cat">
                                        <span>{t("label_yearly")}</span> <span>{("label_yearly")}</span>
                                    </div>
                                </div>
                            </Link>
                            {/* <Link to='/weeklyHoroscope'>
                                <div class="tiles-design-temple-cat animate__animated animate__flipInX">
                                    <div class="title-img-bg">
                                        <img class="card-img-temple-cat" alt="temples" src={'https://tse4.mm.bing.net/th?id=OIF.I3E8Gl1VLLhNKa7EwUpB5w&pid=Api&P=0&h=180'} />
                                    </div>
                                    <div class="tile-text-temple-cat">
                                        <span>{t("label_weekly_horoscope")}</span> <span>{("label_temples_list")}</span>
                                    </div>
                                </div>
                            </Link> */}
                        </div>
                    </div>

                    <Footer />
                </div>
            </div>
        </>
    )

}

export default HoroCategeries;