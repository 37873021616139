import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Typography from 'antd/es/typography/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Col, DatePicker, Form, Image, Input, Popconfirm, Row, Select, Skeleton, message } from 'antd';
import { getPriestDetailsError, getPriestDetailsList, getPriestDetailsLoading } from '../../redux/selectors/selectors';
import { fetchPriestDetails, postProfileImageRequest, updatePriestDegree, updatePriestDetails, updatePriestSpclty } from '../../redux/actions/acions';
import deftemples from '../../assets/images/templeicon.jpg'
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import TextArea from 'antd/es/input/TextArea';
import showToast from '../../utils/showToast';
import CustomToastContainer from '../../components/common/CustomToastContainer';
import Footer from '../pages/Homepage/footer/footer';
import Cropper from 'cropperjs';
import { getServerImageAPI } from '../../http-common';

const PriestDetails = () => {
    const { t } = useTranslation();
    const storedLanguage = localStorage.getItem('lng') || 5;
    const { id } = useParams()
    const { name } = useParams()
    const { city } = useParams()
    const { st } = useParams()
    const dispatch = useDispatch();
    const urole = localStorage.getItem('urole')
    const [messageApi, contextHolder] = message.useMessage();
    const navigate = useNavigate();
    const [amount, setAmount] = useState(null);
    const [dontype, setDontype] = useState(null);
    const [notes, setNotes] = useState(null);
    const [bill, setBill] = useState(null);
    const [payment, setPayment] = useState(null);

    const success = () => {
        showToast('success', 'label_add_temple_sucess')
        form.resetFields()
    };

    const failure = (msg) => {
        showToast('error', msg); // You can use 'error' directly
    };

    const { rList, rLoading, rError } = useSelector(state => ({
        rList: getPriestDetailsList(state),
        rLoading: getPriestDetailsLoading(state),
        rError: getPriestDetailsError(state),
    }));

    useEffect(() => {
        dispatch(fetchPriestDetails(id));
    }, [dispatch]);

    const handleGoBack = () => {
        navigate(-1);
    };

    const [formData, setFormData] = useState({
        // fullname: sDetails.fullname || '',
        // gender: sDetails.gender || '',
        // lang: sDetails.lang || '',
    });

    const [editable, setEditable] = useState(false);
    const [buttonText, setButtonText] = useState(t("edit"));
    const [form] = Form.useForm();

    const handleCancelClick = () => {
        // Reset the form to its initial values
        // form.resetFields();
        setButtonText('Edit');
        setEditable(false);
    };

    const handleEditClick = () => {
        if (!editable) {
            setButtonText(t("label_cancel"));
            // Populate the input field with the current value when entering edit mode
            setFormData({ ...formData, amount: rList && rList.amount });
            setFormData({ ...formData, otherdeities: rList && rList.otherdeities });
        } else {
            setButtonText('Edit');
        }
        setEditable(!editable);
    };

    const handleSelect = (value) => {
        dispatch(updatePriestSpclty({ splty: `${value}` }, success));
    };

    const handleDetails = (e) => {
        setNotes(e.target.value)
    };

    const updateDetails = (values) => {
        const data = {
            addr1: values.addr1,
            addr2: values.addr2,
            phone: values.phone,
            pin: values.pin,
            fullname: values.fullname
        };
        dispatch(updatePriestDetails(id, data));
    };

    const handleDegree = (e) => {
        setBill(e.target.value)
    };

    const updateDegree = (values) => {
        const data = {
            "degree1": values.degree1,
            "degree2": values.degree2
        }
        dispatch(updatePriestDegree(id, data));
    };

    const [previewImage, setPreviewImage] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    
    const handleGalleryClick = () => {
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = 'image/*';
    
        fileInput.addEventListener('change', (event) => {
          const selectedFile = event.target.files[0];
          if (selectedFile) {
            const reader = new FileReader();
            reader.onload = () => {
              setSelectedImage(reader.result);
              setIsModalVisible(true);
            };
            reader.readAsDataURL(selectedFile);
          }
        });
    
        fileInput.click();
      };
    
      const imageRef = useRef();
      const [cropper, setCropper] = useState(null);
      const handleImageCloseModal = () => {
        setIsModalVisible(false);
        if (cropper) {
          cropper.destroy();
        }
      };
    
      const [loading, setLoading] = useState(false);
      const [hasError, setHasError] = useState(false);
      const [newImagePath, setNewImagePath] = useState('');
      const getToken = Boolean(localStorage.getItem('token'));

      const handleCropAndUpload = () => {
        if (cropper) {
          cropper.getCroppedCanvas().toBlob((blob) => {
            const formData = new FormData();
            formData.append('file', blob);
            setLoading(true);
            setHasError(false);
            dispatch(
              postProfileImageRequest(
                formData,
                () => {
                  const uploadedImagePath = URL.createObjectURL(blob);
                  setNewImagePath(uploadedImagePath);
                  handleImageCloseModal();
                  setLoading(false);
                  if (getToken) {
                    dispatch(fetchPriestDetails(id));
                  }
                },
                (errorMsg) => {
                  console.error('Image upload failed:', errorMsg);
                  setLoading(false);
                  setHasError(true);
                }
              )
            );
          });
        }
      };
    
      useEffect(() => {
        if (isModalVisible && imageRef.current) {
          const cropperInstance = new Cropper(imageRef.current, {
            aspectRatio: 16 / 9,
            viewMode: 1,
            autoCropArea: 1,
            responsive: true,
            scalable: false,
            zoomable: true,
            cropBoxResizable: true,
            minCropBoxHeight: 200,
            maxCropBoxHeight: 200,
            minCropBoxWidth: imageRef.current.clientWidth,
            maxCropBoxWidth: imageRef.current.clientWidth,
          });
          setCropper(cropperInstance);
        }
    
        return () => {
          if (cropper) {
            cropper.destroy();
          }
        };
      }, [isModalVisible]);


    return (
        <div className='mainsec'>
            <div class=" mob-nav fluid-container page-title justify-content-between">
                <div class="d-flex align-items-center left-sec-mob-nav">
                    <Link onClick={handleGoBack} ><i class="fa-solid fa-angle-left"></i></Link>
                    <span class="bc-link">{t('label_priest_by_spclty')}</span>
                </div>
                <div></div>
            </div>
            {contextHolder}

            <div className='f2 font-weight-bold mb-3 mt-3 container title-head-txt breadcrumb-link' >
                <Link style={{ color: 'black' }} to='/'>
                    <span className="bc-active-link">{t('Home')}</span>
                </Link> - <span className="bc-active-link" onClick={handleGoBack}>{t("label_priest")}</span> - <span className="bc-link">{t("label_priest_details")}</span>
            </div>
            <div class="bg-details-highlight">
                <div class=" details-headertxt-singleLine container">
                    <div class="header_txt">
                        {name}
                    </div>
                    <a
                        href={`/addPriestReq/${id}/${name}`}
                        class="button_move"
                    >{t('label_ask_for_priest')}
                    </a>
                </div>

            </div>
            <div class="mainsec-block-addtemple-detailspage">
                <div className='container'>
                    <Form
                        form={form}
                        name='basic'
                        labelCol={{ span: 5 }}
                        wrapperCol={{ span: 10 }}
                    //onFinish={handleSubmit}
                    >

                        <div className='horoscope-item' >
                            <div class="float-left w-100" ><h5 class="card-text-title">{t("label_priest_details")} <span>| {t('label_addr_degree_splt')}</span></h5></div>
                            {/* <h2>{rList.author}</h2> */}
                            <div class="active-details">
                                <div className="tiles-designs">
                                    <Form.Item
                                        label={<Typography.Title level={4}>{t("label_village_area")}</Typography.Title>}
                                        name="addr1"
                                    >
                                        {editable ? (
                                            <Input style={{ height: '30px' }} defaultValue={rList && rList.addr1} />
                                        ) : (
                                            <Typography.Text level={5} className='typ'>{rList && rList.addr1}</Typography.Text>
                                        )}
                                    </Form.Item>
                                </div>
                                <Col span={10} style={{ display: 'flex', flexDirection: 'row-reverse', alignItems: 'flex-end' }}>
                                    <section>
                                        <div className="user-pic">
                                            {editable && (
                                                <div className="upload-overlay">
                                                    <a onClick={handleGalleryClick} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textDecoration: 'none' }}>
                                                        <i className="fa-solid fa-camera" style={{ fontSize: '20px' }}></i>
                                                        <span>Change Profile Image</span>
                                                    </a>
                                                </div>
                                            )}
                                            {rList && rList.profimg ? (
                                                <img alt={rList.fullname || 'Default Name'} src={rList.profimg ? getServerImageAPI() + rList.profimg : deftemples} />
                                            ) : previewImage ? (
                                                <img src={previewImage} alt="Profile" />
                                            ) : (
                                                <img src={deftemples} alt="Default Profile" />
                                            )}
                                        </div>
                                    </section>
                                </Col>
                                <div className="tiles-designs">
                                    <Form.Item
                                        label={<Typography.Title level={4}>{t("label_addr")}</Typography.Title>}
                                        name="addr2"
                                    >
                                        {editable ? (
                                            <Input style={{ height: '30px' }} defaultValue={rList && rList.addr2} />
                                        ) : (
                                            <Typography.Text level={5} className='typ'>{rList && rList.addr2}</Typography.Text>
                                        )}
                                    </Form.Item>
                                </div>
                                <div className="tiles-designs">
                                    <Form.Item
                                        label={<Typography.Title level={4}>{t("label_location")}</Typography.Title>}
                                        name="location"
                                    >
                                        <Typography.Text level={5} className='typ'>{`${st}, ${city}`}</Typography.Text>
                                    </Form.Item>
                                </div>

                                <div className="tiles-designs">
                                    <Form.Item
                                        label={<Typography.Title level={4}>{t("label_phone")}</Typography.Title>}
                                        name="phone"
                                    >
                                        {editable ? (
                                            <Input style={{ height: '30px' }} defaultValue={rList && rList.phone} />
                                        ) : (
                                            <Typography.Text level={5} className='typ'>{rList && rList.phone}</Typography.Text>
                                        )}
                                    </Form.Item>
                                </div>
                                <div className="tiles-designs">
                                    <Form.Item
                                        label={<Typography.Title level={4}>{t("Pin")}</Typography.Title>}
                                        name="pin"
                                    >
                                        {editable ? (
                                            <Input style={{ height: '30px' }} defaultValue={rList && rList.pin} />
                                        ) : (
                                            <Typography.Text level={5} className='typ'>{rList && rList.pin}</Typography.Text>
                                        )}
                                    </Form.Item>
                                </div>
                                <div className="tiles-designs">
                                    {editable && (
                                        <Form.Item>
                                            <Button
                                                className="button_move"
                                                type="primary"
                                                htmlType="button"
                                                onClick={() => {
                                                    const values = form.getFieldsValue(["addr1", "addr2", "phone", "pin"]);
                                                    updateDetails(values);
                                                }}
                                            >
                                                {t("label_update_dtl")}
                                            </Button>
                                        </Form.Item>
                                    )}
                                </div>
                                <div class="tiles-designs" >
                                    <Form.Item
                                        label={<Typography.Title level={4} >{t("label_approved_dt")}</Typography.Title>}
                                        name="apprdt"
                                    >

                                        {editable ? (
                                            <DatePicker
                                                style={{ height: '30px' }}
                                                defaultValue={moment(rList && rList.apprdt)} // Make sure to import 'moment' if you're using Ant Design DatePicker
                                            />
                                        ) : (

                                            <Typography.Text level={5} className='typ'>{rList && rList.apprdt}</Typography.Text>
                                        )}
                                    </Form.Item>

                                </div>
                                <div class="tiles-designs" >
                                    <Form.Item
                                        label={<Typography.Title level={4} >{t("label_Degree1")}</Typography.Title>}
                                        name="degree1"
                                    >

                                        {editable ? (
                                            <Input style={{ height: '30px' }}
                                                defaultValue={rList && rList.degree1}
                                            />
                                        ) : (

                                            <Typography.Text level={5} className='typ'>{rList && rList.degree1}</Typography.Text>
                                        )}
                                    </Form.Item>

                                </div>
                                <div class="tiles-designs" >
                                    <Form.Item
                                        label={<Typography.Title level={4} >{t("label_Degree2")}</Typography.Title>}
                                        name="degree2"
                                    >

                                        {editable ? (
                                            <Input style={{ height: '30px' }}
                                                defaultValue={rList && rList.degree2}
                                            />
                                        ) : (

                                            <Typography.Text level={5} className='typ'>{rList && rList.degree2}</Typography.Text>
                                        )}
                                    </Form.Item>

                                </div>
                                <div class="tiles-designs" >
                                    {editable ? (
                                        <div >
                                            <Form.Item >
                                                <Button class="button_move" htmlType='submit' type="primary" onClick={updateDegree} >
                                                    {t("Update Degree")}
                                                </Button>
                                            </Form.Item>
                                        </div>
                                    ) : (null
                                    )}
                                </div>

                                <div class="tiles-designs" >
                                    <Form.Item
                                        label={<Typography.Title level={4} >{t("label_groups")}</Typography.Title>}
                                        name="groups"
                                    >

                                        {editable ? (
                                            <Input style={{ height: '30px' }}
                                                defaultValue={rList && rList.groups}
                                            />
                                        ) : (

                                            <Typography.Text level={5} className='typ'>{rList && rList.groups}</Typography.Text>
                                        )}
                                    </Form.Item>

                                </div>

                                <div class="tiles-designs" >
                                    <Form.Item
                                        label={<Typography.Title level={4} >{t("label_pujas")}</Typography.Title>}
                                        name="pujas"
                                    >

                                        {editable ? (
                                            <TextArea placeholder="Enter History " style={{ width: '29vw' }} rows={4}
                                                defaultValue={rList && rList.pujas}
                                            />
                                        ) : (

                                            <Typography.Text level={5} className='typ'>{rList && rList.pujas}</Typography.Text>
                                        )}
                                    </Form.Item>

                                </div>
                                <div class="tiles-designs" >
                                    <Form.Item
                                        label={<Typography.Title level={4} >{t("label_speciality")}</Typography.Title>}
                                        name="splty"
                                    >

                                        {editable ? (
                                            <Select onChange={handleSelect} style={{ width: '350px' }} defaultValue={rList && rList.splty}>
                                                <Select.Option value="">{t('label_select_speciality')}</Select.Option>
                                                <Select.Option value="vratham">{t('label_vratham')}</Select.Option>
                                                <Select.Option value="jatakam">{t('label_jathakam')}</Select.Option>
                                            </Select>
                                        ) : (

                                            <Typography.Text level={5} className='typ'>{rList && rList.splty}</Typography.Text>
                                        )}
                                    </Form.Item>
                                </div>

                                <div class="tiles-designs" >
                                    <Form.Item
                                        label={<Typography.Title level={4} >{t("label_summary")}</Typography.Title>}
                                        name="summary"

                                    >

                                        {editable ? (
                                            <TextArea placeholder="Enter History " rows={4} style={{ width: '29vw' }}
                                                defaultValue={rList && rList.summary}
                                            />
                                        ) : (

                                            <Typography.Text level={5} className='typ'>{rList && rList.summary}</Typography.Text>
                                        )}
                                    </Form.Item>
                                </div>
                                {/* <div class="tiles-designs" >
                                    {editable ? (
                                        <div >
                                            <Form.Item >
                                                <Button class="button_move" htmlType='submit' type="primary" onClick={updateAmount} >
                                                    {t("label_update_amount")}
                                                </Button>
                                            </Form.Item>
                                        </div>
                                    ) : (null
                                    )}
                                </div> */}
                            </div>
                        </div>
                    </Form>
                </div>
                <Footer />
            </div>
        </div>
    );
};

export default PriestDetails;
