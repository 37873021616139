import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchGodNames, fetchGroupDescription, fetchTemplesOutsideIndList, retrieveGodNames } from "../../../../redux/actions/acions";
import {
  getGodNames,
  getGodNamesLoading,
  getGodNamesError,
  getGroupDescriptionList,
  getGroupDescriptionLoading,
  getGroupDescriptionError,
  getTemplesOutsideIndList,
  getTemplesOutsideIndListLoading,
  getTemplesOutsideIndListError,
} from "../../../../redux/selectors/selectors";
import { message } from 'antd';
import { useParams } from "react-router-dom";
import "../../search.css"
import { useTranslation } from "react-i18next";
import TemplesPage from "../../../common/TemplesPage";
import deftemples from '../../../../assets/images/templeicon.png'
import showToast from '../../../../utils/showToast';

var last_rec = 0; // Last record shown on screen



const OutsideIndiaTemples = () => {
  const { t } = useTranslation();
  const [filterId, setFilterId] = useState(null);
  const records_per_page = 12;
  const { txt } = useParams()
  const { gid } = useParams()
  const localEnv = false;
  const storedLanguage = localStorage.getItem('lng') || 5;
  const [isLoading, setIsLoading] = useState(false);
  const [currentPageTemples, setCurrentPageTemples] = useState([]);
  const { groupName } = useParams();
  const [did, setDid] = useState(0);


  const [messageApi, contextHolder] = message.useMessage();
  const failure = (msg) => {
    showToast('fail', t('label_loginerror'))
    // messageApi.open({
    //   type: 'error',
    //   content: 'Please Login to see additional features',
    // });
  };

  const dispatch = useDispatch();
  const { tList, tLoading, tError, godCtgryList, godCtgryListLoading, godCtgryListError, gList, gLoading, gError } = useSelector(state => ({
    tList: getTemplesOutsideIndList(state),
    tLoading: getTemplesOutsideIndListLoading(state),
    tError: getTemplesOutsideIndListError(state),
    godCtgryList: getGodNames(state),
    godCtgryListLoading: getGodNamesLoading(state),
    godCtgryListError: getGodNamesError(state),
    gList: getGroupDescriptionList(state),
    gLoading: getGroupDescriptionLoading(state),
    gError: getGroupDescriptionError(state),
  }));

  useEffect(() => {
    dispatch(localEnv ? fetchGodNames(failure) : retrieveGodNames(storedLanguage))
  }, [storedLanguage])

  useEffect(() => {

    dispatch(fetchTemplesOutsideIndList(storedLanguage, txt, 'all', 'all', did, 0));
  }, [storedLanguage, did]);

  useEffect(() => {

    dispatch(fetchGroupDescription(storedLanguage, gid));
  }, [storedLanguage]);


  function filterTemplesList(gDid) {
    setDid(gDid);
    if (gDid === filterId) {
      setFilterId(null)
      dispatch(fetchTemplesOutsideIndList(storedLanguage, txt, 'all', 'all', 0, 0));
    } else {
      setFilterId(gDid);
      dispatch(fetchTemplesOutsideIndList(storedLanguage, txt, 'all', 'all', gDid, last_rec));
    }
  }

  function getStyle(did) {
    if (did == filterId) {
      return {
        color: '#4096ff',
        borderColor: '#4096ff',
      }
    }
    else {
      return {
        borderColor: '#d9d9d9',
        color: '#000'
      }
    }
  }

  const [currentPage, setCurrentPage] = useState(1);


  useEffect(() => {

    if (!tLoading && tList) {
      setCurrentPageTemples([...currentPageTemples, ...tList]);
    }

  }, [tLoading]);

  const nextTemplesList = async () => {
    setIsLoading(true);
    last_rec = last_rec + records_per_page;
    // did == filterId ? god_last_rec = god_last_rec + records_per_page : god_last_rec = 0 + records_per_page;
    dispatch(fetchTemplesOutsideIndList(storedLanguage, txt, 'all', 'all', 0, last_rec));
    setIsLoading(false);
  }

  function prevTemplesList() {
    last_rec = last_rec - records_per_page;
    last_rec = (last_rec < 0) ? 0 : last_rec;
    // god_last_rec = god_last_rec - records_per_page;
    // god_last_rec = (god_last_rec < 0) ? 0 : god_last_rec;
    dispatch(fetchTemplesOutsideIndList(storedLanguage, txt, 'all', 'all', 0, last_rec));
  };

  const getImageUrl = (txt) => {
    const imageUrls = {
      'Nepal': 'https://tse3.mm.bing.net/th?id=OIP.ZdU8BvO-LSFgBO5Ou2QFoQHaDy&pid=Api&P=0&h=180',
      'Singapore': 'https://tse2.mm.bing.net/th?id=OIP.t4x2bRdx8dOlZs_TedZidAHaFj&pid=Api&P=0&h=180',
      'Thailand': 'https://tse4.mm.bing.net/th?id=OIP.rw9ELTMmVnDP_ShEoILQrQHaFD&pid=Api&P=0&h=180',
      'Srilanka': 'https://tse4.mm.bing.net/th?id=OIP.jPw49e5eWuWwkgZxEhBahQHaFj&pid=Api&P=0&h=180',
      'Australia': 'https://tse4.mm.bing.net/th?id=OIP.XG-GkbWheyYNCbARTFAOogHaD0&pid=Api&P=0&h=180',
      'Canada': 'https://tse4.mm.bing.net/th?id=OIP.-Ubpcb9fGGBwD10SF04b_gAAAA&pid=Api&P=0&h=180',
    };

    return imageUrls[txt] || deftemples; // defaultImageUrl is a fallback image URL if txt does not match any key
  };


  const imageUrl = getImageUrl(txt);


  return (

    <>
      
      <TemplesPage
        title={txt}
        description={gList && gList.descr}
        tLoading={tLoading}
        tList={tList && tList}
        pageTitle={txt}
        filterId={filterId}
        setFilterId={setFilterId}
        filterTemplesList={filterTemplesList}
        godCtgryList={godCtgryList}
        godCtgryListLoading={godCtgryListLoading}
        temple={gList && gList.descr}
        nextTemplesList={nextTemplesList}
        prevTemplesList={prevTemplesList}
        last_rec={last_rec}
        image={imageUrl}
      />

      <div>
      </div>
    </>

  );
}

export default OutsideIndiaTemples;
