import React, { useEffect, useState } from 'react';
import deftemples from '../../assets/images/templeicon.jpg';
import { Link } from 'react-router-dom';
import { getImageAPI } from '../../http-common';
import { message } from 'antd';
import spin from '../../assets/images/Rolling.gif';
import TempleMap from '../pages/Homepage/Temples/templeMap';
import { useDispatch } from 'react-redux';
import { deleteTempleImage, postUploadImageRequest } from '../../redux/actions/acions';
import temple1 from '../../assets/images/templeimg.png';
import temple2 from '../../assets/images/templeimg2.png';
import { useTranslation } from 'react-i18next';
import Hammer from 'react-hammerjs';
import article1 from "../../assets/images/altcles1.jpg";
import article2 from "../../assets/images/altcles-copy.jpg";
import article3 from "../../assets/images/altcles-3y.jpg";


let currentPlayingAudio = null;

const ResourceCard = ({ id, title, text, tList, temple, publish_date, author, favList, toggleFavorite, handleClick, link, target, city }) => {
    const [showImage, setShowImage] = useState(true);
    const { t } = useTranslation();

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowImage(false);
        }, 2000); // Hide the image after 2 seconds

        return () => {
            clearTimeout(timer);
        };
    }, []);


    const handleAudioPlay = (event) => {
        if (currentPlayingAudio && currentPlayingAudio !== event.target) {
            currentPlayingAudio.pause(); // Pause the previously playing audio
        }
        currentPlayingAudio = event.target; // Update the currently playing audio
    };

    const userRole = localStorage.getItem('urole');

    const articleImages = [
        { src: article1, alt: 'article1' },
        { src: article2, alt: 'article2' },
        { src: article3, alt: 'article3' },
    ];

    return (
        <>
            <div className={`col-lg-${text === 'audios' ? 4 : 3} col-md-6 col-sm-12 animate__animated animate__flipInX`}>
                <div className="weather-card one">
                    <div className={`top ${(text === 'articles') ? 'article-image' : text === 'books' ? 'books-image' : text === 'mantras' ? 'mantras-image' : 'audio-image'}`}>
                        <div className="wrapper">
                            <h3 className="location" onClick={handleClick}>
                                <div>{title}</div>
                            </h3>
                            <div className="author-sec">
                                <h1 className="heading2">
                                    <span className="face-icon"></span>
                                    <span>
                                        <Link to={`/publicprofile/${temple.authorid}/${temple.author}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                                            <span>{author}</span>
                                        </Link>
                                        <div className="pub-on">{publish_date} &nbsp;</div>
                                    </span>
                                </h1>
                                <span className="d-flex">
                                    {(title === 'articles' || title === 'mantras') &&
                                        <div className="art-sub">Subscribe</div>
                                    }
                                    <div className="tile-quicklink1">
                                        {userRole === 'AS' || userRole === 'AD' && (
                                            <a href={`/editResource/${temple.rid}`} className="share-button1">
                                                <i className="fa-solid fa-edit"></i>
                                            </a>
                                        )}
                                        <a
                                            className="share-button1"
                                            onClick={() => {
                                                if (navigator.share) {
                                                    navigator.share({
                                                        title: "Know " + title,
                                                        text: "Know " + title,
                                                        url: `https://www.templeswiki.com/${text}/${temple.rid}`,
                                                    })
                                                        .then(() => console.warn("Share successful."))
                                                        .catch((error) => console.error("Error sharing:", error));
                                                } else {
                                                    // Fallback for browsers that do not support the Web Share API
                                                    console.error("Web Share API not supported.");
                                                }
                                            }}
                                        >
                                            <i title={t('label_share')} className="fa-solid fa-share-nodes"></i>
                                        </a>
                                        {favList && (
                                            <a
                                                className="share-button1"
                                                onClick={() => toggleFavorite(temple, title)}
                                            >
                                                {favList.some(t => t === id) ? (
                                                    <i className="fa-solid fa-heart" title={t('Favourites')}></i>
                                                ) : (
                                                    <i className="fa-regular fa-heart"></i>
                                                )}
                                            </a>
                                        )}
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>
                    {(text === 'mantras' || text === 'articles' || text === 'books') &&
                        <div className="bottom">
                            <div className="wrapper">
                                <ul className="forecast">
                                    <li className="active">
                                        <span className="date">{city}</span>
                                        <div className="showmore">
                                            <button className="button_move" onClick={(e) => {
                                                if (text === 'articles' && temple.src_url === 'www.templeswiki.com') {
                                                    e.preventDefault();
                                                    handleClick();
                                                }
                                                else if (text === 'mantras' && temple.src_url === 'www.templeswiki.com') {
                                                    e.preventDefault();
                                                    handleClick();
                                                }
                                                else if (text === 'books') {
                                                    
                                                    handleClick();
                                                }
                                            }}>{t('label_show_more')}</button>
                                        </div>
                                    </li>
                                    <li></li>
                                </ul>
                            </div>
                        </div>
                    }
                    {(text === 'audios') &&
                        <div className="bottom">
                            <div className="wrapper">
                                <ul className="forecast">
                                    <li className="active">
                                        <div className="showmore">
                                            <div style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                marginTop: '10%'
                                            }}>
                                                <audio
                                                    id={'song00' + id}
                                                    controls="controls"
                                                    onPlay={handleAudioPlay}
                                                    onLoadedMetadata={event => console.log("MP3=" + event.target.duration)}
                                                >
                                                    <source src={link} type="audio/mpeg" />
                                                </audio>
                                            </div>
                                        </div>
                                        <span className="date">{city}</span>
                                    </li>
                                    <li></li>
                                </ul>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </>
    );
};

export default ResourceCard;