import React, { useEffect, useState } from 'react';
import { Button, Input, Modal, Select, message, Carousel } from 'antd';
import "../style.css"
import "./homepage.css"
import { Link, useNavigate } from 'react-router-dom';
import { AudioOutlined } from '@ant-design/icons';
import iphoneapp from "../../../assets/images/app-store.jpg";
import androidapp from "../../../assets/images/google-play.jpg";
import mobilescreen from "../../../assets/images/phone.png";
import bannerlibrary from "../../../assets/images/banner_library.png";
import bannerhoroscope from "../../../assets/images/banner_horoscope.png";
import mapTemplswiki from "../../../assets/images/map_library.png";
import temple from "../../../assets/images/pandit.png";
import temple_icon from "../../../assets/images/temple-img.png";
import horoscope_icon from "../../../assets/images/horoscope-img.png";
import books_icon from "../../../assets/images/books-img.png";
import deepam from '../../../assets/images/deepam.jpg';
import pundit1 from '../../../assets/images/indian_priest1.png';

import vishnu_acc from '../../../assets/images/vishnu_acc.png';
import durga_acc from '../../../assets/images/durga_acc.png';
import siva_acc from '../../../assets/images/siva_acc.png';
import murugan_acc from '../../../assets/images/murugan_acc.png';
import ganesh_acc from '../../../assets/images/ganesh_acc.png';

import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle'
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';
import { fetchAnonomousUser, fetchDailyHoroscope, fetchRegionalNotifi, fetchTemplesByGroup } from '../../../redux/actions/acions';
import statesData from '../states.json';
import { getDailyHoroscopeError, getDailyHoroscopeList, getDailyHoroscopeLoading, getRegionalNotifiError, getRegionalNotifiList, getRegionalNotifiLoading } from '../../../redux/selectors/selectors';
import Error from './error';
import Footer from './footer/footer';
import sun from '../../../assets/images/sun_pic.png'
import moon from '../../../assets/images/moon.jpg'
import HeaderSearch from './Temples/headerSearch';


const suffix = (
  <AudioOutlined
    style={{
      fontSize: 16,
      color: '#1890ff',
    }}
  />
);



const HomePage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [byCity, setByCity] = useState(false);
  const [selectedState] = useState("All");
  const [selectedCity] = useState("All");
  const storedLanguage = localStorage.getItem('lng') || 5;
  const [messageApi] = message.useMessage();
  const failure = (msg) => {
    messageApi.error(msg); // You can use 'error' directly
  };

  const states = statesData.states.map((state) => state.name);


  const fetchGroupTemples = (gid) => {
    dispatch(fetchTemplesByGroup(5, gid, 0))
    navigate(`/templeGroup/${gid}`);
  };

  const handleHoro = (gid) => {
    if (hDetails) {
      navigate(`/horoscopeDetails/${hDetails && hDetails.hid}`);
    }
  };


  const { hDetails, hLoading } = useSelector((state) => ({
    hDetails: getDailyHoroscopeList(state),
    hLoading: getDailyHoroscopeLoading(state),
    hError: getDailyHoroscopeError(state)
  }));


  useEffect(() => {
    const formattedDate = formatDate(currentDate);
    sendDateToAPI(formattedDate);
  }, [storedLanguage, dispatch]);

  const { rList, rLoading } = useSelector(state => ({
    rList: getRegionalNotifiList(state),
    rLoading: getRegionalNotifiLoading(state),
    rError: getRegionalNotifiError(state),
  }));

  const notififailure = (msg) => {
    messageApi.open({
      type: 'error',
      content: msg,
    });
  };

  useEffect(() => {
    dispatch(fetchRegionalNotifi(storedLanguage, 0, notififailure));
  }, [storedLanguage, dispatch]);


  const [searchText, setSearchText] = useState('');
  const handleSubmit = (event) => {
    event.preventDefault();
    //const searchText = event.target.search.value;
    // Redirect to another page with the searched text as a parameter
    navigate(`/searchResults/${searchText}`);
  };

  const [currentDate, setCurrentDate] = useState(new Date()); // September is 8 because months are zero-indexed

  const handlePrevDate = () => {
    const newDate = new Date(currentDate);
    newDate.setDate(currentDate.getDate() - 1);
    const formattedDate = formatDate(newDate);
    sendDateToAPI(formattedDate);
    setCurrentDate(newDate);
  };

  const handleNextDate = () => {
    const newDate = new Date(currentDate);
    newDate.setDate(currentDate.getDate() + 1);
    const formattedDate = formatDate(newDate);
    sendDateToAPI(formattedDate);
    setCurrentDate(newDate);
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const sendDateToAPI = (formattedDate) => {
    dispatch(fetchDailyHoroscope(storedLanguage, formattedDate, failure));
  };


  const months = [
    t("January"), t("February"), t("March"), t("April"), t("May"), t("June"),
    t("July"), t("August"), t("September"), t("October"), t("November"), t("December")
  ];

  const days = [t("Sunday"), t("Monday"), t("Tuesday"), t("Wednesday"), t("Thursday"), t("Friday"), t("Saturday")];

  const [foldDirection, setFoldDirection] = useState('');

  const handleChangeDate = (direction) => {
    setFoldDirection(direction);
    if (direction === 'prev') {
      handlePrevDate();
    } else if (direction === 'next') {
      handleNextDate();
    }
    setTimeout(() => {
      setFoldDirection('');
    }, 50);
  };


  const sliderRef = React.createRef();

  const numberOfSlides = 3; // Define the total number of slides


  const home_banner_responses = [
    t("label_banner_1"),
    t("label_banner_2"),
    t("label_banner_3"),
    // t("label_banner_4"),
    t("label_banner_5"),
    t("label_banner_6"),
    // t("label_banner_7"),
  ]

  const modalStyle = {
    height: '400px', // Set the desired height
  };

  const [isModalVisible, setIsModalVisible] = useState(false);
  const closeModal = () => {
    setIsModalVisible(false);
  };

  const handleLinkClick = (slideIndex, link) => {
    if (slideIndex === 2) {
      setIsModalVisible(true);
    } else {
      navigate(link);
    }
  };

  const slides = [
    {
      title: home_banner_responses[0],
      subtitle: t("label_pooja_timings"),
      buttonLabel: t("label_india_temples"),
      image: pundit1,
      link: '/templeCtgry',
    },
    {
      title: home_banner_responses[1],
      subtitle: t("label_pooja_timings"),
      buttonLabel: t("label_intl_temples"),
      image: temple,
      link: '/outsideIndia',
    },
    {
      title: home_banner_responses[2],
      subtitle: t("label_pooja_timings"),
      buttonLabel: t("label_search_by_city"),
      image: mapTemplswiki,
      link: null, // We will handle this link specially
    },
    {
      title: home_banner_responses[3],
      subtitle: t("label_pooja_timings"),
      buttonLabel: t("label_show_priests"),
      image: bannerlibrary,
      link: '/priestBySpclty',
    },
    {
      title: home_banner_responses[4],
      subtitle: t("label_pooja_timings"),
      buttonLabel: t("label_library_items"),
      image: bannerhoroscope,
      link: '/library',
    },
    // {
    //   title: home_banner_responses[5],
    //   subtitle: t("label_pooja_timings"),
    //   buttonLabel: t("label_get_started"),
    //   image: bannerlibrary,
    //   link: '/horoscope',
    // },
    // Add more slides as needed
  ];

  const library = [
    {
      title: t("label_div_desam"),
      subtitle: t("label_divya_desham_slide"),
      buttonLabel: t("label_get_started"),
      image: vishnu_acc,
      link: '/templeGroup/101',
      backgroundColor: '#ff0000', // Example background color for the first item
    },
    {
      title: t("label_shakti_peethas"),
      subtitle: t("label_shakthipeetas_slide"),
      buttonLabel: t("label_get_started"),
      image: durga_acc,
      link: '/templeGroup/102',
      backgroundColor: '#00ff00', // Example background color for the second item
    },
    {
      title: t("label_jyo"),
      subtitle: t("label_jyothirlingas_slide"),
      buttonLabel: t("label_get_started"),
      image: siva_acc,
      link: '/templeGroup/103',
      backgroundColor: '#0000ff', // Example background color for the third item
    },
    {
      title: t("label_ganesh_mandir"),
      subtitle: t("label_ganesha_slide"),
      buttonLabel: t("label_get_started"),
      image: ganesh_acc,
      link: '/templeGroup/104',
      backgroundColor: '#ffff00', // Example background color for the fourth item
    },
    {
      title: t("label_char_dham"),
      subtitle: t("label_chardam_slide"),
      buttonLabel: t("label_get_started"),
      image: vishnu_acc,
      link: '/templeGroup/105',
      backgroundColor: '#ffff00', // Example background color for the fourth item
    },
    {
      title: t("label_muruga_temples"),
      subtitle: t("label_murugan_slide"),
      buttonLabel: t("label_get_started"),
      image: murugan_acc,
      link: '/templeGroup/106',
      backgroundColor: '#ffff00', // Example background color for the fourth item
    },
  ];

  const [index, setIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % numberOfSlides);
    }, 3000); // Change the interval time here (e.g., 3000ms = 3 seconds)

    return () => clearInterval(interval);
  }, []);


  const [backgroundImage, setBackgroundImage] = useState('');

  useEffect(() => {
    // Get the current hour
    const currentHour = new Date().getHours();

    // Set background image based on time
    if (currentHour >= 6 && currentHour < 18) {
      // Daytime, show sun
      setBackgroundImage({ sun });
    } else {
      // Nighttime, show moon or any other nighttime image
      setBackgroundImage({ moon });
    }
  }, []);

  useEffect(() => {

    dispatch(fetchAnonomousUser());

  }, []);

  if (rLoading) {
    return (
      <div className="loading-screen" style={{ textAlign: 'center', padding: '50px' }}>
        <Error />
      </div>
    );
  }

  return (
    <>

      <div class="main-content">

        <section class=" position-relative ">
          <div className="flowers_top"></div>

          <div class="d-flex justify-content-center colorb overflow-hidden ">
            <div class="loop-wrapper">



              <div class="search-bar container position-relative flex-column">
                <div class="col-sm-6 d-none">
                  <div class="aside-block">

                  </div>
                </div>


                <form
                  className=" search-form d-none align-items-center w-100"
                  onSubmit={handleSubmit}
                >

                  <div class="search_banner ">

                    <span class="search_banner_title d-flex w-100">
                      <aside class="d-flex w-100 justify-content-between">
                        <span>{t('label_find_your_temples')}</span>
                        <span class="d-flex ">
                          <span class="bycitycheckbox"><input type="checkbox" /></span>
                          <span class="bycity">{t('label_bycity')}</span>
                        </span>
                      </aside>
                    </span>
                    <div className="d-flex">
                      <input
                        type="text"
                        name="query"
                        placeholder="Search"
                        title="Enter search keyword"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                      />
                      <div className="button-sec">
                        <button type="submit" title="Search" onClick={handleSubmit}
                          disabled={!searchText.trim()}>
                          Search
                        </button>
                      </div>
                    </div>
                  </div>
                </form>

                <section id="hero">
                  <Carousel autoplay effect="fade" dotPosition="none" className="custom-carousel">
                    {slides.map((slide, slideIndex) => (
                      <div key={slideIndex} className="banner22">
                        <div className="d-flex flex-column justify-content-start banner-txt-content">
                          <h1>{slide.title}</h1>
                          <div className="d-flex">


                            <button
                              onClick={() => handleLinkClick(slideIndex, slide.link)}
                              className="bannerbutton"
                            >
                              <div class="svg-wrapper-1">
                                <div class="svg-wrapper">
                                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                    <path fill="none" d="M0 0h24v24H0z"></path>
                                    <path fill="currentColor" d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"></path>
                                  </svg>
                                </div>
                              </div>
                              <span>{slide.buttonLabel}</span>


                            </button>

                          </div>
                        </div>
                        <div className="hero-img">
                          <img src={slide.image} className="img-fluid animated" alt="" />
                        </div>
                      </div>
                    ))}
                  </Carousel>

                  <Modal
                    open={isModalVisible}
                    onOk={closeModal}
                    onCancel={closeModal}
                    footer={null}
                    width={800}
                    style={modalStyle}

                  >
                    <div class="diag-header">{t("value_search")}
                      <span class="diag-close"><i class="fa-solid fa-xmark" onClick={closeModal}></i></span></div>
                    <HeaderSearch closeModal={closeModal} />
                    <div className="dialog-buttons">
                      <Button className="login-form-button" onClick={closeModal}>{t('label_cancel')}</Button>
                    </div>
                  </Modal>
                </section>

              </div>

              <div class="tree3"></div>
              <div class="hill"></div>
              <div class="tree"></div>
              <div class="tree"></div>
              <div class="tree2"></div>
              <div class="rock"></div>
            </div>


            <div class="w-994 bkg d-none">
              <Carousel autoplay>
                <div className="caroselPic position-relative">
                  <img alt="temples" src={deepam} />
                  <div class="container text-center position-absolute slider-txt  d-none d-sm-block" data-stellar-background-ratio=".5">
                    <div class="row">
                      <div class="col-md-7">
                        <div class="slider-text-two">
                          <h2 class="mbr-section-title mbr-fonts-style mbr-bold align-center display-1">
                            HELLO
                          </h2>
                          <h4 class="mbr-text mbr-fonts-style align-center display-7 mb-4">
                            SPECIALIZED IN DESIGN AND PHOTOGRAPHY
                          </h4>
                          <div class="mbr-section-btn">
                            <a class="btn-primary btn btn-md display-4" type="submit">ABOUT ME</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Carousel>

            </div>

          </div>
        </section>


        <div class="lib-sec">

          <Link to='/templeCtgry' className='ignore-styles'>
            <div class="lib-block">
              <div class="bg-lib-sec">
              </div>
              <div class="lib-sec-img"><img src={temple_icon} /></div>
              <span class="bg-lib-sec-text-sec" title={t("label_temples_list")}>{t("label_temples_list")}</span>

            </div>
          </Link>
          <Link to='/library' className='ignore-styles'>
            <div class="lib-block">
              <div class="bg-lib-sec">
              </div>
              <div class="lib-sec-img"><img src={books_icon} /></div>
              <span class="bg-lib-sec-text-sec" title={t("label_library")}>{t("label_library")}</span>

            </div>
          </Link>
          <Link to='/horoCtgry' className='ignore-styles'>
            <div class="lib-block">
              <div class="bg-lib-sec">
              </div>
              <div class="lib-sec-img"><img src={horoscope_icon} /></div>
              <span class="bg-lib-sec-text-sec" title={t("label_panchangam")}>{t("label_panchangam")}</span>

            </div>
          </Link>
        </div>



        <section>
          <div class="container">
            <div class="d-flex row font-condenced justify-content-between bg-details">

              <div class="events-block d-flex bg-details-sec1">
                <aside class="cal-1 pt-2">
                  <span class="f18 mb-2 colorHeadingviolet fw-7 events-icon " title={t("label_events_near_you")}>
                    <i class="fa-solid fa-calendar-check"></i>&nbsp;  {t("label_events_near_you")} 
                    <Link class="ml-2 detail-but" to='/events'>
                    Go <i class=" btn-icon-go fa-solid fa-chevron-right"></i></Link></span>
                  <div id="scroll-container">
                    <div id="scroll-text">
                      {rLoading ? (
                        <Error />
                      ) : Array.isArray(rList) && rList.length > 0 ? (
                        rList.map((notificationList) => {
                          return (
                            <Link to={`/templeDetails/${notificationList.tid}?prevPage=${window.location.pathname}`} key={notificationList.tid}>
                              <span className="f1 events-block-links-title">{notificationList.subject}</span>
                              <span className="f1 events-block-links">{notificationList.txt}</span>
                            </Link>
                          );
                        })
                      ) : (
                        <div className="no-event">No Events found</div>
                      )}
                    </div>

                  </div>
                </aside>
              </div>

              <span class="today-horo-text f18 mt-3 colorHeadingviolet fw-7 events-icon d-none">
                <i class="fa-solid fa-calendar-check"></i>&nbsp; {t("label_last_seven_days")}  <Link class="ml-2" to='/horoscope'>
                  <i class="fa-solid fa-up-right-from-square"></i></Link></span>
              <div class="bg-details-sec2">

                <aside className="calendar-block ">
                  <div className="calendar-content">
                    <div class="day-up" title={t('Calendar')} onClick={() => handleChangeDate('prev')}>
                      <i class="fa-solid fa-angle-up"></i>
                    </div>
                    <div className="cal-top" title={t('Calendar')}>
                      <span className='year-txt' title={t('Calendar')}>
                        {currentDate.getFullYear()}
                      </span>
                      <span>{months[currentDate.getMonth()]}</span>
                      <span >{days[currentDate.getDay()]}</span>
                    </div>
                    <div className={`cal-bottom ${foldDirection ? `fold-out` : `fold-in`}`} title={t('Calendar')}>
                      {currentDate.getDate()}
                    </div>
                    <div class="day-down" title={t('Calendar')} onClick={() => handleChangeDate('next')}>
                      <i class="fa-solid fa-angle-down"></i>
                    </div>
                  </div>
                </aside>

                <div class="d-flex bg-details-sec3">
                  <aside class="cal-1" onClick={handleHoro}>
                    <div
                      className="ball"
                      style={{
                        rotate: '0deg',
                        position: 'absolute',
                        right: '0',
                        top: '0',
                        backgroundSize: 'contain',
                        width: '70px',
                        height: '70px',
                        backgroundImage: backgroundImage,
                        transition: 'background-image 1s ease',
                      }}
                    ></div>
                    {hLoading ? (
                      <p>Loading...</p>
                    ) : hDetails && hDetails.hid === -1 ? (
                      <>
                        <span class="f3">{currentDate.getDate()} {months[currentDate.getMonth()]} {currentDate.getFullYear()}</span>
                        <span class="f1">
                          <b>{t("label_tith")}</b> : {t("label_dwa")} <span class="f-c-2">08.53 AM</span> {t("label_nex_tri")}
                        </span>
                        <span class="f1">
                          <b>{t("label_nak")}</b> : {t("label_anu")} <span class="f-c-2">10.05 AM</span>
                        </span>
                        <span class="f1">
                          <b>{t("label_rahu_time")}</b> : <span class="f-c-3">04.30 pm - 06.00 pm</span>
                        </span>
                        <span class="f1">
                          <b>{t("label_yam")}</b> : <span class="f-c-3">12.00 PM - 01.30 PM</span>
                        </span>
                      </>
                    ) : (
                      <>

                        <span class=" f18 colorHeadingviolet fw-7" title={t("Calender")}>{hDetails && hDetails.title}
                          {hDetails && (
                            <Link class="ml-2  detail-but" to={`/horoscopeDetails/${hDetails && hDetails.hid}`}>Go <i class=" btn-icon-go fa-solid fa-chevron-right"></i></Link>
                          )}
                        </span>

                        <span class="f1">
                          <b>{t("label_tith")}</b> : {hDetails && hDetails.tithi}
                        </span>
                        <span class="f1">
                          <b>{t("label_nak")}</b> : {hDetails && hDetails.nakshatra} <span class="f-c-2"></span>
                        </span>
                        <span class="f1">
                          <b>{t("label_rahu_time")}</b> : <span class="f-c-3 f1">{hDetails && hDetails.rh_time}</span>
                        </span>
                        <span class="f1">
                          <b>{t("label_yam")}</b> : <span class="f-c-3 f1">{hDetails && hDetails.yg_time}</span>
                        </span>

                      </>
                    )}
                  </aside>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div class="bg-mandala block-float">
          <div class="heading-text-home justify-content-center">
            <div class="heading">
              <p class="subtitle text-primary">{t("label_huge_collections")} </p>
              <h2>{t("label_library")}</h2>
            </div>

          </div>
          <div class="library-icons"><div>
            <i class="fa-solid fa-video"></i> </div><div>
              <i class="fa-solid fa-book"></i></div><div>
              <i class="fa-regular fa-newspaper"></i></div><div>
              <i class="fa-solid fa-volume-high"></i></div><div>
              <i class="fa-solid fa-hands-praying"></i></div>
          </div>




          <section class="library-icons-content">
            <div class="container">

              <div class="row justify-content-around">

                <Link to="/videos" class="nav-link1  category-item swiper-slide swiper-slide-active" title={t("label_videos")} >
                  <i class="fa-solid fa-video btn-c1"></i>
                  <h3 class="category-title btn-c1">{t("label_videos")}</h3>
                </Link>



                <Link to="/books" class="nav-link1 category-item swiper-slide swiper-slide-active" title={t("label_books")}>
                  <i class="fa-solid fa-book btn-c2"></i>
                  <h3 class="category-title btn-c2">{t("label_books")}</h3>
                </Link>


                <Link to="/articles" class="nav-link1 category-item swiper-slide swiper-slide-active" title={t("label_articles")}>
                  <i class="fa-regular fa-newspaper btn-c3"></i>
                  <h3 class="category-title btn-c3">{t("label_articles")}</h3>
                </Link>

                <Link to="/audios" class="nav-link1 category-item swiper-slide swiper-slide-active" title={t("label_audios")} >
                  <i class="fa-solid fa-volume-high btn-c4"></i>
                  <h3 class="category-title btn-c4">{t("label_audios")}</h3>
                </Link>

                <Link to="/mantras" class="nav-link1 category-item swiper-slide swiper-slide-active" >
                <i class="fa-solid fa-om btn-c5"></i>
                  <h3 class="category-title btn-c5">{t("label_mantras")}</h3>
                </Link>





              </div>

            </div>
          </section>
        </div>


        <section class="py-5">
        <div class="justify-content-center mt-3 mb-5"><div class="heading">
            <p class="subtitle text-primary">{t('label_daily')}</p><h2>{t('lable_horo_panchangam')}</h2></div></div>
      <div class="container ">
        <div class="row">

              <div class="col-md-6">
                <div class="banner-ad mb-3  horoscope-home-sec-bg1"
                >
                  <div class="banner-content p-5 d-flex">
                    <div >
                      <div class="categories text-warning mb-3 fs-3 fw-bold" title={t('label_panchangam')}>Start your work in Time</div>
                      <h3 class="banner-title">Panchanngam</h3>
                      <p>is an ancient Hindu astrological calendar that provides valuable information about the
                        positioning of celestial bodies, auspicious days, festivals,
                        and important events. It typically includes the following elements for each day of the year</p>
                      <a class="btn btn-dark text-uppercase">Show Now</a>
                    </div>
                    <div class="horoscope-home-sec-bg-img1"></div>
                  </div>


                </div>
              </div>
              <div class="col-md-6">
                <div class="banner-ad horoscope-home-sec-bg2" >
                  <div class="banner-content p-5 d-flex">
                    <div class="">
                      <div class="categories text-danger mb-3 fs-3 fw-bold" title={t('label_horoscope')}>How is yor Luck Today?</div>
                      <h3 class="banner-title">Raasi Phalalu</h3>
                      <p>It is a form of astrological prediction based
                        on the moon's position in the zodiac signs. These predictions are typically provided for different time frames, such as daily, weekly, monthly, and yearly, to help individuals
                        understand what to expect in various aspects of their lives.</p>
                      <a class="btn btn-dark text-uppercase">Show Now</a>
                    </div>
                    <div class="horoscope-home-sec-bg-img2"></div>
                  </div>

                </div>
              </div>

            </div>
          </div>
        </section>

        <section class="py-2 home-mobile-sec">
          <div class="container-fluid">

            <div class="bg-mob-app py-5 rounded-5" >
              <div class="container">
                <div class="row">
                  <div class="col-md-4">
                    <img src={mobilescreen} alt="phone" class="image-float home-mobile-sec-mob-pic" />
                  </div>
                  <div class="col-md-8">
                    <h4 class="my-2 lh-lg" >  Divine Connect is your ultimate spiritual companion, bringing the essence of World’s vast religious and cultural heritage right to your fingertips. Whether you're a seeker of knowledge,
                      looking for guidance, or exploring divine spaces, this app offers:</h4>
                    <br />
                    <p> <b>Temples Information:</b> Discover detailed information on thousands of temples across India. Explore their history, significance, and tips for your visit.
                      <br />
                      <b>Horoscope:</b> Stay updated with personalized daily, weekly, and monthly horoscopes. Get insights into your future and astrological predictions.
                      <br />
                      <b>Spiritual Library:</b> Articles, Videos, Audios, Books, Mantras</p><br />
                    <div class="d-flex gap-2 flex-wrap app-links">
                      <img src={iphoneapp} alt="app-store" />
                      <img src={androidapp} alt="google-play" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </section>


        <div class="banner2 p-3 w-100 flex-row d-none " >
          <div class="d-flex w-100 font-condenced container justify-content-between">

            <aside className="calendar-block col-md-2">
              <div className="calendar-content">
                <div className="arrow-circle">
                  <div className="arrow up-arrow" onClick={handlePrevDate}>
                    ↑
                  </div>
                  <div className="arrow down-arrow" onClick={handleNextDate}>
                    ↓
                  </div>
                  <i class="fa-solid fa-angle-down"></i>
                </div>
                <div className="cal-top">
                  <span className="year-txt">{currentDate.getFullYear()}</span>
                  <span>{months[currentDate.getMonth()]}</span>
                  <span>{days[currentDate.getDay()]}</span>
                </div>
                <div className="cal-bottom">{currentDate.getDate()}</div>
              </div>
            </aside>


            <div class="d-flex col-md-5">
              <aside class="cal-1">
                <div class="ball"></div>
                <span class="calender-title ">{t("label_aadhika")}</span>
                <span class="f1"><b>{t("label_tith")}</b> : {t("label_dwa")} <span class="f-c-2">08.53 AM</span> {t("label_nex_tri")}</span>
                <span class="f1"><b>{t("label_nak")}</b> : {t("label_anu")} <span class="f-c-2">10.05 AM</span></span>
                <span class="f1">
                  <b>{t("label_rahu_time")}</b> : <span class="f-c-3">04.30 pm - 06.00 pm</span></span>
                <span class="f1"> <b>{t("label_yam")}</b> : <span class="f-c-3">12.00 PM - 01.30 PM</span>
                </span>
              </aside>
            </div>
          </div>
        </div>


        <div class="justify-content-center mt-3"><div class="heading">
            <p class="subtitle text-primary">{t("label_recommended")}</p><h2>{t("label_fam_temples")}</h2></div></div>
        <div class="cta container aos-init aos-animate" data-aos="zoom-out">
       
          <Carousel autoplay effect="fade" dotPosition="none" className="custom-carousel" >
            {library.map((item, slideIndex) => (
              <Link className='ignore-style' to={item.link}>
                <div key={slideIndex} class="row g-5" >
                  <div class="col-lg-8 col-md-6 content d-flex flex-column justify-content-center order-last order-md-first" >
                    <h3>{item.title}</h3>
                    <p>{item.subtitle}</p>
                    <Link class="cta-btn slider-btn" to={item.link} >

                      <div class="container-c font_main">
                        <a href="#" class="button-c type--C">
                          <div class="button__line"></div>
                          <div class="button__line"></div>
                          <span class="button__text">{t("label_explore")}</span>
                        </a>
                      </div>

                    </Link>
                  </div>
                  <div class="col-lg-4 col-md-6 order-first order-md-last d-flex align-items-center" >
                    <div class="livetv" style={{ backgroundImage: `url(${item.image})` }}>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </Carousel>
        </div>


        <section class="py-6 d-none">

          <div class="container-fluid ">
            <div class="row justify-content-center">
              <div class="heading">
                <p class="subtitle text-primary">{t("label_know_about")}</p>
                <h2>{t("label_our_fam_shrines")}</h2>
              </div>

            </div>


            <div class="d-flex container shrines-block ">
              <ul class="nav nav-pills nav-stacked flex-column fade-down">
                <li class="active"><a data-toggle="tab" href="#SHAKTI">{t("label_shakti_peethas")}</a></li>
                <li><a data-toggle="tab" href="#JYOTIRLINGA">{t("label_jyo")}</a></li>
                <li><a data-toggle="tab" href="#PANCHARAMALU">{t("label_pancha")}</a></li>
                <li><a data-toggle="tab" href="#PANCHABHOOTAM">{t("label_pancha_bho_tpls")}</a></li>
                <li><a data-toggle="tab" href="#DIVYADESAM">{t("label_div_desam")}</a></li>
                <li><a data-toggle="tab" href="#NAVANARASIMHA">{t("label_nav_nar_ksh")}</a></li>
                <li><a data-toggle="tab" href="#CHARDHAM">{t("label_char_dham")}</a></li>

                <li><a data-toggle="tab" href="#CHOTACHARDHAM">{t("label_cho_char_dham")}</a></li>
                <li><a data-toggle="tab" href="#ARUPADAIVEEDU">{t("label_aru_veedu")}</a></li>
                <li><a data-toggle="tab" href="#PANCHKEDAR">{t("label_panch_kedar")}</a></li>
                <li><a data-toggle="tab" href="#TRILINGAKSHETRA">{t("label_tri_ksh")}</a></li>
              </ul>




              <div class="tab-content w-100 ">
                <div id="SHAKTI" class="tab-pane active h-100">
                  <div class="card-bg h-100">
                    <h6 class="text-shadow text-uppercase card-design-text">{t("label_shakti_peethas")}
                      <span class="card-design-text2">18<span class="card-design-text3">{t("label_temples_list")}</span></span></h6>
                    <div class="d-flex h-100">
                      <div class="card-design durga-pic"></div>
                      <div class="onhover-text"><span>
                        <button onClick={() => fetchGroupTemples(102)}>{t("label_show_temples")} <i class="fa-solid fa-circle-play"></i></button>
                      </span><aside>
                          ert erw treww trew t rewt rewt rew t ret ret
                        </aside>
                      </div>

                    </div>
                  </div>
                </div>

                <div id="JYOTIRLINGA" class="tab-pane fade h-100">
                  <div class="card-bg h-100">
                    <h6 class="text-shadow text-uppercase card-design-text">{t("label_jyo")}
                      <span class="card-design-text2">12<span class="card-design-text3">{t("label_temples_list")}</span></span></h6>
                    <div class="d-flex h-100">
                      <div class="card-design linga-pic"></div>
                      <div class="onhover-text"><span> <button onClick={() => fetchGroupTemples(103)}>{t("label_show_temples")} <i class="fa-solid fa-circle-play"></i></button></span>
                        <aside>
                          ert erw treww trew t rewt rewt rew t ret ret
                        </aside>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="PANCHARAMALU" class="tab-pane fade h-100">
                  <div class="card-bg h-100">
                    <h6 class="text-shadow text-uppercase card-design-text">{t("label_pancha")}
                      <span class="card-design-text2">5<span class="card-design-text3">{t('label_temples_list')}</span></span></h6>
                    <div class="d-flex h-100">
                      <div class="card-design shiva1"></div>
                      <div class="onhover-text"><span><button onClick={() => fetchGroupTemples(109)}>{t("label_show_temples")} <i class="fa-solid fa-circle-play"></i></button></span><aside>
                        ert erw treww trew t rewt rewt rew t ret ret</aside>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="PANCHABHOOTAM" class="tab-pane fade h-100">
                  <div class="card-bg h-100">
                    <h6 class="text-shadow text-uppercase card-design-text">{t("label_pancha_bho_tpls")}
                      <span class="card-design-text2">5<span class="card-design-text3">Temples</span></span></h6>
                    <div class="d-flex h-100">
                      <div class="card-design shiva2"></div>
                      <div class="onhover-text"><span><button onClick={() => fetchGroupTemples(102)}>{t("label_show_temples")} <i class="fa-solid fa-circle-play"></i></button></span><aside>
                        ert erw treww trew t rewt rewt rew t ret ret</aside>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="DIVYADESAM" class="tab-pane fade h-100">
                  <div class="card-bg h-100">
                    <h6 class="text-shadow text-uppercase card-design-text">{t("label_div_desam")}
                      <span class="card-design-text2">108<span class="card-design-text3">Temples</span></span></h6>
                    <div class="d-flex h-100">
                      <div class="card-design vishnu"></div>
                      <div class="onhover-text"><span><button onClick={() => fetchGroupTemples(108)}>Show Temples <i class="fa-solid fa-circle-play"></i></button></span><aside>
                        ert erw treww trew t rewt rewt rew t ret ret</aside>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="NAVANARASIMHA" class="tab-pane fade  h-100">
                  <div class="card-bg h-100">
                    <h6 class="text-shadow text-uppercase card-design-text">{t("label_nav_nar_ksh")}
                      <span class="card-design-text2">9<span class="card-design-text3">Temples</span></span></h6>
                    <div class="d-flex h-100">
                      <div class="card-design narashimha"></div>
                      <div class="onhover-text"><span><button onClick={() => fetchGroupTemples(110)}>Show Temples <i class="fa-solid fa-circle-play"></i></button></span><aside>
                        ert erw treww trew t rewt rewt rew t ret ret</aside>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="CHARDHAM" class="tab-pane fade  h-100  h-100">
                  <div class="card-bg h-100">
                    <h6 class="text-shadow text-uppercase card-design-text">{t("label_char_dham")}
                      <span class="card-design-text2">4<span class="card-design-text3">Temples</span></span></h6>
                    <div class="d-flex h-100">
                      <div class="card-design cd1"></div>
                      <div class="onhover-text"><span><button onClick={() => fetchGroupTemples(105)}>Show Temples <i class="fa-solid fa-circle-play"></i></button></span><aside>
                        ert erw treww trew t rewt rewt rew t ret ret</aside>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="CHOTACHARDHAM" class="tab-pane fade  h-100">
                  <div class="card-bg h-100">
                    <h6 class="text-shadow text-uppercase card-design-text">{t("label_cho_char_dham")}
                      <span class="card-design-text2">4<span class="card-design-text3">Temples</span></span></h6>
                    <div class="d-flex h-100">
                      <div class="card-design cd1"></div>
                      <div class="onhover-text"><span><button onClick={() => fetchGroupTemples(102)}>Show Temples <i class="fa-solid fa-circle-play"></i></button></span><aside>
                        ert erw treww trew t rewt rewt rew t ret ret</aside>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="ARUPADAIVEEDU" class="tab-pane fade  h-100">
                  <div class="card-bg h-100">
                    <h6 class="text-shadow text-uppercase card-design-text">{t("label_aru_veedu")}
                      <span class="card-design-text2">6<span class="card-design-text3">Temples</span></span></h6>
                    <div class="d-flex h-100">
                      <div class="card-design murugan"></div>
                      <div class="onhover-text"><span><button onClick={() => fetchGroupTemples(102)}>Show Temples <i class="fa-solid fa-circle-play"></i></button></span><aside>
                        ert erw treww trew t rewt rewt rew t ret ret</aside>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="PANCHKEDAR" class="tab-pane fade  h-100">
                  <div class="card-bg h-100">
                    <h6 class="text-shadow text-uppercase card-design-text">{t("label_panch_kedar")}
                      <span class="card-design-text2">5<span class="card-design-text3">Temples</span></span></h6>
                    <div class="d-flex h-100">
                      <div class="card-design shiva4"></div>
                      <div class="onhover-text"><span><button onClick={() => fetchGroupTemples(102)}>Show Temples <i class="fa-solid fa-circle-play"></i></button></span><aside>
                        ert erw treww trew t rewt rewt rew t ret ret</aside>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="TRILINGAKSHETRA" class="tab-pane fade  h-100">
                  <div class="card-bg h-100">
                    <h6 class="text-shadow text-uppercase card-design-text">{t("label_tri_ksh")}
                      <span class="card-design-text2">3<span class="card-design-text3">Temples</span></span></h6>
                    <div class="d-flex h-100">
                      <div class="card-design shiva3"></div>
                      <div class="onhover-text"><span><button onClick={() => fetchGroupTemples(102)}>Show Temples <i class="fa-solid fa-circle-play"></i></button></span><aside>
                        ert erw treww trew t rewt rewt rew t ret ret</aside>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </section>
        <Footer />

        {/* <Chatbot /> */}
      </div>

      {/* <div class="footer-mobile p-3">
        <div class="active-nav">
          <i class="fa-solid fa-house"></i>
          <span>Home</span>
        </div>

        <div>
          <i class="fa-solid fa-gopuram"></i>
          <span>Temples</span>
        </div>


        <div>
          <i class="fa-solid fa-photo-film"></i>
          <span>Library</span>
        </div>

        <div>
          <i class="fa-regular fa-hand"></i>
          <span>Horoscope</span>
        </div>



        <div>
          <i class="fa-solid fa-video"></i>
          <span>Live TV </span>
        </div>


        <div>
          <i class="fa-regular fa-circle-user"></i>
          <span>User</span>
        </div>


      </div> */}


    </>
  )
}

export default HomePage;