import ganesh from '../../assets/icons/ganesh.jpg'
import durga from '../../assets/icons/durga.jpg'
import vishnu from '../../assets/icons/narayana.jpg'
import lakshmi from '../../assets/icons/lakshmi.jpg'
import rama from '../../assets/icons/rama.jpg'
import shiva from '../../assets/icons/siva.jpg'
import krishna from '../../assets/icons/krishna.jpg'
import kartikeya from '../../assets/icons/kumara.jpg'
import hanuma from '../../assets/icons/hanuman.jpg'
import brahma from '../../assets/icons/bhrahma.jpg'
import saraswati from '../../assets/icons/saraswati.jpg'
import ayyappa from '../../assets/icons/ayyapa.jpg'
import saibaba from '../../assets/icons/saibaba.jpg'
import nrusimha from '../../assets/icons/narashima.jpg'
import surya from '../../assets/icons/surya.jpg'
import budda from '../../assets/icons/bhudha.jpg'
import aum from '../../assets/icons/om.jpg'
import naga from '../../assets/icons/naga.jpg'
import gurudev from '../../assets/icons/gurudev.jpg'
import balaji from '../../assets/icons/balaji.jpg'

const deityImages = {
    0: aum,
    1: ganesh,
    2: vishnu,
    3: lakshmi,
    4: durga,
    5: rama,
    6: shiva,
    7: krishna,
    19: balaji,
    8: kartikeya,
    9: hanuma,
    10: brahma,
    11: saraswati,
    12: ayyappa,
    13: saibaba,
    14: nrusimha,
    15: surya,
    16: budda,
    17: naga,
    18: gurudev
}

export default deityImages;
