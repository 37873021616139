const API_KEYS = {
  RESOURCE_API_KEY: process.env.REACT_APP_RESOURCE_API_KEY,
  USER_API_KEY: process.env.REACT_APP_USER_API_KEY,
  TEMPLE_API_KEY: process.env.REACT_APP_TEMPLE_API_KEY,
  SERVICE_API_KEY: process.env.REACT_APP_SERVICE_API_KEY,
  SECRET_CODE: process.env.REACT_APP_SECRET_CODE,
  USER_AUTH_KEY: process.env.REACT_APP_USER_AUTH_KEY,

  // Method to set the anonymous token in local storage
  setClientToken() {
    if (!localStorage.getItem('client_token')) {
      localStorage.setItem('client_token', this.anonomous);
    }
  },

  // Method to get the anonymous token from local storage
  getClientToken() {
    const token = localStorage.getItem('appuid');
    return token;
  }
};

export default API_KEYS;
