import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Typography from 'antd/es/typography/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Col, DatePicker, Form, Image, Input, Popconfirm, Row, Select, Skeleton, message } from 'antd';
import { getPriestDetailsError, getPriestDetailsList, getPriestDetailsLoading } from '../../redux/selectors/selectors';
import { fetchPriestDetails, subscribeAuthor, updatePriestDegree, updatePriestDetails, updatePriestSpclty } from '../../redux/actions/acions';
import deftemples from '../../assets/images/templeicon.jpg'
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import TextArea from 'antd/es/input/TextArea';
import showToast from '../../utils/showToast';
import CustomToastContainer from '../common/CustomToastContainer';
import Footer from '../pages/Homepage/footer/footer';

const PublicProfile = () => {
    const { t } = useTranslation();
    const storedLanguage = localStorage.getItem('lng') || 5;
    const { id } = useParams()
    const { author } = useParams()
    const { city } = useParams()
    const { st } = useParams()
    const dispatch = useDispatch();
    const urole = localStorage.getItem('urole')
    const [messageApi, contextHolder] = message.useMessage();
    const navigate = useNavigate();
    const [amount, setAmount] = useState(null);
    const [dontype, setDontype] = useState(null);
    const [notes, setNotes] = useState(null);
    const [bill, setBill] = useState(null);
    const [payment, setPayment] = useState(null);

    const success = () => {
        showToast('success', 'label_add_temple_sucess')
        form.resetFields()
    };

    const failure = (msg) => {
        showToast('error', msg); // You can use 'error' directly
    };

    const { rList, rLoading, rError } = useSelector(state => ({
        rList: getPriestDetailsList(state),
        rLoading: getPriestDetailsLoading(state),
        rError: getPriestDetailsError(state),
    }));

    useEffect(() => {
        dispatch(fetchPriestDetails(id));
    }, [dispatch]);

    console.log(rList)

    const handleGoBack = () => {
        navigate(-1);
    };

    const [formData, setFormData] = useState({
        // fullname: sDetails.fullname || '',
        // gender: sDetails.gender || '',
        // lang: sDetails.lang || '',
    });

    const [editable, setEditable] = useState(false);
    const [buttonText, setButtonText] = useState(t("edit"));
    const [form] = Form.useForm();

    const handleCancelClick = () => {
        // Reset the form to its initial values
        // form.resetFields();
        setButtonText('Edit');
        setEditable(false);
    };

    const editsucess = () => {
        showToast('success', t('label_subscribe_success'))
        form.resetFields()
    };

    const editfailure = (msg) => {
        showToast('error', msg)
    };

    const handleEditClick = () => {
        if (!editable) {
            setButtonText(t("label_cancel"));
            // Populate the input field with the current value when entering edit mode
            setFormData({ ...formData, amount: rList && rList.amount });
            setFormData({ ...formData, otherdeities: rList && rList.otherdeities });
        } else {
            setButtonText('Edit');
        }
        setEditable(!editable);
    };

    const handleSelect = (value) => {
        dispatch(updatePriestSpclty({ splty: `${value}` }, success));
    };

    const handleDetails = (e) => {
        setNotes(e.target.value)
    };


    const handleSaveClick = () => {
        dispatch(subscribeAuthor(rList.userid, editsucess, editfailure));
    };


    const handleDegree = (e) => {
        setBill(e.target.value)
    };

    const updateDegree = (values) => {
        const data = {
            "degree1": values.degree1,
            "degree2": values.degree2
        }
        dispatch(updatePriestDegree(id, data));
    };


    return (
        <div className='mainsec'>
            <div class=" mob-nav fluid-container page-title justify-content-between">
                <div class="d-flex align-items-center left-sec-mob-nav">
                    <Link onClick={handleGoBack} ><i class="fa-solid fa-angle-left"></i></Link>
                    <span class="bc-link">{t('label_articles')}</span>
                </div>
                <div></div>
            </div>
            {contextHolder}

            <div className='f2 font-weight-bold mb-3 mt-3 container title-head-txt breadcrumb-link' >
                <Link style={{ color: 'black' }} to='/'>
                    <span className="bc-active-link">{t('Home')}</span>
                </Link> - <span className="bc-active-link" onClick={handleGoBack}>{t("label_author")}</span>
            </div>
            <div class="bg-details-highlight">
                <div class=" details-headertxt-singleLine container">
                    <div class="header_txt">
                        {author}
                    </div>
                    <div class="details-headertxt-singleLine-btns">
                        <a
                            className="button_move"
                            onClick={handleSaveClick}
                            style={{ marginRight: '10px' }}
                        >
                            {t("label_subscribe")}
                        </a>
                    </div>
                </div>
            </div>
            <div class="mainsec-block-addtemple-detailspage">
                <div className='container'>
                    <Form
                        form={form}
                        name='basic'
                    // labelCol={{ span: 5 }}
                    // wrapperCol={{ span: 10 }}
                    >
                        <div className='horoscope-item' >
                            <div class="float-left w-100" ><h5 class="card-text-title">{t("label_author_details")} <span>| {t('label_addr_degree_splt')}</span></h5></div>
                            <div class="active-details">
                                <div className="tiles-designs">
                                    <Form.Item
                                        label={<Typography.Title level={4}>{t("label_village_area")}</Typography.Title>}
                                        name="addr1"
                                    >
                                        {editable ? (
                                            <Input style={{ height: '35px' }} defaultValue={rList && rList.addr1} />
                                        ) : (
                                            <Typography.Text level={5} className='typ'>{rList && rList.addr1}</Typography.Text>
                                        )}
                                    </Form.Item>
                                </div>
                                <div className="tiles-designs">
                                    <Form.Item
                                        label={<Typography.Title level={4}>{t("label_addr")}</Typography.Title>}
                                        name="addr2"
                                    >
                                        {editable ? (
                                            <Input style={{ height: '30px' }} defaultValue={rList && rList.addr2} />
                                        ) : (
                                            <Typography.Text level={5} className='typ'>{rList && rList.addr2}</Typography.Text>
                                        )}
                                    </Form.Item>
                                </div>
                                <div className="tiles-designs">
                                    <Form.Item
                                        label={<Typography.Title level={4}>{t("label_phone")}</Typography.Title>}
                                        name="phone"
                                    >
                                        {editable ? (
                                            <Input style={{ height: '30px' }} defaultValue={rList && rList.phone} />
                                        ) : (
                                            <Typography.Text level={5} className='typ'>{rList && rList.phone}</Typography.Text>
                                        )}
                                    </Form.Item>
                                </div>
                                <div className="tiles-designs">
                                    <Form.Item
                                        label={<Typography.Title level={4}>{t("label_pin")}</Typography.Title>}
                                        name="pin"
                                    >
                                        {editable ? (
                                            <Input style={{ height: '30px' }} defaultValue={rList && rList.pin} />
                                        ) : (
                                            <Typography.Text level={5} className='typ'>{rList && rList.pin}</Typography.Text>
                                        )}
                                    </Form.Item>
                                </div>
                                <div class="tiles-designs" >
                                    <Form.Item
                                        label={<Typography.Title level={4} >{t("label_Degree1")}</Typography.Title>}
                                        name="degree1"
                                    >

                                        {editable ? (
                                            <Input style={{ height: '30px' }}
                                                defaultValue={rList && rList.degree1}
                                            />
                                        ) : (

                                            <Typography.Text level={5} className='typ'>{rList && rList.degree1}</Typography.Text>
                                        )}
                                    </Form.Item>

                                </div>
                                <div class="tiles-designs" >
                                    <Form.Item
                                        label={<Typography.Title level={4} >{t("label_Degree2")}</Typography.Title>}
                                        name="degree2"
                                    >
                                        {editable ? (
                                            <Input style={{ height: '30px' }}
                                                defaultValue={rList && rList.degree2}
                                            />
                                        ) : (

                                            <Typography.Text level={5} className='typ'>{rList && rList.degree2}</Typography.Text>
                                        )}
                                    </Form.Item>
                                </div>
                                <div class="tiles-designs" >
                                    <Form.Item
                                        label={<Typography.Title level={4} >{t("label_summary")}</Typography.Title>}
                                        name="summary"

                                    >

                                        {editable ? (
                                            <Input.TextArea placeholder="Enter History " rows={3}
                                                defaultValue={rList && rList.summary}
                                            />
                                        ) : (

                                            <Typography.Text level={5} className='typ'>{rList && rList.summary}</Typography.Text>
                                        )}
                                    </Form.Item>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
                <Footer />
            </div>
        </div>
    );
};

export default PublicProfile;
